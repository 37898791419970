import { AuthState, canonicalIdForHGObjectRef, HGObject } from "./domain";
import * as connection from "./domain/connection";
import * as domain from "./domain";
import * as hsApi from "./api/hubspot-api";
import _ from "lodash";
import * as useCases from "./use-cases/use-cases";

async function sendOutgoingItems(
  queue: HubSpotAssociationsSyncQueue,
): Promise<void> {
  let incoming: {
    incomingPatchSet: IncomingPatchSet;
    onDone: (incomingPatchSet: IncomingPatchSet) => void;
  }[] = [];

  for (const { patches, onDone } of queue.outgoing) {
    const authState = queue.getAuthStateFn();

    if (!authState) {
      throw new Error(
        "Cannot send outgoing HubSpot associations patches without authState",
      );
    }

    const applyPatchesResults = await hsApi.applyAssociationPatches({
      authState,
      patches,
    });

    console.log("applyPatchesResults", applyPatchesResults);

    // recreate all connections involved in the patches so that we
    // have any updated label state from HubSpot (you can apply
    // labels to one object that affects another object as well,
    // e.g. moving the "primary company" label)
    const affectedObjectRefs = _.chain(patches)
      .flatMap((patch) => [patch.toObjectRef, patch.fromObjectRef])
      .uniqBy(canonicalIdForHGObjectRef)
      .value();

    const propertiesToFetchForObjectTypes =
      queue.propertiesToFetchForObjectTypesFn();
    const existingHGLabelPairs = queue.existingLabelPairsFn();
    const hgSchemas = queue.existingHGSchemasFn();

    console.log("refreshing connections for objectRefs...", affectedObjectRefs);
    const { connections: refreshedConnections, objects: refreshedObjects } =
      await useCases.fetchObjectsConnections({
        objectRefs: affectedObjectRefs,
        authState,
        hgSchemas,
        existingHGLabelPairs,
        propertiesToFetchForObjectTypes,
      });
    console.log("refreshed results for objectRefs", {
      objectRefs: affectedObjectRefs,
      refreshedConnections,
      refreshedObjects,
    });

    incoming.push({
      incomingPatchSet: {
        refreshedConnections,
        refreshedObjects,
      },
      onDone,
    });
  }

  for (const { incomingPatchSet, onDone } of incoming) {
    onDone(incomingPatchSet);
  }
}

export function applyHubSpotAssociationChanges(
  queue: HubSpotAssociationsSyncQueue,
  params: {
    patches: connection.ConnectionPatch[];
  },
): Promise<IncomingPatchSet> {
  return new Promise((resolve, reject) => {
    const { patches } = params;

    const onDone = async (incomingPatchSet: IncomingPatchSet) => {
      resolve(incomingPatchSet);
    };

    queue.outgoing.push({ patches, onDone });

    if (queue.state === "idle") {
      queue.state = "applying-patches";
      sendOutgoingItems(queue)
        .then(() => {
          queue.state = "idle";
        })
        .catch((e) => {
          console.error("error applying patches", e);
          queue.state = "idle";
        });
    }
  });
}

type OutgoingPatchSet = {
  patches: connection.ConnectionPatch[];
  onDone: (incomingPatchSet: IncomingPatchSet) => void;
};
type IncomingPatchSet = {
  refreshedConnections: connection.HGConnection[];
  refreshedObjects: HGObject[];
};

export type HubSpotAssociationsSyncQueue = {
  outgoing: OutgoingPatchSet[];
  incoming: IncomingPatchSet[];
  state: "applying-patches" | "idle";
  getAuthStateFn: () => AuthState | undefined;
  existingLabelPairsFn: () => connection.HGLabelPair[];
  existingHGSchemasFn: () => domain.HGObjectSchema[];
  propertiesToFetchForObjectTypesFn: () => Record<string, string[]>;
};
export function makeHubSpotAssociationsSyncQueue(
  params: Omit<HubSpotAssociationsSyncQueue, "outgoing" | "incoming" | "state">,
): HubSpotAssociationsSyncQueue {
  return {
    outgoing: [],
    incoming: [],
    state: "idle",
    ...params,
  };
}
