import { canonicalIdForHSProperty } from "../../domain";
import { HSProperty } from "../../domain/property";
import { HGStore } from "../../store/types";
import { assert } from "../../utils";

export type BaseBuyingGroupCondition = {
  id: string;
};

export type BuyingGroupSizeCondition = BaseBuyingGroupCondition & {
  type: "buying-group-size";
  size: number;
};

export type BuyingRolePresenceCondition = BaseBuyingGroupCondition & {
  type: "buying-role-presence";
  buyingRole: string;
  count: number;
};

export type BuyingGroupAnalysisCondition =
  | BuyingGroupSizeCondition
  | BuyingRolePresenceCondition;

export type BuyingGroupAnalysisConfig = {
  conditions: BuyingGroupAnalysisCondition[];
};

export type Health = "bad" | "okay" | "good";

export const bgColorTintMap: { [key in Health]: string } = {
  bad: "bg-red-50",
  okay: "bg-orange-50",
  good: "bg-green-50",
} as const;

export const bgColorMap: { [key in Health]: string } = {
  bad: "bg-red-600",
  okay: "bg-orange-600",
  good: "bg-green-600",
} as const;

export const BUYING_GROUP_ANALYSIS_CONFIG: BuyingGroupAnalysisConfig = {
  conditions: [
    {
      id: "1",
      type: "buying-group-size",
      size: 5,
    },
    {
      id: "2",
      type: "buying-role-presence",
      buyingRole: "CHAMPION",
      count: 2,
    },
    {
      id: "3",
      type: "buying-role-presence",
      buyingRole: "BUDGET_HOLDER",
      count: 1,
    },
  ],
};

export function buyingRoleOptions(
  store: HGStore,
): { value: string; label: string }[] {
  const buyingRolePropertyCanonicalId = canonicalIdForHSProperty({
    objectType: "contact",
    name: "hs_buying_role",
  });
  const buyingRoleProperty = store.hgProperties[
    buyingRolePropertyCanonicalId
  ] as HSProperty | undefined;

  if (!buyingRoleProperty) {
    return [];
  }

  assert(buyingRoleProperty.type === "enumeration");

  return buyingRoleProperty.options.map((option) => {
    return {
      value: option.value,
      label: option.label,
    };
  });
}
