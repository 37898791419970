import React, { useCallback } from "react";
import { observer } from "mobx-react-lite";
import { Button, Drawer, IconButton } from "@mui/material";
import {
  Close as CloseIcon,
  Person as PersonIcon,
  Handshake as HandshakeIcon,
  Business as BusinessIcon,
} from "@mui/icons-material";
import * as actions from "../actions";
import {
  canonicalIdForHGObjectRef,
  HGObject,
  objectDisplayName,
} from "../domain";
import { DisplayPropertiesDrawer } from "./DisplayPropertiesSelector";
import * as computeds from "../computeds";
import _ from "lodash";
import { SidebarPropertyRows } from "./PropertyComponents/HGObjectSidebarProperties";
import { useStore } from "../hooks/hooks";

type ObjectColorClass = "purple" | "blue" | "emerald" | "gray";

const replaceableObjectColorClasses: Readonly<
  Record<ObjectColorClass, [find: string, replace: string][]>
> = {
  purple: [
    ["text-{color}-900", "text-purple-900"],
    ["border-{color}-400", "border-purple-400"],
    ["bg-{color}-50", "bg-purple-50"],
    ["bg-{color}-300", "bg-purple-300"],
    ["bg-{color}-500", "bg-purple-500"],
    ["bg-{color}-200", "bg-purple-200"],
    ["bg-{color}-100", "bg-purple-100"],
  ],
  blue: [
    ["text-{color}-900", "text-blue-900"],
    ["border-{color}-400", "border-blue-400"],
    ["bg-{color}-50", "bg-blue-50"],
    ["bg-{color}-300", "bg-blue-300"],
    ["bg-{color}-500", "bg-blue-500"],
    ["bg-{color}-200", "bg-blue-200"],
    ["bg-{color}-100", "bg-blue-100"],
  ],
  emerald: [
    ["text-{color}-900", "text-emerald-900"],
    ["border-{color}-400", "border-emerald-400"],
    ["bg-{color}-50", "bg-emerald-50"],
    ["bg-{color}-300", "bg-emerald-300"],
    ["bg-{color}-500", "bg-emerald-500"],
    ["bg-{color}-200", "bg-emerald-200"],
    ["bg-{color}-100", "bg-emerald-100"],
  ],
  gray: [
    ["text-{color}-900", "text-slate-900"],
    ["border-{color}400", "border-slate-400"],
    ["bg-{color}-50", "bg-slate-50"],
    ["bg-{color}-200", "bg-slate-200"],
    ["bg-{color}-300", "bg-slate-300"],
    ["bg-{color}-400", "bg-slate-400"],
    ["bg-{color}-50", "bg-slate-50"],
  ],
};

function applyObjectColorClasses(
  color: ObjectColorClass,
  classString: string,
): string {
  const availableReplacements = replaceableObjectColorClasses[color];

  let withReplacements = classString;

  for (const [find, replace] of availableReplacements) {
    withReplacements = withReplacements.replaceAll(find, replace);
  }

  return withReplacements;
}

function objectTypeToObjectColorClass(objectType: string): ObjectColorClass {
  switch (objectType) {
    case "contact": {
      return "purple";
    }
    case "company": {
      return "blue";
    }
    case "deal": {
      return "emerald";
    }
    default: {
      return "gray";
    }
  }
}

function replaceObjectTypeColors(
  objectType: string,
  classString: string,
): string {
  return applyObjectColorClasses(
    objectTypeToObjectColorClass(objectType),
    classString,
  );
}

const HGObjectDetailPanelSummary: React.FC<{
  hgObject: HGObject;
  onClose: () => void;
}> = observer((props) => {
  const { hgObject, onClose } = props;

  const displayLabel = computeds.displayLabelForObjectTypeSingular(
    hgObject.objectType,
  );

  let iconNode: React.ReactNode | undefined;
  switch (hgObject.objectType) {
    case "contact": {
      iconNode = <PersonIcon color="inherit" fontSize="medium" />;
      break;
    }
    case "company": {
      iconNode = <BusinessIcon color="inherit" fontSize="medium" />;
      break;
    }
    case "deal": {
      iconNode = <HandshakeIcon color="inherit" fontSize="medium" />;
      break;
    }
  }

  const Tag = (props: { label: string }) => {
    return (
      <div
        className={replaceObjectTypeColors(
          hgObject.objectType,
          `px-2 py-0.5 rounded-md bg-white bg-opacity-70 text-{color}-900 text-base font-semibold`,
        )}
      >
        {props.label}
      </div>
    );
  };

  return (
    <div>
      <div
        className={replaceObjectTypeColors(
          hgObject.objectType,
          `flex flex-row justify-between px-2 bg-{color}-200`,
        )}
      >
        <div className="flex flex-row opx-2 py-2.5 space-x-2 items-center">
          <span
            className={replaceObjectTypeColors(
              hgObject.objectType,
              `text-{color}-900 ml-1`,
            )}
          >
            {iconNode}
          </span>
          <Tag label={displayLabel} />
        </div>
        <div className="flex items-center justify-center">
          <IconButton
            // style={{ width: 48, height: 48 }}
            size="small"
            className={replaceObjectTypeColors(
              hgObject.objectType,
              "text-{color}-900",
            )}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </div>
      </div>

      <div
        className={replaceObjectTypeColors(
          hgObject.objectType,
          `px-4 pt-4 pb-3 space-y-3 bg-{color}-50`,
        )}
      >
        <h2 className="text-slate-800 text-lg font-semibold tracking-tight leading-tight hg-privacy blur">
          {objectDisplayName(hgObject)}
        </h2>

        <Button
          size="small"
          onClick={() => {
            actions.openHSObjectInHubSpot({
              objectRef: hgObject,
            });
          }}
          color="primary"
          variant="contained"
          disableElevation
          style={{ textTransform: "none" }}
        >
          View in HubSpot
        </Button>
      </div>
    </div>
  );
});

const HGObjectDetailPanel: React.FC<{
  objectType: string;
  objectId: string;
  onClose: () => void;
}> = observer((props) => {
  const { objectType, objectId, onClose } = props;

  const store = useStore();

  const canonicalId = canonicalIdForHGObjectRef({ objectType, objectId });

  const hgObject = store.hgObjects[canonicalId];

  if (!hgObject) {
    return null;
  }

  return (
    <div className="flex flex-col flex-1 min-h-0 divide-y-2 divide-slate-200">
      <HGObjectDetailPanelSummary hgObject={hgObject} onClose={onClose} />
      <div className="overflow-y-scroll flex-1 min-h-0">
        <SidebarPropertyRows hgObject={hgObject} />
      </div>
      <div className="p-2 flex bg-slate-100">
        <Button
          variant="contained"
          color={"primary"}
          disableElevation
          style={{ textTransform: "none" }}
          onClick={(e) => {
            actions.startConfiguringDisplayProperties({ objectType });
          }}
        >
          Choose properties
        </Button>
      </div>
    </div>
  );
});

const ObjectDisplayPropertiesDrawer: React.FC<{
  objectType: string;
  open: boolean;
  onClose: () => void;
}> = observer((props) => {
  const { open, onClose, objectType } = props;

  const properties = computeds.hubspotProperties(objectType);
  const propertyGroups = computeds.hubspotPropertyGroups(objectType);
  const displayProperties = computeds.portalDisplayProperties(objectType);

  const handleDone = useCallback(() => {
    actions.finishConfiguringDisplayProperties();
  }, []);

  const handleAddDisplayProperty = useCallback(
    (params: { name: string; objectType: string; showOnCard: boolean }) => {
      actions.addDisplayProperty(params);
    },
    [],
  );

  const handleRemoveDisplayProperty = useCallback(
    (params: { name: string; objectType: string }) => {
      actions.removeDisplayProperty(params);
    },
    [],
  );

  const handleUpdateDisplayProperty = useCallback(
    (params: { name: string; objectType: string; showOnCard: boolean }) => {
      actions.updateDisplayProperty(params);
    },
    [],
  );

  return (
    <Drawer anchor="left" open={open} onClose={onClose}>
      <DisplayPropertiesDrawer
        objectType={objectType}
        displayProperties={displayProperties}
        properties={properties}
        propertyGroups={propertyGroups}
        onDone={handleDone}
        onAddDisplayProperty={handleAddDisplayProperty}
        onRemoveDisplayProperty={handleRemoveDisplayProperty}
        onUpdateDisplayProperty={handleUpdateDisplayProperty}
      />
    </Drawer>
  );
});

const HGObjectSidebarWrapper = (props: { children?: React.ReactNode }) => {
  return (
    <div className="flex flex-col flex-1 min-h-0 bg-white rounded-xl shadow-vlg overflow-hidden pointer-events-auto">
      {props.children}
    </div>
  );
};

export const HGObjectSidebarImpl: React.FC<{ onClosePanel: () => void }> =
  observer((props) => {
    const { onClosePanel } = props;
    const store = useStore();

    if (!store.focusedObject) {
      return <HGObjectSidebarWrapper />;
    }

    const { objectType, objectId } = store.focusedObject;

    return (
      <HGObjectSidebarWrapper>
        <ObjectDisplayPropertiesDrawer
          objectType={objectType}
          open={!!store.displayPropertiesConfigSession}
          onClose={() => actions.finishConfiguringDisplayProperties()}
        />

        <div className="flex flex-col flex-1 min-h-0">
          <HGObjectDetailPanel
            onClose={onClosePanel}
            objectType={objectType}
            objectId={objectId}
          />
        </div>
      </HGObjectSidebarWrapper>
    );
  });
