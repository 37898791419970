import * as icons from "@mui/icons-material";

const fgColorClassForObjectType: {
  [objectType: string]: string | undefined;
} = {
  contact: "text-purple-900",
  company: "text-blue-900",
  deal: "text-emerald-900",
};

const bgColorClassesForObjectType: {
  [objectType: string]:
    | {
        dark: string;
        darker: string;
        darkest: string;
        light: string;
        darkBorder: string;
      }
    | undefined;
} = {
  contact: {
    darkBorder: "border-purple-400",
    darker: "bg-purple-300",
    darkest: "bg-purple-500",
    dark: "bg-purple-200",
    light: "bg-purple-50",
  },
  company: {
    darkBorder: "border-blue-400",
    darker: "bg-blue-300",
    darkest: "bg-blue-500",
    dark: "bg-blue-200",
    light: "bg-blue-50",
  },
  deal: {
    darkBorder: "border-emerald-400",
    darker: "bg-emerald-300",
    darkest: "bg-emerald-500",
    dark: "bg-emerald-200",
    light: "bg-emerald-50",
  },
};

export function fgColorClass(objectType: string): string {
  return fgColorClassForObjectType[objectType] || "text-slate-900";
}

export function bgColorClasses(objectType: string): {
  darkBorder: string;
  darker: string;
  darkest: string;
  dark: string;
  light: string;
} {
  return (
    bgColorClassesForObjectType[objectType] || {
      darkBorder: "border-slate-400",
      dark: "bg-slate-200",
      darker: "bg-slate-300",
      darkest: "bg-slate-400",
      light: "bg-slate-50",
    }
  );
}

export function iconComponentForObjectType(
  objectType: string,
): icons.SvgIconComponent | undefined {
  let IconComponent: icons.SvgIconComponent | undefined;
  switch (objectType) {
    case "contact": {
      IconComponent = icons.Person;
      break;
    }
    case "company": {
      IconComponent = icons.Business;
      break;
    }
    case "deal": {
      IconComponent = icons.Handshake;
      break;
    }
    case "note": {
      IconComponent = icons.Note;
      break;
    }
  }

  return IconComponent;
}
