import React from "react";

export function TextIcon() {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 2H4V6.8H5.5992C5.5992 5.8608 5.9168 4.4 7.2 4.4H10.4V17.8992C10.4 18.8384 9.74 19.6 8.8 19.6H8V21.2H15.9992V19.6H15.2C14.2608 19.6 13.6 18.8384 13.6 17.8992V4.4H16.8C18.1192 4.4 18.416 5.8608 18.416 6.8H20V2V2Z"
        fill="currentColor"
      />
    </svg>
  );
}
