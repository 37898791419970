import React from "react";
import { useStore } from "../hooks/hooks";
import * as actions from "../actions";
import { observer } from "mobx-react-lite";
import * as domain from "../domain";
import _ from "lodash";
import { Button, Dialog, DialogActions, DialogTitle } from "@mui/material";
import { canonicalObjectIdsOnMap } from "../computeds";
import { AddToMapSuggestionRow } from "./AddToMapSuggestion";
import * as connection from "../domain/connection";

export const AddAssociatedToChartModal: React.FC<{}> = observer((props) => {
  const store = useStore();

  const objectRef = store.draftAddingAssociatedToChart;

  if (!objectRef) {
    return null;
  }

  const hgConnections = Object.values(store.hgConnections).filter(
    (hgConnection) => {
      return connection.hgConnectionInvolvesObjectRef(hgConnection, objectRef);
    },
  );

  const objectIdsAlreadyOnMap = canonicalObjectIdsOnMap.get();

  const hgConnectionsToShow: {
    hgObject: domain.HGObject;
    hgConnection: connection.HGConnection;
  }[] = _.chain(hgConnections)
    .map((hgConnection) => {
      const otherObjectRef = domain.objectRefsEqual(
        hgConnection.objectRefA,
        objectRef,
      )
        ? hgConnection.objectRefB
        : hgConnection.objectRefA;
      return { otherObjectRef, hgConnection };
    })
    .filter(({ otherObjectRef }) => {
      return !objectIdsAlreadyOnMap[
        domain.canonicalIdForHGObjectRef(otherObjectRef)
      ];
    })
    .map((params) => {
      // add in hgObject records from our in-memory database
      return {
        ...params,
        hgObject:
          store.hgObjects[
            domain.canonicalIdForHGObjectRef(params.otherObjectRef)
          ],
      };
    })
    // remove any objects that we don't have in the database
    .filter(({ hgObject }) => !!hgObject)
    .value();

  return (
    <Dialog
      open={true}
      onClose={(e) => {
        actions.cancelAddingAssociatedObject();
      }}
      fullWidth={true}
      maxWidth="xs"
    >
      <DialogTitle>Add associated objects to map</DialogTitle>
      <div className="divide-y-2 border-y border-slate-300">
        {_.map(hgConnectionsToShow, ({ hgObject, hgConnection }) => {
          return (
            <AddToMapSuggestionRow
              hgObject={hgObject}
              hgConnection={hgConnection}
              onAddToMap={() => {
                actions.addAssociatedObjectToChart({ hgObject });
              }}
              key={domain.canonicalIdForHGObjectRef(hgObject)}
            />
          );
        })}
      </div>
      <DialogActions>
        <Button
          onClick={() => {
            actions.cancelAddingAssociatedObject();
          }}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
});
