import * as ts from "io-ts";

const HSPropertyBase = ts.intersection([
  ts.type({
    /* objectType and canonicalId are not actually present, but we need identity across different object types */
    objectType: ts.string,
    canonicalId: ts.string,

    groupName: ts.string,
    hidden: ts.boolean,
    name: ts.string,
    displayOrder: ts.number,
    label: ts.string,
    description: ts.string,
    externalOptions: ts.boolean,
  }),
  ts.partial({
    referencedObjectType: ts.string,
    showCurrencySymbol: ts.boolean,
  }),
]);

const HSPropertyBool = ts.intersection([
  HSPropertyBase,
  ts.type({
    type: ts.literal("bool"),
    fieldType: ts.string,
    // fieldType: ts.union([
    //   ts.literal("booleancheckbox"),
    //   ts.literal("calculation_equation"),
    //   ts.literal("calculation_read_time"),
    //   ts.literal("calculation_rollup"),
    //   ts.literal("calculation_score"),
    //   ts.literal(""),
    // ]),
  }),
]);
export const HSPropertyEnumeration = ts.intersection([
  HSPropertyBase,
  ts.type({
    type: ts.literal("enumeration"),
    fieldType: ts.string,
    // fieldType: ts.union([
    //   ts.literal("booleancheckbox"),
    //   ts.literal("checkbox"),
    //   ts.literal("radio"),
    //   ts.literal("select"),
    //   ts.literal("calculation_equation"),
    //   ts.literal("calculation_read_time"),
    //   ts.literal("calculation_rollup"),
    //   ts.literal("calculation_score"),
    //   ts.literal("number"),
    // ]),
    options: ts.array(
      ts.intersection([
        ts.type({
          label: ts.string,
          value: ts.string,
          displayOrder: ts.number,
        }),
        ts.partial({
          description: ts.string,
        }),
      ]),
    ),
  }),
]);

export type HSPropertyEnumeration = ts.TypeOf<typeof HSPropertyEnumeration>;
const HSPropertyDate = ts.intersection([
  HSPropertyBase,
  ts.type({
    type: ts.literal("date"),
    fieldType: ts.string,
    // fieldType: ts.union([
    //   ts.literal("date"),
    //   ts.literal("calculation_equation"),
    //   ts.literal("calculation_read_time"),
    //   ts.literal("calculation_rollup"),
    //   ts.literal("calculation_score"),
    //   ts.literal("number"),
    // ]),
  }),
]);
const HSPropertyDateTime = ts.intersection([
  HSPropertyBase,
  ts.type({
    type: ts.literal("datetime"),
    fieldType: ts.string,
    // fieldType: ts.union([
    //   ts.literal("date"),
    //   ts.literal("text"),
    //   ts.literal("calculation_equation"),
    //   ts.literal("calculation_read_time"),
    //   ts.literal("calculation_rollup"),
    //   ts.literal("calculation_score"),
    //   ts.literal("number"),
    // ]),
  }),
]);
const HSPropertyNumber = ts.intersection([
  HSPropertyBase,
  ts.type({
    type: ts.literal("number"),
    fieldType: ts.string,
    // fieldType: ts.union([
    //   ts.literal("number"),
    //   ts.literal("text"),
    //   ts.literal("calculation_equation"),
    //   ts.literal("calculation_read_time"),
    //   ts.literal("calculation_rollup"),
    //   ts.literal("calculation_score"),
    // ]),
  }),
]);
const HSPropertyPhoneNumber = ts.intersection([
  HSPropertyBase,
  ts.type({
    type: ts.literal("phone_number"),
    fieldType: ts.string,
    // fieldType: ts.union([
    //   ts.literal("phonenumber"),
    //   ts.literal("calculation_equation"),
    //   ts.literal("calculation_read_time"),
    //   ts.literal("calculation_rollup"),
    //   ts.literal("calculation_score"),
    // ]),
  }),
]);
const HSPropertyString = ts.intersection([
  HSPropertyBase,
  ts.type({
    type: ts.literal("string"),
    fieldType: ts.string,
    // fieldType: ts.union([
    //   ts.literal("file"),
    //   ts.literal("text"),
    //   ts.literal("textarea"),
    //   ts.literal("phonenumber"),
    //   ts.literal("calculation_equation"),
    //   ts.literal("calculation_read_time"),
    //   ts.literal("calculation_rollup"),
    //   ts.literal("calculation_score"),
    //   ts.literal("number"),
    // ]),
  }),
]);

export const HSProperty = ts.union([
  HSPropertyBool,
  HSPropertyEnumeration,
  HSPropertyDate,
  HSPropertyDateTime,
  HSPropertyNumber,
  HSPropertyPhoneNumber,
  HSPropertyString,
]);
export type HSProperty = ts.TypeOf<typeof HSProperty>;

export const HSPropertyGroup = ts.type({
  /* objectType and canonicalId are not actually present, but we need identity across different object types */
  objectType: ts.string,
  canonicalId: ts.string,

  name: ts.string,
  displayOrder: ts.number,
  label: ts.string,
});
export type HSPropertyGroup = ts.TypeOf<typeof HSPropertyGroup>;

export const HGDisplayProperty = ts.type({
  name: ts.string,
  objectType: ts.string,
  showOnCard: ts.boolean,
});
export type HGDisplayProperty = ts.TypeOf<typeof HGDisplayProperty>;
