import React from "react";
import * as actions from "../actions";
import { ToolbarIconButton } from "./ToolbarButton";
import GroupsIcon from "@mui/icons-material/Groups";

export const RightToolbar = () => {
  return (
    <div className="flex flex-col">
      <div className="mx-auto">
        <div className="flex flex-col bg-gray-200 shadow-vlg rounded-lg items-center">
          <ToolbarIconButton
            tooltipTitle="Buying Group Analysis"
            tooltipPlacement="left"
            onClick={() => {
              actions.toggleBuyingGroupAnalysis();
            }}
          >
            <GroupsIcon />
          </ToolbarIconButton>
        </div>
      </div>
    </div>
  );
};
