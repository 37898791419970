import React from "react";

export function ArrowIcon() {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.465 16.95L4.5 20.915L3 19.587L7.051 15.536L15.535 7.05L12.707 4H20V11.292L16.949 8.464L8.465 16.95Z"
        fill="currentColor"
      />
    </svg>
  );
}
