import { action, runInAction } from "mobx";
import { app } from "../store/store";
import * as authApi from "../api/auth";
import * as hsApi from "../api/hubspot-api";
import * as useCases from "../use-cases/use-cases";
import * as TDDraw from "@orgcharthub/tldraw-tldraw";
import {
  canonicalIdForHGObjectRef,
  HGObject,
  HGObjectRef,
  HGPortal,
  objectRefsEqual,
  userDisplayName,
  hubspotURLForObjectRef,
  isCardShape,
  isCardShapeMetaHubSpot,
  isCardShapeHubSpot,
} from "../domain";
import { HGDisplayProperty } from "../domain/property";
import _, { cloneDeep } from "lodash";
import shortid from "shortid";
import { assert, indexBy, parseOrThrow, delay } from "../utils";
import { CardShape, TDDocument, TDShape } from "@orgcharthub/tldraw-tldraw";
import * as firebaseApi from "../api/firebase";
import jwtDecode, { JwtPayload } from "jwt-decode";
import * as connection from "../domain/connection";
import { applyHubSpotAssociationChanges } from "../hubspot-associations-sync-queue";
import * as db from "../store/db";
import * as concentric from "../domain/layout/concentric";
import { shapeByObjectCanonicalId } from "../domain/canvas";
import {
  DEV_CACHE_ENABLED,
  DEV_USE_V3_API_FOR_FETCH_CONNECTIONS,
} from "../config";

const store = app.store;

function wrapTLDrawUpdate(fn: (tlApp: TDDraw.TldrawApp) => void): void {
  fn(app.tlApp);
  app.store.documentForceUpdate += 1;
}

function withTLDraw<T>(fn: (tlApp: TDDraw.TldrawApp) => T): T {
  return fn(app.tlApp);
}

function makeAction<F extends (...args: any[]) => any>(
  actionName: string,
  fn: F,
  options: {
    quiet: boolean;
  } = { quiet: false },
): F {
  return action((...args: any[]) => {
    if (!options.quiet) {
      console.groupCollapsed(`action:${actionName}`, ...args);
    }
    const beforeState = _.cloneDeep(store);
    const result = fn(...args);
    const afterState = _.cloneDeep(store);
    if (!options.quiet) {
      console.log("processed action", { beforeState, afterState });
      console.groupEnd();
    }
    return result;
  }) as F;
}

function makeActionAsync<F extends (...args: any[]) => Promise<any>>(
  actionName: string,
  fn: F,
): F {
  return action(async (...args: any[]) => {
    console.group(`action:${actionName}`, ...args);
    const beforeState = _.cloneDeep(store);
    const result = await fn(...args);
    const afterState = _.cloneDeep(store);
    console.log("processed action", { beforeState, afterState });
    console.groupEnd();
    return result;
  }) as F;
}

function handleAuthenticateSuccess(nextAuthState: {
  accessToken: string;
  refreshToken?: string;
}): void {
  const { accessToken, refreshToken } = nextAuthState;
  // keep auth tokens away from URL as much as we can
  window.location.hash = "";

  runInAction(() => {
    store.auth.accessToken = accessToken;
    if (refreshToken) {
      store.auth.refreshToken = refreshToken;
    }
  });
}

export const receivePortalUpdate = makeAction(
  "receivePortalUpdate",
  (params: { portalData: HGPortal }): void => {
    const { portalData } = params;
    store.portal = portalData;
  },
);

function setupFirestoreLongLivedSubscriptions(params: { portalId: string }) {
  const { portalId } = params;
  const portalRef = firebaseApi.portalDocRef(portalId);
  portalRef.onSnapshot(
    (snapshot) => {
      const portalData = parseOrThrow(HGPortal, snapshot.data());
      receivePortalUpdate({
        portalData,
      });
    },
    (error) => {
      console.error(error);
    },
  );
}

export const startBackgroundServices = makeAction(
  "startBackgroundServices",
  () => {
    setInterval(() => {
      authenticationMaybeRefresh();
    }, 10 * 1000);
  },
);

export const initialize = makeActionAsync("initialize", async () => {
  const portalId = store.portalId;
  let accessToken: string | undefined;
  let refreshToken: string | undefined;

  firebaseApi.startFirebase();

  if (store.auth.queryToken) {
    console.log("found queryToken in state, using as accessToken");
    // handle dev-time passing of token in the URL
    handleAuthenticateSuccess({ accessToken: store.auth.queryToken });
    accessToken = store.auth.queryToken;
  } else if (!_.isEmpty(store.auth.authCode)) {
    console.log("found authCode in state, performing login");
    // login using an authCode (for example, set on the CRM Card iframe URL)
    const loginRes = await authApi.login(store.auth.authCode);
    handleAuthenticateSuccess(loginRes);
    accessToken = loginRes.accessToken;
    refreshToken = loginRes.refreshToken;
  } else {
    console.log(
      "no queryToken, no authToken, attempting cookie-session based auth",
    );
    // no other auth method given, so assume that we should have access
    // to an auth cookie. to handle this, attempt to refresh using any
    // session cookie we have right now, and if this doesn't work
    // then redirect to login to HubSpot to get a session cookie
    try {
      const nextAuthState = await authApi.refreshSession({ portalId });
      handleAuthenticateSuccess(nextAuthState);
      accessToken = nextAuthState.accessToken;
      refreshToken = nextAuthState.refreshToken;
    } catch (e) {
      // remove any auth tokens etc from the document hash (don't want to
      // leave it in the `returnTo` param)
      window.location.hash = "";
      const marketingSiteUrl = window.location.host.replace("dealmapping.", "");
      const url = `${marketingSiteUrl}/auth/hubspot/login`;
      const returnTo = window.location.href;
      const redirectUrl = `${
        window.location.protocol
      }//${url}?appRedirectUrl=${encodeURIComponent(
        returnTo,
      )}&portalId=${portalId}`;
      window.location.href = redirectUrl;
    }
  }

  if (!accessToken) {
    throw new Error("Failed to get an accessToken during initialize");
  }

  // login to firebase
  authApi.firebaseSignOut(); // firestore keeps session info somehow, so logout first to make sure we are not authed to a different portal the user has visited
  await authApi.firebaseSignIn({ token: accessToken });

  // monitor auth state changes
  const waitForFirebaseUser = new Promise<boolean>((resolve, reject) => {
    authApi.onAuthStateChanged((user) => {
      console.log("user changed", user);
      if (user) {
        resolve(true);
      }
    });
  });

  // start background services (e.g. refreshing auth)
  startBackgroundServices();

  // initial subscriptions
  setupFirestoreLongLivedSubscriptions({
    portalId,
  });

  // fetch user information
  const userId = store.auth.userId;
  let user: {
    id: string;
    firstName?: string | undefined;
    lastName?: string | undefined;
    email?: string | undefined;
  };

  // can't fetch actual user in impersonation mode - won't be in the HS account
  if (store.auth.impersonation) {
    user = {
      id: userId,
      email: "",
      firstName: "",
      lastName: "",
    };
  } else {
    user = await hsApi.fetchOwner({
      authState: {
        accessToken,
      },
      userId,
    });
  }

  let color: string;
  if (user.email === "austin@orgcharthub.com") {
    color = "#1d4ed8";
  } else if (user.email === "dan@orgcharthub.com") {
    color = "#22c55e";
  } else {
    color = "#a16207";
  }

  const displayName = userDisplayName(user);

  console.log("waiting for firebase user...");
  await waitForFirebaseUser;

  runInAction(() => {
    store.initialized = true;
    store.user = {
      color,
      metadata: {
        email: user.email,
        displayName,
      },
      point: [0, 0],
      selectedIds: [],
    };
  });

  const minimumHSMapDependenciesMet = new Promise<boolean>(
    async (resolve, reject) => {
      assert(accessToken);

      let haveFetchedAccountDetails: boolean = false;
      let haveFetchedHGLabelPairs: boolean = false;
      let haveProperties: boolean = false;
      let havePropertyGroups: boolean = false;
      let haveCheckedDisplayProperties: boolean = false;
      let resolved: boolean = false;
      function maybeResove() {
        console.log("maybeResolve", {
          haveFetchedAccountDetails,
          haveFetchedHGLabelPairs,
          haveProperties,
          havePropertyGroups,
          haveCheckedDisplayProperties,
        });
        if (
          haveFetchedAccountDetails &&
          haveFetchedHGLabelPairs &&
          haveProperties &&
          havePropertyGroups &&
          haveCheckedDisplayProperties &&
          !resolved
        ) {
          console.log("resolving minimum hs dependencies");
          resolve(true);
        }
      }

      await useCases.maybeAddDefaultDisplayProperties({
        portalId,
      });
      haveCheckedDisplayProperties = true;
      maybeResove();

      await useCases.ensureHubSpotDependencies({
        portalId,
        accessToken,
        onDependency: (event) => {
          console.log("received hubspot dependency event", event);
          runInAction(() => {
            switch (event.type) {
              case "DependencyEventAccountDetailsFetched": {
                store.hgAccountDetails = event.accountDetails;
                haveFetchedAccountDetails = true;
                maybeResove();
                return;
              }

              case "DependencyEventSchemasDiscovered": {
                if (event.customObjectsSupported) {
                  store.customObjectsSupported = true;
                  db.upsertHGObjectSchemas(store, event.schemas);
                } else {
                  store.customObjectsSupported = false;
                }
                return;
              }

              case "DependencyEventHGLabelPairsFetched": {
                db.upsertHGLabelPairs(store, event.hgLabelPairs);
                haveFetchedHGLabelPairs = true;
                maybeResove();
                return;
              }

              case "DependencyEventPropertyGroupsFetched": {
                db.upsertHSPropertyGroups(store, event.propertyGroups);
                havePropertyGroups = true;
                maybeResove();
                return;
              }

              case "DependencyEventPropertiesFetched": {
                db.upsertHSProperties(store, event.properties);
                haveProperties = true;
                maybeResove();
                return;
              }
            }
          });
        },
      });
    },
  );

  console.log("waiting for minimum hs dependencies for map...");
  await minimumHSMapDependenciesMet;
  console.log("have minimum hs dependencies for map");

  await initRelationshipMap();
});

export const updateCardSizeCache = makeAction(
  "updateCardSizeCache",
  (params: { nodeId: string; size: [width: number, height: number] }) => {
    app.store.cardSizeCache[params.nodeId] = [...params.size];
  },
  {
    quiet: true,
  },
);

export const addShape = makeAction("addShape", () => {
  const id = shortid();
  wrapTLDrawUpdate((tlApp) => {
    tlApp.createShapes({
      id,
      type: TDDraw.TDShapeType.Rectangle,
      name: "Rect",
      childIndex: 1,
      point: [_.random(1, 10) * 100, _.random(1, 10) * 100],
      size: [_.random(1, 3) * 100, _.random(1, 3) * 100],
      style: {
        dash: TDDraw.DashStyle.Draw,
        size: TDDraw.SizeStyle.Small,
        color: _.shuffle([
          TDDraw.ColorStyle.Red,
          TDDraw.ColorStyle.Green,
          TDDraw.ColorStyle.Orange,
          TDDraw.ColorStyle.Violet,
          TDDraw.ColorStyle.Cyan,
        ])[0],
      },
    });
  });
});

export const startEditingConnection = makeAction(
  "startEditingConnection",
  (params: { shapeIdA: string; shapeIdB: string }) => {
    const [shapeA, shapeB] = withTLDraw((tlApp) => {
      return [
        tlApp.getShape<TDShape>(params.shapeIdA) as TDShape | undefined,
        tlApp.getShape(params.shapeIdB),
      ];
    });

    if (!shapeA || !shapeB) {
      return;
    }

    if (!isCardShapeHubSpot(shapeA) || !isCardShapeHubSpot(shapeB)) {
      return;
    }

    const objectRefA: HGObjectRef = {
      objectType: shapeA.meta.objectType,
      objectId: shapeA.meta.objectId,
    };

    const objectRefB: HGObjectRef = {
      objectType: shapeB.meta.objectType,
      objectId: shapeB.meta.objectId,
    };

    const connectionId = connection.canonicalIdForConnection({
      objectRefA,
      objectRefB,
    });

    const existing = store.hgConnections[connectionId] as
      | connection.HGConnection
      | undefined;

    console.log("shapeA/shapeB", shapeA, shapeB);

    let draft: connection.HGConnection;
    if (existing) {
      draft = cloneDeep(existing);
    } else {
      // TODO: move this is way too complicated to be in an action
      const unlabelledLabelPair = Object.values(store.hgLabelPairs).find(
        (hgLabelPair) => {
          return connection.labelPairIsUnlabelledPairForObjectTypes({
            labelPair: hgLabelPair,
            objectTypeA: shapeA.meta.objectType,
            objectTypeB: shapeB.meta.objectType,
          });
        },
      );

      if (!unlabelledLabelPair) {
        throw new Error(
          "Cannot create connection without unlabelled label pair",
        );
      }

      const objectAIsHSLabelA =
        unlabelledLabelPair.hgLabels.hgLabelA.objectType ===
        objectRefA.objectType;

      // TODO: are `objectATypeId` and `objectBTypeId` assigned the correct way around?
      draft = {
        canonicalId: connectionId,
        objectRefA,
        objectRefB,
        appliedLabelPairs: [
          {
            labelPairCanonicalId: unlabelledLabelPair.canonicalId,
            objectATypeId: objectAIsHSLabelA
              ? unlabelledLabelPair.hgLabels.hgLabelA.typeId
              : unlabelledLabelPair.hgLabels.hgLabelB.typeId,
            objectBTypeId: objectAIsHSLabelA
              ? unlabelledLabelPair.hgLabels.hgLabelB.typeId
              : unlabelledLabelPair.hgLabels.hgLabelA.typeId,
          },
        ],
      };
    }

    store.connectionEditingSession = {
      newPrimaryCompanySelection: undefined,
      existing: existing,
      draft,
    };
  },
);

export const cancelEditingAssociation = makeAction(
  "cancelEditingAssociation",
  () => {
    store.connectionEditingSession = undefined;
  },
);

export const connectionEditingSessionSetNewPrimaryCompany = makeAction(
  "connectionEditingSessionSetNewPrimaryCompany",
  (params: { newCompanyObjectRef: HGObjectRef }) => {
    const { newCompanyObjectRef } = params;

    if (!store.connectionEditingSession) {
      return;
    }

    store.connectionEditingSession.newPrimaryCompanySelection =
      newCompanyObjectRef;
  },
);

export const editingSessionRemoveAssociationLabelPair = makeAction(
  "editingSessionRemoveAssociationLabelPair",
  (params: { labelPairCanonicalId: string }) => {
    const { labelPairCanonicalId } = params;

    if (!store.connectionEditingSession) {
      return;
    }

    const { draft } = store.connectionEditingSession;

    draft.appliedLabelPairs = draft.appliedLabelPairs.filter(
      (appliedLabelPair) =>
        appliedLabelPair.labelPairCanonicalId !== labelPairCanonicalId,
    );
  },
);

export const editingSessionAddAssociationLabelPair = makeAction(
  "editingSessionAddAssociationLabelPair",
  (params: {
    labelPairCanonicalId: string;
    objectSide: "A" | "B";
    typeId: number;
  }) => {
    const { labelPairCanonicalId, objectSide, typeId } = params;

    if (!store.connectionEditingSession) {
      return;
    }

    const hgLabelPair = store.hgLabelPairs[labelPairCanonicalId] as
      | connection.HGLabelPair
      | undefined;

    if (!hgLabelPair) {
      return;
    }

    const { draft } = store.connectionEditingSession;

    const oppositeSideTypeId = connection.oppositeTypeIdForHGLabelPair(
      hgLabelPair,
      typeId,
    );

    const thisSideIsObjectA = objectSide === "A";
    const objectATypeId = thisSideIsObjectA ? typeId : oppositeSideTypeId;
    const objectBTypeId = thisSideIsObjectA ? oppositeSideTypeId : typeId;

    if (connection.isPrimaryLabelPair(hgLabelPair)) {
      store.connectionEditingSession.newPrimaryCompanySelection = undefined;
    }

    draft.appliedLabelPairs = draft.appliedLabelPairs
      .filter(
        (appliedLabelPair) =>
          appliedLabelPair.labelPairCanonicalId !== labelPairCanonicalId,
      )
      .concat([
        {
          labelPairCanonicalId,
          objectATypeId,
          objectBTypeId,
        },
      ]);
  },
);

export const connectionEditingSessionUpdateConnectionOnHubSpot =
  makeActionAsync(
    "connectionEditingSessionUpdateConnectionOnHubSpot",
    async () => {
      const { existing, draft, newPrimaryCompanySelection } =
        store.connectionEditingSession!;
      const accessToken = store.auth.accessToken;

      if (!accessToken) {
        throw new Error(
          "Cannot perform connectionEditingSessionUpdateConnectionOnHubSpot without access token",
        );
      }

      if (!draft) {
        throw new Error("Cannot update connection without a draft");
      }

      // TODO: pass in information about new primary company if we have it - will generate extra patches
      const patches = connection.makeConnectionPatchesForHubSpotAPIUpdate({
        hgLabelPairs: Object.values(store.hgLabelPairs),
        existingConnection: existing,
        nextConnection: draft,
        newPrimaryCompanyObjectRef: newPrimaryCompanySelection,
      });

      console.log("patches for HubSpot", cloneDeep(patches));

      // optimistically update our local state with the new connection
      db.upsertHGConnections(store, [draft]);

      // finish the editing session so the user can move on
      store.connectionEditingSession = undefined;

      // actually apply the changes to HubSpot and refresh objects
      // and connections after the update
      const { refreshedConnections, refreshedObjects } =
        await applyHubSpotAssociationChanges(app.hsAssociationsSyncQueue, {
          patches,
        });

      console.log("refreshed connections/objects, applying to store", {
        refreshedConnections,
        refreshedObjects,
      });

      // need to remove any connections from our local store where we didn’t receive
      // an update for them and they were involved in the patches
      // - that would indicate that they were not present on HubSpot after
      //   our patch was applied
      const affectedObjectRefCanonicalIds = patches
        .flatMap((patch) => [patch.fromObjectRef, patch.toObjectRef])
        .map(canonicalIdForHGObjectRef);

      runInAction(() => {
        for (const connection of Object.values(store.hgConnections)) {
          if (
            affectedObjectRefCanonicalIds.includes(
              canonicalIdForHGObjectRef(connection.objectRefA),
            ) ||
            affectedObjectRefCanonicalIds.includes(
              canonicalIdForHGObjectRef(connection.objectRefB),
            )
          ) {
            delete store.hgConnections[connection.canonicalId];
          }
        }
        db.upsertHGObjects(store, refreshedObjects);
        db.upsertHGConnections(store, refreshedConnections);
      });
    },
  );

export const connectionEditingSessionRemoveConnectionOnHubSpot =
  makeActionAsync(
    "connectionEditingSessionRemoveConnectionOnHubSpot",
    async () => {
      const { existing } = store.connectionEditingSession!;
      const accessToken = store.auth.accessToken;

      if (!accessToken) {
        throw new Error(
          "Cannot perform connectionEditingSessionRemoveConnectionOnHubSpot without access token",
        );
      }

      if (!existing) {
        throw new Error(
          "Cannot remove connection without an existing connection",
        );
      }

      const patch = connection.makeConnectionPatchesForHubSpotAPIRemove({
        existingConnection: existing,
      });

      console.log("removal patch for HubSpot", cloneDeep(patch));

      // optimistically update our local state with the new connection
      delete store.hgConnections[existing.canonicalId];

      // finish the editing session
      store.connectionEditingSession = undefined;

      // actually apply the changes to HubSpot and refresh objects
      // and connections after the update
      const { refreshedConnections, refreshedObjects } =
        await applyHubSpotAssociationChanges(app.hsAssociationsSyncQueue, {
          patches: [patch],
        });

      console.log("refreshed connections/objects, applying to store", {
        refreshedConnections,
        refreshedObjects,
      });

      // need to remove any connections from our local store where we didn’t receive
      // an update for them and they were involved in the patches
      // - that would indicate that they were not present on HubSpot after
      //   our patch was applied
      const affectedObjectRefCanonicalIds = [
        patch.fromObjectRef,
        patch.toObjectRef,
      ].map(canonicalIdForHGObjectRef);

      runInAction(() => {
        for (const connection of Object.values(store.hgConnections)) {
          if (
            affectedObjectRefCanonicalIds.includes(
              canonicalIdForHGObjectRef(connection.objectRefA),
            ) ||
            affectedObjectRefCanonicalIds.includes(
              canonicalIdForHGObjectRef(connection.objectRefB),
            )
          ) {
            delete store.hgConnections[connection.canonicalId];
          }
        }
        db.upsertHGObjects(store, refreshedObjects);
        db.upsertHGConnections(store, refreshedConnections);
      });
    },
  );

export const startAddingObjectToChart = makeAction(
  "startAddingObjectToChart",
  () => {
    store.draftAddingObjectToChart = {
      objectType: "company",
    };
  },
);

export const cancelAddingObjectToChart = makeAction(
  "cancelAddingObjectToChart",
  () => {
    store.draftAddingObjectToChart = undefined;
  },
);

export const updateAddingObjectToChartObjectType = makeAction(
  "updateAddingObjectToChartObjectType",
  (params: { objectType: string }) => {
    if (!store.draftAddingObjectToChart) {
      return;
    }
    store.draftAddingObjectToChart.objectType = params.objectType;
  },
);

export const enableCustomObjects = makeAction("enableCustomObjects", () => {
  const marketingSiteUrl = window.location.host.replace("dealmapping.", "");
  const enableUrl = `${window.location.protocol}//${marketingSiteUrl}/oauth/hubspot/authorize-redirect?includeHubGraphScopes=true`;
  window.open(enableUrl, "_blank");
});

export const addObjectToChart = makeActionAsync(
  "addObjectToChart",
  async (params: { hgObject: HGObject; fromAssociation?: boolean }) => {
    const { hgObject, fromAssociation } = params;

    const accessToken = store.auth.accessToken;
    if (!accessToken) {
      throw new Error("Cannot addObjectToChart without accessToken");
    }

    const addingObjectRef: HGObjectRef = {
      objectId: hgObject.objectId,
      objectType: hgObject.objectType,
    };

    const currentShapes = (app.tlApp.document as TDDocument).pages.page_1
      .shapes;

    // if they are already on the map, don't add
    for (const shape of Object.values(currentShapes)) {
      if (!(isCardShape(shape) && isCardShapeMetaHubSpot(shape.meta))) {
        continue;
      }

      const objectRef: HGObjectRef = {
        objectType: shape.meta.objectType,
        objectId: shape.meta.objectId,
      };

      if (objectRefsEqual(objectRef, addingObjectRef)) {
        return;
      }
    }

    // add the object to our database
    store.hgObjects[hgObject.canonicalId] = _.cloneDeep(hgObject);

    // get current canvas position and new index for shape
    const point = fromAssociation
      ? app.tlApp.currentPoint
      : app.tlApp.centerPoint;
    const childIndex = Object.values(currentShapes).length + 1;

    // add the shape for it
    const shapeId = shortid();
    const shape: CardShape = {
      id: shapeId,
      type: TDDraw.TDShapeType.Card,
      parentId: "page_1",
      name: "Card",
      childIndex,
      point,
      style: {
        dash: TDDraw.DashStyle.Draw,
        size: TDDraw.SizeStyle.Large,
        color: TDDraw.ColorStyle.Blue,
      },
      meta: {
        metaType: "hsObject",
        objectType: hgObject.objectType,
        objectId: hgObject.objectId,
      },
    };

    wrapTLDrawUpdate((tlApp) => {
      tlApp.createShapes(shape);
    });

    // subscribe to this HGObject and its connections
    runInAction(() => {
      db.subscribeToHGObjects(store, {
        hgObjectRefs: [hgObject],
      });
    });
  },
);

export const startAddingAssociatedObject = makeAction(
  "startAddingAssociatedObject",
  (params: { objectType: string; objectId: string }) => {
    const { objectType, objectId } = params;
    store.draftAddingAssociatedToChart = {
      objectType,
      objectId,
    };
  },
);

export const cancelAddingAssociatedObject = makeAction(
  "cancelAddingAssociatedObject",
  () => {
    store.draftAddingAssociatedToChart = undefined;
  },
);

export const addAssociatedObjectToChart = makeAction(
  "addAssociatedObjectToChart",
  (params: { hgObject: HGObject }) => {
    const { hgObject } = params;
    addObjectToChart({
      hgObject,
      fromAssociation: true,
    });
    store.draftAddingAssociatedToChart = undefined;
  },
);

export const initRelationshipMap = makeActionAsync(
  "initRelationshipMap",
  async () => {
    if (store.initializedEnoughForMap || !store.auth.accessToken) {
      return;
    }

    const mapId = store.initialObject.objectId;

    const document = await firebaseApi.fetchRelationshipMap({
      portalId: store.portalId,
      id: mapId,
    });

    if (document) {
      wrapTLDrawUpdate((tlApp) => {
        tlApp.replacePageContent(
          document.shapes,
          document.bindings,
          document.assets,
        );
      });
    } else {
      const dealObjectRef: HGObjectRef = {
        objectId: store.initialObject.objectId,
        objectType: store.initialObject.objectType,
      };

      console.log("dealObjectRef to create map for", dealObjectRef);

      // find the unlabelled deal<->contact HGLabelPair
      const unlabelledDealContactLabelPair = Object.values(
        store.hgLabelPairs,
      ).find((hgLabelPair) => {
        return connection.labelPairIsUnlabelledPairForObjectTypes({
          labelPair: hgLabelPair,
          objectTypeA: "deal",
          objectTypeB: "contact",
        });
      });

      if (!unlabelledDealContactLabelPair) {
        throw new Error(
          "Cannot create map without unlabelled deal<->contact label pair",
        );
      }

      // fetch the things we need and get a doc with the directly associated contacts added as well as the deal
      const { doc, hgObjects, hgConnections } =
        await useCases.createInitialDealMapWithConnectedContacts({
          authState: { accessToken: store.auth.accessToken },
          dealObjectRef,
          unlabelledDealContactLabelPair: unlabelledDealContactLabelPair,
        });

      wrapTLDrawUpdate((tlApp) => {
        tlApp.replacePageContent(
          doc.pages.page_1.shapes,
          doc.pages.page_1.bindings,
          doc.assets,
        );
      });
      runInAction(() => {
        db.upsertHGConnections(store, hgConnections);
        db.upsertHGObjects(store, hgObjects);

        // ensure we are subscribed to all these objects
        console.log("subscribing to objects", [...hgObjects, dealObjectRef]);
        db.subscribeToHGObjects(store, {
          hgObjectRefs: [...hgObjects, dealObjectRef],
          force: true,
        });
      });

      // write the document to firestore
      console.log("writing initial document to firestore...", {
        portalId: store.portalId,
        mapId,
        doc,
      });
      await firebaseApi.syncRelationshipMapDocument({
        portalId: store.portalId,
        mapId,
        metadata: {
          mapVersion: 0,
        },
        document: {
          shapes: doc.pages.page_1.shapes,
          bindings: doc.pages.page_1.bindings,
          assets: doc.assets,
          version: doc.version,
        },
      });
    }

    runInAction(() => {
      store.initializedEnoughForMap = true;
    });

    debouncedCheckForFetches();
  },
);

export const checkForFetches = makeActionAsync(
  "checkForFetches",
  async (params: { force?: boolean } = {}) => {
    const { force = false } = params;

    const document = app.tlApp.document as TDDocument;

    const shapes = Object.values(document.pages.page_1.shapes);

    const hgObjectRefs = shapes
      .filter(isCardShape)
      .map((shape) => shape.meta)
      .filter(isCardShapeMetaHubSpot)
      .map((shapeMeta) => {
        return {
          objectId: shapeMeta.objectId,
          objectType: shapeMeta.objectType,
        };
      });

    // ensure we are subscribed to all these objects
    db.subscribeToHGObjects(store, {
      hgObjectRefs,
      force,
    });
  },
);

export const devResubscribeToHGObject = makeActionAsync(
  "devResubscribeToHGObject",
  async (params: { hgObjectRef: HGObjectRef }) => {
    db.subscribeToHGObjects(store, {
      hgObjectRefs: [params.hgObjectRef],
      force: true,
    });
  },
);

export const debouncedCheckForFetches = _.debounce(checkForFetches, 1000);

export const authenticationMaybeRefresh = makeAction(
  "authenticationMaybeRefresh",
  () => {
    try {
      // if we are using the local testing token then we can't refresh
      if (store.auth.queryToken) {
        return;
      }

      const accessToken = store.auth.accessToken;
      if (!accessToken) {
        return;
      }

      const decoded = jwtDecode<JwtPayload>(accessToken);

      const expiration = decoded.exp;
      if (!expiration) {
        return;
      }

      const nowSeconds = Date.now() / 1000;
      const diffSeconds = Math.floor(expiration - nowSeconds);
      if (diffSeconds < 60 * 5) {
        setTimeout(async () => {
          try {
            await authenticationRefresh();
          } catch (e) {
            console.error(e);
          }
        }, 0);
      }
    } catch (e) {
      console.error(e);
    }
  },
  {
    quiet: true,
  },
);

export const authenticationRefresh = makeActionAsync(
  "authenticationRefresh",
  async () => {
    const portalId = store.portalId;
    const refreshToken = store.auth.refreshToken;
    const nextAuthState = await authApi.refreshSession({
      portalId,
      refreshToken,
    });
    handleAuthenticateSuccess(nextAuthState);
  },
);

export const focusObject = makeAction(
  "focusObject",
  (params: { objectType: string; objectId: string }) => {
    const { objectType, objectId } = params;
    store.focusedObject = {
      objectType,
      objectId,
    };
    store.draftAddingObjectToChart = undefined;
  },
);

export const unfocusObject = makeAction("unfocusObject", () => {
  app.store.focusedObject = undefined;
});

export const startConfiguringDisplayProperties = makeAction(
  "startConfiguringDisplayProperties",
  (params: { objectType: string }) => {
    const existingDisplayProperties = _.cloneDeep(
      app.store.portal?.["hg-display-properties"] || [],
    );

    app.store.displayPropertiesConfigSession = {
      objectType: params.objectType,
      previousDisplayPropertyConfig: existingDisplayProperties,
    };
  },
);

export const finishConfiguringDisplayProperties = makeAction(
  "finishConfiguringDisplayProperties",
  () => {
    assert(app.store.displayPropertiesConfigSession);

    function displayPropertyKey(displayProperty: HGDisplayProperty): string {
      return `${displayProperty.objectType}:${displayProperty.name}`;
    }

    // if we have additional display properties then we need to fetch the objects again in case they have values for these properties
    const previousDisplayPropertyIndex = indexBy(
      app.store.displayPropertiesConfigSession.previousDisplayPropertyConfig,
      displayPropertyKey,
    );

    const haveNewDisplayPropertiesToFetch = (
      app.store.portal?.["hg-display-properties"] || []
    ).some((displayProperty) => {
      const key = displayPropertyKey(displayProperty);
      return !previousDisplayPropertyIndex[key];
    });

    if (haveNewDisplayPropertiesToFetch) {
      setTimeout(() => {
        checkForFetches({ force: true }).catch(console.error);
      }, 1);
    }

    app.store.displayPropertiesConfigSession = undefined;
  },
);

export const addDisplayProperty = makeActionAsync(
  "addDisplayProperty",
  async (params: { name: string; showOnCard: boolean; objectType: string }) => {
    const { name, objectType, showOnCard } = params;

    const portalId = store.portalId;
    const portal = store.portal;

    if (!portalId) {
      throw new Error("Cannot addDisplayProperty without portalId in store");
    }

    if (!portal) {
      throw new Error("Cannot addDisplayProperty without portal in store");
    }

    const existingDisplayProperties = portal["hg-display-properties"] || [];
    const nextDisplayProperties = _.chain(existingDisplayProperties)
      .filter(
        (displayProperty) =>
          !(
            displayProperty.name === name &&
            displayProperty.objectType === objectType
          ),
      )
      .push({
        name,
        showOnCard,
        objectType,
      })
      .value();

    const portalRef = firebaseApi.portalDocRef(portalId);

    const patch: {
      "hg-display-properties": HGDisplayProperty[];
    } = {
      "hg-display-properties": nextDisplayProperties,
    };

    await portalRef.update(patch);
  },
);

export const removeDisplayProperty = makeActionAsync(
  "removeDisplayProperty",
  async (params: { name: string; objectType: string }) => {
    const { name, objectType } = params;

    const portalId = store.portalId;
    const portal = store.portal;

    if (!portalId) {
      throw new Error("Cannot removeDisplayProperty without portalId in store");
    }

    if (!portal) {
      throw new Error("Cannot removeDisplayProperty without portal in store");
    }

    const existingDisplayProperties = portal["hg-display-properties"] || [];
    const nextDisplayProperties = _.chain(existingDisplayProperties)
      .filter(
        (displayProperty) =>
          !(
            displayProperty.name === name &&
            displayProperty.objectType === objectType
          ),
      )
      .value();

    const portalRef = firebaseApi.portalDocRef(portalId);

    const patch: {
      "hg-display-properties": HGDisplayProperty[];
    } = {
      "hg-display-properties": nextDisplayProperties,
    };

    await portalRef.update(patch);
  },
);

export const updateDisplayProperty = makeActionAsync(
  "updateDisplayProperty",
  async (params: { name: string; showOnCard: boolean; objectType: string }) => {
    const { name, objectType, showOnCard } = params;

    const portalId = store.portalId;
    const portal = store.portal;

    if (!portalId) {
      throw new Error("Cannot updateDisplayProperty without portalId in store");
    }

    if (!portal) {
      throw new Error("Cannot updateDisplayProperty without portal in store");
    }

    const existingDisplayProperties = portal["hg-display-properties"] || [];
    const nextDisplayProperties = _.chain(existingDisplayProperties)
      .map((displayProperty) => {
        if (
          displayProperty.name === name &&
          displayProperty.objectType === objectType
        ) {
          return {
            ...displayProperty,
            showOnCard,
          };
        } else {
          return displayProperty;
        }
      })
      .value();

    const portalRef = firebaseApi.portalDocRef(portalId);

    const patch: {
      "hg-display-properties": HGDisplayProperty[];
    } = {
      "hg-display-properties": nextDisplayProperties,
    };

    await portalRef.update(patch);
  },
);

export const openHSObjectInHubSpot = makeAction(
  "openHSObjectInHubSpot",
  (params: { objectRef: HGObjectRef }) => {
    const url = hubspotURLForObjectRef({
      portalId: store.portalId,
      portalHSDomain: "app.hubspot.com",
      objectRef: params.objectRef,
    });
    window.open(url, "_blank");
  },
);

export const togglePrivacyMode = makeAction("togglePrivacyMode", () => {
  store.privacyMode = !store.privacyMode;
});

export const toggleDevMenu = makeAction("toggleDevMenu", () => {
  store.devMenuVisible = !store.devMenuVisible;
});

export const addNote = makeAction("addNote", () => {
  wrapTLDrawUpdate((tlApp) => {
    throw new Error("needs reimplementation");
    // const id = shortid();
    // // get current canvas position and new index for shape
    // const point = app.tlApp.centerPoint;
    // const currentShapes = (app.tlApp.document as TDDocument).pages.page_1
    //   .shapes;
    // const childIndex = Object.values(currentShapes).length + 1;

    // const noteShape: CardShape = {
    //   type: TDShapeType.Card,
    //   id,
    //   parentId: "page_1",
    //   name: "Card",
    //   childIndex,
    //   point,
    //   style: {
    //     dash: TDDraw.DashStyle.Draw,
    //     size: TDDraw.SizeStyle.Large,
    //     color: TDDraw.ColorStyle.Blue,
    //   },
    //   meta: {
    //     metaType: "hsObject",
    //     objectId: "27139815333",
    //     objectType: "note",
    //   },
    // };
    // console.log("noteShape", noteShape);
    // tlApp.createShapes(noteShape);
  });
});

export const addLocalNote = makeAction("addLocalNote", () => {
  wrapTLDrawUpdate((tlApp) => {
    throw new Error("needs reimplementation");
    // // const id = shortid();

    // const id = "test_local_note";

    // // get current canvas position and new index for shape
    // const point = app.tlApp.centerPoint;
    // const currentShapes = (app.tlApp.document as TDDocument).pages.page_1
    //   .shapes;
    // const childIndex = Object.values(currentShapes).length + 1;

    // const noteShape: CardShape = {
    //   type: TDShapeType.Card,
    //   id,
    //   parentId: "page_1",
    //   name: "Card",
    //   childIndex,
    //   point,
    //   style: {
    //     dash: TDDraw.DashStyle.Draw,
    //     size: TDDraw.SizeStyle.Large,
    //     color: TDDraw.ColorStyle.Blue,
    //   },
    //   meta: {
    //     metaType: "hgObject",
    //     objectType: "note",
    //   },
    // };
    // console.log("noteShape", noteShape);
    // tlApp.createShapes(noteShape);
  });
});

/* @ts-ignore */
window.HG_SAVE_NOTE = () => {
  createNoteInHubSpot({
    nodeId: "test_local_note",
    content: `something about the thing: ${new Date().toLocaleDateString()}`,
  }).catch(console.error);
};

/* @ts-ignore */
window.HG_ADD_LOCAL_NOTE = () => {
  addLocalNote();
};

export const createNoteInHubSpot = makeActionAsync(
  "createNoteInHubSpot",
  async (params: { nodeId: string; content: string }) => {
    throw new Error("needs reimplementation");
    // await delay(200);

    // const accessToken = store.auth.accessToken;
    // if (!accessToken) {
    //   return;
    // }

    // const existingShape = withTLDraw((tlApp) => {
    //   const currentDoc = tlApp.document;
    //   const shapeId = params.nodeId;
    //   const existingShape = currentDoc.pages.page_1.shapes[shapeId];

    //   console.log(
    //     "SHAPE BEFORE UPDATE",
    //     JSON.parse(JSON.stringify(existingShape)),
    //   );

    //   return existingShape;
    // });

    // if (!existingShape) {
    //   return;
    // }

    // // create note in HubSpot then update the local note object to reflect that it's now stored on HubSpot
    // const hgObject = await hsApi.createObject({
    //   authState: { accessToken },
    //   objectType: "note",
    //   properties: {
    //     hs_note_body: params.content,
    //     hs_timestamp: DateTime.utc().toISO(),
    //   },
    // });

    // runInAction(() => {
    //   wrapTLDrawUpdate((tlApp) => {
    //     console.log(
    //       "before shape updated",
    //       JSON.parse(JSON.stringify(tlApp.document)),
    //     );

    //     const currentDoc = tlApp.document;
    //     const shapeId = params.nodeId;
    //     const existingShape = currentDoc.pages.page_1.shapes[shapeId];

    //     console.log(
    //       "SHAPE BEFORE UPDATE",
    //       JSON.parse(JSON.stringify(existingShape)),
    //     );

    //     if (!existingShape) {
    //       throw new Error("Could not find shape to update");
    //     }

    //     const nextShapeMeta: CardShapeMetaHubSpot = {
    //       metaType: "hsObject",
    //       objectType: "note",
    //       objectId: hgObject.objectId,
    //     };

    //     const next = {
    //       ...currentDoc,
    //       pages: {
    //         ...currentDoc.pages,
    //         page_1: {
    //           ...currentDoc.pages.page_1,
    //           shapes: {
    //             ...currentDoc.pages.page_1.shapes,
    //             [shapeId]: {
    //               ...existingShape,
    //               meta: nextShapeMeta,
    //             },
    //           },
    //         },
    //       },
    //     };
    //     tlApp.updateDocument(next);

    //     console.log(
    //       "after shape updated",
    //       JSON.parse(JSON.stringify(tlApp.document)),
    //     );
    //   });
    // });
  },
);

export const updateNoteInHubSpot = makeActionAsync(
  "updateNoteInHubSpot",
  async (params: { nodeId: string; content: string }) => {
    throw new Error("needs reimplementation");
    // const accessToken = store.auth.accessToken;
    // if (!accessToken) {
    //   throw new Error("Cannot updateNoteInHubSpot without accessToken");
    // }

    // const shape = withTLDraw((tlApp) => {
    //   const currentDoc = tlApp.document;
    //   const shapeId = params.nodeId;
    //   const shape = currentDoc.pages.page_1.shapes[shapeId];

    //   return shape;
    // });

    // const isHubSpotCardNodeShape =
    //   isCardShapeHubSpot(shape) && shape.meta.objectType === "note";

    // if (!isHubSpotCardNodeShape) {
    //   throw new Error("Cannot updateNoteInHubSpot for non-note card shape");
    // }

    // const hsObjectId = shape.meta.objectId;
    // const hsObjectType = shape.meta.objectType;

    // // actually update HubSpot
    // const updatedHGObject = await hsApi.updateObject({
    //   authState: { accessToken },
    //   objectId: hsObjectId,
    //   objectType: hsObjectType,
    //   properties: {
    //     hs_note_body: params.content,
    //   },
    // });

    // console.log("updated hgobject", updatedHGObject);

    // runInAction(() => {
    //   upsertMany({
    //     entities: [updatedHGObject],
    //     state: store.hgObjects,
    //   });
    // });
  },
);

export const setCanUndo = makeAction(
  "setCanUndo",
  (params: { canUndo: boolean }) => {
    store.currentRelationshipMap.canUndo = params.canUndo;
  },
);

export const setCanRedo = makeAction(
  "setCanRedo",
  (params: { canRedo: boolean }) => {
    store.currentRelationshipMap.canRedo = params.canRedo;
  },
);

/**
 * This is called by our liveblocks react hook so that we can tell in the
 * rest of the UI when we are waiting on the the liveblocks document to be
 * either setup or loaded. Not great but will do for now.
 */
export const setLoadingMultiplayer = makeAction(
  "setLoadingMultiplayer",
  (params: { loadingMultiplayer: boolean }) => {
    store.loadingMultiplayer = params.loadingMultiplayer;
  },
);

export const showBuyingGroupAnalysis = makeAction(
  "showBuyingGroupAnalysis",
  () => {
    store.buyingGroupAnalysisVisible = true;
  },
);

export const hideBuyingGroupAnalysis = makeAction(
  "hideBuyingGroupAnalysis",
  () => {
    store.buyingGroupAnalysisVisible = false;
  },
);

export const toggleBuyingGroupAnalysis = makeAction(
  "toggleBuyingGroupAnalysis",
  () => {
    store.buyingGroupAnalysisVisible = !store.buyingGroupAnalysisVisible;
  },
);

export const devPerformLayout = makeActionAsync(
  "devPerformLayout",
  async () => {
    const dealObjectRef: HGObjectRef = store.initialObject;

    const hgConnections: connection.HGConnection[] = Object.values(
      store.hgConnections,
    );

    const dealToContactHGConnections: connection.HGConnection[] =
      connection.hgConnectionsForDealContacts({
        dealObjectRef,
        hgConnections,
      });

    const result = await concentric.layout({
      hgConnections: dealToContactHGConnections,
    });

    wrapTLDrawUpdate((tlApp) => {
      const shapes = tlApp.getShapes();

      const updates: {
        id: string;
        point: number[];
      }[] = _.chain(result)
        .map((bounds, canonicalId) => {
          const shape = shapeByObjectCanonicalId(shapes, canonicalId);
          if (!shape) {
            return null;
          }
          return {
            id: shape.id,
            point: [bounds.x, bounds.y],
          };
        })
        .compact()
        .value();

      tlApp.updateShapes(...updates);
    });
  },
);

export const devToggleCache = makeAction("devToggleCache", () => {
  localStorage.setItem("devCacheEnabled", DEV_CACHE_ENABLED ? "false" : "true");
  window.location.reload();
});

export const devToggleV3V4FetchConnections = makeAction(
  "devToggleV3V4FetchConnections",
  () => {
    localStorage.setItem(
      "devUseV3FetchConnections",
      DEV_USE_V3_API_FOR_FETCH_CONNECTIONS ? "false" : "true",
    );
    window.location.reload();
  },
);

// dev helpers
// @ts-ignore
window.actions = {};
// @ts-ignore
window.actions.refreshAssociations = () => {
  checkForFetches({ force: true });
};
// @ts-ignore
window.actions.layout = async () => {
  devPerformLayout();
};
