import { useHotkeys } from "react-hotkeys-hook";
import * as actions from "../actions";

export function useKeyboardShortcuts() {
  useHotkeys(
    "esc",
    (e) => {
      console.log("on esc");
    },
    {
      enabled: false,
    },
  );

  useHotkeys("option+shift+d", (e) => {
    actions.toggleDevMenu();
  });
}
