import _ from "lodash";
import { assertNever } from "../utils";
import * as domain from "./index";
import * as propertyDomain from "./property";

const VALUE_RESOLVING_SUBSCRIPTION_TYPES = [
  "HGObject",
  "AllOwners",
  "AllDealPipelines",
] as const;
type ValueResolvingSubscriptionType =
  typeof VALUE_RESOLVING_SUBSCRIPTION_TYPES[number];

export type ValueResolvingSubscriptionHGObject = {
  type: Extract<ValueResolvingSubscriptionType, "HGObject">;
  id: string;
  // TODO: change this to HGObjectRef type
  hgObjectCanonicalId: string;
  createdAt: string;
};

export type ValueResolvingSubscriptionAllOwners = {
  type: Extract<ValueResolvingSubscriptionType, "AllOwners">;
  id: "AllOwners";
  createdAt: string;
  owners: domain.HSOwner[];
};

export type ValueResolvingSubscriptionAllDealPipelines = {
  type: Extract<ValueResolvingSubscriptionType, "AllDealPipelines">;
  id: "AllDealPipelines";
  createdAt: string;
  dealPipelines: domain.HSPipeline[];
};

export type ValueResolvingSubscription =
  | ValueResolvingSubscriptionHGObject
  | ValueResolvingSubscriptionAllOwners
  | ValueResolvingSubscriptionAllDealPipelines;

export function isHGObjectValueResolvingSubscription(
  params: ValueResolvingSubscription,
): params is ValueResolvingSubscriptionHGObject {
  return params.type === "HGObject";
}

export function idForValueResolvingSubscription(
  params:
    | {
        type: Extract<ValueResolvingSubscriptionType, "AllOwners">;
      }
    | {
        type: Extract<ValueResolvingSubscriptionType, "AllDealPipelines">;
      }
    | {
        type: Extract<ValueResolvingSubscriptionType, "HGObject">;
        hgObjectCanonicalId: string;
      },
): string {
  if (params.type === "AllOwners") {
    return "AllOwners";
  } else if (params.type === "AllDealPipelines") {
    return "AllDealPipelines";
  } else if (params.type === "HGObject") {
    return `HGObject:${params.hgObjectCanonicalId}`;
  } else {
    assertNever(params);
  }
}

export function makeResolvingValueSubscription(
  params:
    | {
        type: Extract<ValueResolvingSubscriptionType, "AllOwners">;
      }
    | {
        type: Extract<ValueResolvingSubscriptionType, "AllDealPipelines">;
      }
    | {
        type: Extract<ValueResolvingSubscriptionType, "HGObject">;
        hgObjectCanonicalId: string;
      },
): ValueResolvingSubscription {
  const createdAt = new Date().toISOString();
  if (params.type === "AllOwners") {
    return {
      type: "AllOwners",
      id: "AllOwners",
      createdAt,
      owners: [],
    };
  } else if (params.type === "AllDealPipelines") {
    return {
      type: "AllDealPipelines",
      id: "AllDealPipelines",
      createdAt,
      dealPipelines: [],
    };
  } else if (params.type === "HGObject") {
    return {
      type: "HGObject",
      id: `HGObject:${params.hgObjectCanonicalId}`,
      createdAt,
      hgObjectCanonicalId: params.hgObjectCanonicalId,
    };
  } else {
    assertNever(params);
  }
}

export function isValueResolvingType(
  value: string,
): value is ValueResolvingSubscriptionType {
  return value === "OWNER" || value === "DEAL_PIPELINE";
}

export function hsPropertyValueResolvingType(
  property: propertyDomain.HSProperty,
): ValueResolvingSubscriptionType | undefined {
  if (
    property.type === "enumeration" &&
    property.referencedObjectType &&
    property.referencedObjectType === "OWNER"
  ) {
    return "AllOwners";
  }

  if (
    property.objectType === "deal" &&
    (property.name === "dealstage" || property.name === "pipeline")
  ) {
    return "AllDealPipelines";
  }

  if (property.externalOptions && property.referencedObjectType === "COMPANY") {
    return "HGObject";
  }

  return;
}

/**
 * Returns true if the property requires resolving values from HubSpot API in
 * some way. Not a complete list, but a complete list of the values we are
 * willing to resolve.
 */
export function hsPropertyRequiresResolvingValues(
  property: propertyDomain.HSProperty,
): boolean {
  return typeof hsPropertyValueResolvingType(property) !== "undefined";
}

/**
 * True if this property is an enumeration field that we can turn
 * values into display labels for without having to go to the
 * HubSpot API for more information. This is true for fields where
 * all the options are defined in the property definition rather than
 * fields such as `hubspot_owner_id` where the value is the ID of an
 * 'Owner' object that needs to be looked up in order to turn the ID
 * into a display label.
 */
export function hsPropertyCanSelfResolveValues(
  property: propertyDomain.HSProperty,
): boolean {
  return (
    (property.type === "enumeration" && !property.externalOptions) ||
    typeof hsPropertyValueResolvingType(property) === "undefined"
  );
}

export function requiredResolvingValueSubscriptionsForHGObject(params: {
  hgObject: domain.HGObject;
  hsProperties: propertyDomain.HSProperty[];
}): ValueResolvingSubscription[] {
  const { hgObject } = params;
  // safer to make sure these properties actually require resolving values,
  // and that they actually refer to this object type
  const hsProperties = params.hsProperties
    .filter((hsProperty) => hsProperty.objectType === hgObject.objectType)
    .filter(hsPropertyRequiresResolvingValues)
    .filter(
      (hsProperty) => hsPropertyValueResolvingType(hsProperty) === "HGObject",
    );

  const resolvingValueSubs = _.chain(hsProperties)
    .map((hsProperty) => {
      console.log("checking property", hsProperty);
      const referencedObjectType = hsProperty.referencedObjectType;
      if (!referencedObjectType) {
        return;
      }
      const hgObjectValue = hgObject.properties?.[hsProperty.name] || null;
      if (hgObjectValue !== null) {
        return makeResolvingValueSubscription({
          type: "HGObject",
          hgObjectCanonicalId: domain.canonicalIdForHGObjectRef({
            objectType: referencedObjectType.toLowerCase(),
            objectId: hgObjectValue,
          }),
        });
      }
    })
    .compact()
    .value();

  return resolvingValueSubs;
}
