import { observer } from "mobx-react-lite";
import * as computeds from "../../computeds";
import { HGObject, objectDisplayName } from "../../domain";
import { Tag } from "../primitives/Tag";
import {
  fgColorClass,
  bgColorClasses,
  iconComponentForObjectType,
} from "./shared";
import {
  CenterFocusStrong as CenterFocusStrongIcon,
  OpenInNew as OpenInFullIcon,
} from "@mui/icons-material";

const HGObjectCardHeaderButtonContact: React.FC<{
  children: React.ReactNode;
  onClick: () => void;
  isLast?: boolean;
}> = (props) => {
  const { children, onClick, isLast } = props;

  const hoverBg = `hover:bg-purple-300`;
  const activeBg = `active:bg-purple-400`;
  const text = "text-purple-900";

  return (
    <button
      onClick={onClick}
      className={`transition-all all-unset flex flex-col items-center justify-center ${text} ${hoverBg} ${activeBg} cursor-pointer px-2 text-xl h-full ${
        isLast ? "pr-3" : ""
      }`}
    >
      {children}
    </button>
  );
};

export const HGObjectCardPreviewContact: React.FC<{
  hgObject: HGObject;
  onOpenInHubSpot: () => void;
  onFocusOnMap: () => void;
}> = (props) => {
  const { hgObject, onOpenInHubSpot, onFocusOnMap } = props;

  return (
    <HGObjectCardWrapper objectType={hgObject.objectType}>
      <HGObjectCardHeader objectType={hgObject.objectType}>
        <HGObjectCardHeaderButtonContact onClick={onFocusOnMap}>
          <CenterFocusStrongIcon fontSize="inherit" />
        </HGObjectCardHeaderButtonContact>

        <HGObjectCardHeaderButtonContact
          isLast={true}
          onClick={onOpenInHubSpot}
        >
          <OpenInFullIcon fontSize="inherit" />
        </HGObjectCardHeaderButtonContact>
      </HGObjectCardHeader>

      <HGObjectCardDisplayName hgObject={hgObject} />
    </HGObjectCardWrapper>
  );
};

export const HGObjectCardHeader: React.FC<{
  objectType: string;
  children?: React.ReactNode;
}> = observer((props) => {
  const { objectType, children } = props;

  let IconComponent = iconComponentForObjectType(objectType);

  const displayLabel = computeds.displayLabelForObjectTypeSingular(objectType);

  return (
    <div
      className={`${bgColorClasses(objectType).dark} border-b-2 ${
        bgColorClasses(objectType).darkBorder
      } flex flex-row space-between`}
    >
      <div className="flex-1 flex flex flex-row flex-row items-center space-x-1.5 px-1 py-2">
        <span
          className={`${fgColorClass(
            objectType,
          )} ml-1 inline-flex text-[1.25rem]`}
        >
          {IconComponent ? (
            <IconComponent color="inherit" fontSize="inherit" />
          ) : null}
        </span>
        <Tag colorClass={fgColorClass(objectType)} label={displayLabel} />
      </div>

      {children ? <div className="flex flex-row">{children}</div> : null}
    </div>
  );
});

export const HGObjectCardWrapper = (props: {
  objectType: string;
  children: React.ReactNode;
}) => {
  const { objectType, children } = props;

  return (
    <div
      className={`${
        bgColorClasses(objectType).darkBorder
      } user-select-none flex w-full flex-col overflow-hidden rounded-xl border-2 bg-white shadow`}
    >
      {children}
    </div>
  );
};

export const HGObjectCardDisplayName = (props: { hgObject: HGObject }) => {
  const { hgObject } = props;

  return (
    <div
      className={`${
        bgColorClasses(hgObject.objectType).light
      } whitespace-nowrap p-2 h-[35px] flex items-center`}
    >
      <h5
        className={
          "hg-privacy truncate text-sm font-medium tracking-tight text-slate-800 blur"
        }
      >
        {objectDisplayName(hgObject)}
      </h5>
    </div>
  );
};

export const HGObjectCardRow = (props: { children: React.ReactNode }) => {
  const { children } = props;
  return (
    <div className="bg-white whitespace-nowrap p-2 h-[35px] flex items-center">
      <h5
        className={
          "hg-privacy truncate text-sm font-medium tracking-tight text-slate-800 blur"
        }
      >
        {children}
      </h5>
    </div>
  );
};
