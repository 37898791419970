export const Tag = (props: { label: string; colorClass: string }) => {
  const { colorClass } = props;
  return (
    <div
      className={`${colorClass} flex flex-row items-center rounded-md bg-white bg-opacity-70 px-1 text-sm font-semibold leading-6`}
    >
      {props.label}
    </div>
  );
};
