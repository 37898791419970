import { Button, TextField } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { useStore } from "../../hooks/hooks";
import {
  BuyingGroupAnalysisCondition,
  BuyingGroupAnalysisConfig,
  BuyingGroupSizeCondition,
  buyingRoleOptions,
  BuyingRolePresenceCondition,
} from "./helpers";
import Select from "react-select";
import shortid from "shortid";

const ConfigureBuyingGroupSize = (props: {
  condition: BuyingGroupSizeCondition;
  onUpdateCondition: (nextCondition: BuyingGroupSizeCondition) => void;
}) => {
  const { condition, onUpdateCondition } = props;

  return (
    <div className="flex flex-row justify-between p-4">
      <div className="mr-4">
        <span className="text-base text-slate-800">
          Required buying group size
        </span>
      </div>
      <div className="w-[70px]">
        <TextField
          variant="outlined"
          size="small"
          type="number"
          value={condition.size}
          onChange={(e) => {
            const size = parseInt(e.target.value.trim());
            onUpdateCondition({
              ...condition,
              size,
            });
          }}
        />
      </div>
    </div>
  );
};

const ConfigureBuyingRolePresence = observer(
  (props: {
    condition: BuyingRolePresenceCondition;
    onUpdateCondition: (nextCondition: BuyingRolePresenceCondition) => void;
    onRemove: () => void;
  }) => {
    const { condition, onRemove, onUpdateCondition } = props;

    const store = useStore();

    const [count, setCount] = useState<number>(condition.count);

    const options = buyingRoleOptions(store);
    const selectValue = options.find(
      (option) => option.value === condition.buyingRole,
    );

    return (
      <div className="flex flex-col p-4 space-y-4">
        <div className="flex flex-col space-y-3">
          <div className="flex flex-row items-center space-x-2">
            <span>At least</span>
            <TextField
              variant="outlined"
              size="small"
              type="number"
              value={count}
              onChange={(e) => {
                const nextCount = Math.max(1, parseInt(e.target.value.trim()));
                setCount(nextCount);
              }}
            />
          </div>
          <span>
            contact{count === 1 ? "" : "s"} identified with buying role
          </span>
          <div className="flex flex-row w-full">
            <Select
              className="w-full"
              value={selectValue}
              options={options}
              onChange={(option) => {
                if (option) {
                  onUpdateCondition({
                    ...condition,
                    buyingRole: option.value,
                  });
                }
              }}
              isClearable={false}
            />
          </div>
        </div>

        <div className="flex flex-row">
          <Button color={"error"} onClick={onRemove}>
            Remove
          </Button>
        </div>
      </div>
    );
  },
);

const BuyingGroupCondition = (props: {
  condition: BuyingGroupAnalysisCondition;
  onRemoveRule: (id: string) => void;
  onUpdateCondition: (nextCondition: BuyingGroupAnalysisCondition) => void;
}) => {
  const { condition, onRemoveRule, onUpdateCondition } = props;
  const type = condition.type;

  switch (type) {
    case "buying-group-size": {
      return (
        <ConfigureBuyingGroupSize
          condition={condition}
          onUpdateCondition={onUpdateCondition}
        />
      );
    }
    case "buying-role-presence": {
      return (
        <ConfigureBuyingRolePresence
          condition={condition}
          onUpdateCondition={onUpdateCondition}
          onRemove={() => {
            onRemoveRule(condition.id);
          }}
        />
      );
    }
    default: {
      return (
        <div className="p-4">unsupported buying group condition: {type}</div>
      );
    }
  }
};

export const Configurer = (props: {
  initialConfig: BuyingGroupAnalysisConfig;
  onDone: (config: BuyingGroupAnalysisConfig) => void;
  onCancel: () => void;
}) => {
  const { initialConfig, onCancel, onDone } = props;

  const [config, setConfig] = useState(initialConfig);

  function handleAddNewRule() {
    setConfig((prev) => {
      const condition: BuyingRolePresenceCondition = {
        id: shortid(),
        type: "buying-role-presence",
        buyingRole: "BUDGET_HOLDER",
        count: 1,
      };
      const nextConditions = [...prev.conditions, condition];
      return {
        ...prev,
        conditions: nextConditions,
      };
    });
  }

  function handleRemoveRule(id: string) {
    setConfig((prev) => {
      const nextConditions = prev.conditions.filter(
        (condition) => condition.id !== id,
      );
      return {
        ...prev,
        conditions: nextConditions,
      };
    });
  }

  function handleUpdateRule(nextCondition: BuyingGroupAnalysisCondition) {
    setConfig((prev) => {
      const nextConditions = prev.conditions.map((condition) => {
        if (condition.id === nextCondition.id) {
          return nextCondition;
        }
        return condition;
      });
      return {
        ...prev,
        conditions: nextConditions,
      };
    });
  }

  return (
    <div className="flex-1 min-h-0 flex flex-col">
      <div className="grow divide-y-2 divide-slate-300 border-b-2 border-b-slate-300 overflow-y-scroll">
        {config.conditions.map((condition) => {
          return (
            <BuyingGroupCondition
              key={condition.id}
              condition={condition}
              onRemoveRule={handleRemoveRule}
              onUpdateCondition={handleUpdateRule}
            />
          );
        })}
        <div className="flex items-center justify-center p-4">
          <Button
            color="primary"
            variant="contained"
            onClick={handleAddNewRule}
          >
            Add another
          </Button>
        </div>
      </div>

      <div className="p-4 flex flex-row justify-between">
        <Button onClick={onCancel}>Cancel</Button>
        <Button
          variant="contained"
          onClick={() => {
            onDone(config);
          }}
        >
          Done
        </Button>
      </div>
    </div>
  );
};
