import firebase from "firebase/app";
// side-effect importing all the firebase modules we need, not great
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";

import { TDAsset, TDBinding, TDShape } from "@orgcharthub/tldraw-tldraw";
import { debounce } from "lodash";
import { DateTime } from "luxon";
import * as config from "../config";
import {
  DateTimeFromFirestoreTimestamp,
  HGPortal,
  RelationshipMap,
  RelationshipMapRecord,
  DEFAULT_DISPLAY_PROPERTIES,
  canonicalIdForHGObjectRef,
  HGObjectRef,
  HGObject,
  isCardShapeHubSpot,
  RelationshipMapHSObjectIndex,
} from "../domain";
import { HGDisplayProperty } from "../domain/property";
import { assertNever, parseOrThrow } from "../utils";
import * as ts from "io-ts";
import _ from "lodash";
import { HGConnection } from "../domain/connection";

export function startFirebase(): void {
  if (firebase.apps.length === 0) {
    firebase.initializeApp({
      apiKey: config.FIREBASE_API_KEY,
      authDomain: config.FIREBASE_AUTH_DOMAIN,
      databaseURL: config.FIREBASE_DATABASE_URL,
      projectId: config.FIREBASE_PROJECT_ID,
      storageBucket: config.FIREBASE_STORAGE_BUCKET,
      messageSenderId: config.FIREBASE_MESSAGE_SENDER_ID,
    });
  }
}

interface RelationshipMapDocument {
  version: number;
  shapes: Record<string, TDShape>;
  bindings: Record<string, TDBinding>;
  assets: Record<string, TDAsset>;
}

type SerialisedRelationshipMapDocument = Omit<
  RelationshipMapDocument,
  "shapes"
> & {
  shapes: Record<string, string>;
};

export function makeHSObjectIndexFromDocument(
  document: RelationshipMapDocument,
): RelationshipMapHSObjectIndex {
  return _.chain(document.shapes)
    .values()
    .filter(isCardShapeHubSpot)
    .reduce((acc, shape) => {
      const canonicalId = canonicalIdForHGObjectRef(shape.meta);
      acc[canonicalId] = true;
      return acc;
    }, {} as RelationshipMapHSObjectIndex)
    .value();
}

function serialiseDocument(
  document: RelationshipMapDocument,
): SerialisedRelationshipMapDocument {
  const previousShapes = document.shapes;

  const nextShapes = _.reduce(
    previousShapes,
    (acc, shape, k) => {
      acc[k] = JSON.stringify(shape);
      return acc;
    },
    {} as Record<string, string>,
  );

  return {
    ...document,
    shapes: nextShapes,
  };
}

function unserialiseDocument(
  document: SerialisedRelationshipMapDocument,
): RelationshipMapDocument {
  const previousShapes = document.shapes;

  const nextShapes = _.reduce(
    previousShapes,
    (acc, shape, k) => {
      acc[k] = JSON.parse(shape);
      return acc;
    },
    {} as Record<string, TDShape>,
  );

  return {
    ...document,
    shapes: nextShapes,
  };
}

export async function syncRelationshipMapDocument(params: {
  portalId: string;
  mapId: string;
  document: RelationshipMapDocument;
  metadata: {
    mapVersion: number;
  };
}): Promise<void> {
  const { portalId, mapId, document, metadata } = params;

  const serialised = serialiseDocument(document);
  console.log("document", document);
  console.log("serialised", serialised);

  const mapDocRef = firebase
    .firestore()
    .collection("portals")
    .doc(portalId)
    .collection("deal-maps")
    .doc(mapId);

  const docRef = mapDocRef.collection("documents").doc("current");

  await firebase.firestore().runTransaction(async (transaction) => {
    const mapDoc = await mapDocRef.get();
    const doc = await docRef.get();

    const nextHSObjectIndex = makeHSObjectIndexFromDocument(document);

    if (mapDoc.exists) {
      const Patch = ts.type({
        updatedAt: DateTimeFromFirestoreTimestamp,
        hsObjectIndex: RelationshipMapHSObjectIndex,
      });
      const patch: ts.TypeOf<typeof Patch> = {
        updatedAt: DateTime.utc().toISO(),
        hsObjectIndex: nextHSObjectIndex,
      };
      mapDocRef.update(Patch.encode(patch));
    } else {
      const mapDoc: RelationshipMap = {
        archived: false,
        createdAt: DateTime.utc().toISO(),
        updatedAt: DateTime.utc().toISO(),
        hsObjectIndex: {},
        description: "",
        name: "",
        id: mapId,
        isTemplate: false,
        portalId: portalId,
      };
      mapDocRef.set(RelationshipMap.encode(mapDoc));
    }

    if (!doc.exists) {
      const patch: RelationshipMapRecord = {
        id: mapId,
        portalId: portalId,
        document: serialised,
        metadata: metadata,
      };
      console.log("firestore create doc", patch);
      transaction.set(docRef, patch);
    } else {
      const parsed = parseOrThrow(RelationshipMapRecord, doc.data());

      // if the version number is the same then nothing to do - another client
      // must have already updated this document for us
      if (parsed.metadata.mapVersion === metadata.mapVersion) {
        return;
      } else {
        const patch: Pick<RelationshipMapRecord, "document" | "metadata"> = {
          document: serialised,
          metadata,
        };
        console.log("firestore update doc", patch);
        // if the version number is not the same then apply the update to keep
        // our firestore document in-sync with the liveblocks document
        transaction.update(docRef, patch);
        return;
      }
    }
  });

  return;
}

export async function fetchRelationshipMap(params: {
  portalId: string;
  id: string;
}): Promise<RelationshipMapDocument | undefined> {
  const { portalId, id } = params;

  const mapDocRef = mapRef(portalId, id);
  const doc = await mapDocRef.collection("documents").doc("current").get();
  const data = doc.data();

  if (doc.exists && data) {
    return unserialiseDocument(data.document);
  }
}

export const debouncedSyncRelationshipMapDocument = debounce(
  syncRelationshipMapDocument,
  500,
);

export function portalDocRef(
  portalId: string,
): firebase.firestore.DocumentReference {
  return firebase.firestore().collection("portals").doc(portalId);
}

export function mapsCollectionRef(
  portalId: string,
): firebase.firestore.CollectionReference {
  return portalDocRef(portalId).collection("deal-maps");
}

export function mapRef(
  portalId: string,
  mapId: string,
): firebase.firestore.DocumentReference {
  return mapsCollectionRef(portalId).doc(mapId);
}

export async function addDefaultDisplayPropertiesIfRequired(params: {
  portalId: string;
}): Promise<void> {
  const { portalId } = params;
  const portalDoc = await portalDocRef(portalId).get();
  const portal = parseOrThrow(HGPortal, portalDoc.data());

  if (typeof portal["hg-display-properties"] === "undefined") {
    console.log("creating default display properties for portal");
    const patch: {
      "hg-display-properties": Readonly<HGDisplayProperty[]>;
    } = {
      "hg-display-properties": DEFAULT_DISPLAY_PROPERTIES,
    };
    await portalDoc.ref.update(patch);
  } else {
    console.log("no need to set default display properties, already got some", {
      existingDisplayProperties: portal["hg-display-properties"],
    });
  }

  return;
}

const CacheItemBase = ts.type({
  portalId: ts.string,
  cacheVersion: ts.string,
  createdAt: ts.string,
});

export const CacheItemHGConnection = ts.intersection([
  CacheItemBase,
  ts.type({
    type: ts.literal("HGConnection"),
    hgConnection: HGConnection,
    involvedCanonicalObjectIds: ts.array(ts.string),
  }),
]);
export type CacheItemHGConnection = ts.TypeOf<typeof CacheItemHGConnection>;

export const CacheItemHGObject = ts.intersection([
  CacheItemBase,
  ts.type({
    type: ts.literal("HGObject"),
    hgObject: HGObject,
  }),
]);
export type CacheItemHGObject = ts.TypeOf<typeof CacheItemHGObject>;

export const CacheItem = ts.union([CacheItemHGConnection, CacheItemHGObject]);
export type CacheItem = ts.TypeOf<typeof CacheItem>;

function makeCanonicalIdSafe(canonicalId: string): string {
  return canonicalId.replace(/\//g, "|");
}

function cacheRefForHGConnectionsCollection(
  portalId: string,
): firebase.firestore.CollectionReference {
  return firebase
    .firestore()
    .collection("portals")
    .doc(portalId)
    .collection("app-caches")
    .doc(config.FIRESTORE_APP_CACHE_VERSION)
    .collection("hs-caches")
    .doc("hgConnections")
    .collection("cacheItems");
}

function cacheRefForHGObjectsCollection(
  portalId: string,
): firebase.firestore.CollectionReference {
  return firebase
    .firestore()
    .collection("portals")
    .doc(portalId)
    .collection("app-caches")
    .doc(config.FIRESTORE_APP_CACHE_VERSION)
    .collection("hs-caches")
    .doc("hgObjects")
    .collection("cacheItems");
}

function cacheRefForHGConnectionCanonicalId({
  portalId,
  canonicalId,
}: {
  portalId: string;
  canonicalId: string;
}): firebase.firestore.DocumentReference {
  return cacheRefForHGConnectionsCollection(portalId).doc(
    makeCanonicalIdSafe(canonicalId),
  );
}

function cacheRefForHGObjectCanonicalId({
  portalId,
  canonicalId,
}: {
  portalId: string;
  canonicalId: string;
}): firebase.firestore.DocumentReference {
  return cacheRefForHGObjectsCollection(portalId).doc(
    makeCanonicalIdSafe(canonicalId),
  );
}

function cacheItemRef(
  cacheItem: CacheItem,
): firebase.firestore.DocumentReference {
  switch (cacheItem.type) {
    case "HGConnection": {
      return cacheRefForHGConnectionCanonicalId({
        portalId: cacheItem.portalId,
        canonicalId: cacheItem.hgConnection.canonicalId,
      });
    }
    case "HGObject": {
      return cacheRefForHGObjectCanonicalId({
        portalId: cacheItem.portalId,
        canonicalId: cacheItem.hgObject.canonicalId,
      });
    }
    default: {
      assertNever(cacheItem);
    }
  }
}

export async function persistCacheItems(
  cacheItems: CacheItem[],
): Promise<void> {
  try {
    const cacheItemsBatches = _.chunk(cacheItems, 500);

    for (const cacheItemsBatch of cacheItemsBatches) {
      const batchOperation = firebase.firestore().batch();

      for (const item of cacheItemsBatch) {
        const ref = cacheItemRef(item);
        batchOperation.set(ref, item);
      }

      await batchOperation.commit();
    }
  } catch (e) {
    // must not throw any errors - consumers are expected to fire-and-forget calls to this function
    console.error(e);
  }
}

export async function fetchCachedHGConnections(params: {
  portalId: string;
  involvingObjectRefs: HGObjectRef[];
}): Promise<CacheItemHGConnection[]> {
  const { portalId, involvingObjectRefs } = params;

  console.log("fetching connections involving objects", involvingObjectRefs);

  const objectCanonicalIdBatches = _.chain(involvingObjectRefs)
    .map(canonicalIdForHGObjectRef)
    .uniq()
    .chunk(10)
    .value();

  let cacheItems: CacheItemHGConnection[] = [];

  for (const canonicalIds of objectCanonicalIdBatches) {
    try {
      const snapshot = await cacheRefForHGConnectionsCollection(portalId)
        .where("involvedCanonicalObjectIds", "array-contains-any", canonicalIds)
        .get();
      const batchCacheItems = snapshot.docs.map((doc) =>
        parseOrThrow(CacheItemHGConnection, doc.data()),
      );
      cacheItems = [...cacheItems, ...batchCacheItems];
    } catch (e) {
      console.warn(e);
    }
  }

  return _.uniqBy(cacheItems, (item) => item.hgConnection.canonicalId);
}

export async function fetchCachedHGObjects(params: {
  portalId: string;
  objectRefs: HGObjectRef[];
}): Promise<CacheItemHGObject[]> {
  const { portalId, objectRefs } = params;

  console.log("fetching objects for object refs", objectRefs);

  const objectCanonicalIdBatches = _.chain(objectRefs)
    .map(canonicalIdForHGObjectRef)
    .uniq()
    .chunk(10)
    .value();

  let cacheItems: CacheItemHGObject[] = [];

  for (const canonicalIds of objectCanonicalIdBatches) {
    try {
      const snapshot = await cacheRefForHGObjectsCollection(portalId)
        .where("hgObject.canonicalId", "in", canonicalIds)
        .get();
      const batchCacheItems = snapshot.docs.map((doc) =>
        parseOrThrow(CacheItemHGObject, doc.data()),
      );
      cacheItems = [...cacheItems, ...batchCacheItems];
    } catch (e) {
      console.warn(e);
    }
  }

  return _.uniqBy(cacheItems, (item) => item.hgObject.canonicalId);
}

export function makeHGConnectionCacheItem({
  portalId,
  hgConnection,
}: {
  portalId: string;
  hgConnection: HGConnection;
}): CacheItemHGConnection {
  return {
    type: "HGConnection",
    cacheVersion: config.FIRESTORE_APP_CACHE_VERSION,
    portalId,
    hgConnection,
    involvedCanonicalObjectIds: [
      hgConnection.objectRefA,
      hgConnection.objectRefB,
    ].map(canonicalIdForHGObjectRef),
    createdAt: new Date().toISOString(),
  };
}

export function makeHGObjectCacheItem({
  portalId,
  hgObject,
}: {
  portalId: string;
  hgObject: HGObject;
}): CacheItemHGObject {
  return {
    type: "HGObject",
    cacheVersion: config.FIRESTORE_APP_CACHE_VERSION,
    portalId,
    hgObject,
    createdAt: new Date().toISOString(),
  };
}

function generateDealMapAssetPath({
  portalId,
  mapId,
  assetId,
  fileName,
}: {
  portalId: string;
  mapId: string;
  assetId: string;
  fileName: string;
}): string {
  const fileExtension = fileName.match(/[^.]+$/)![0];
  const path = `hubgraph/${portalId}/deal-maps/${mapId}/${assetId}.${fileExtension}`;
  return path;
}

export async function createDealMapAsset({
  file,
  portalId,
  mapId,
  assetId,
}: {
  file: File;
  portalId: string;
  mapId: string;
  assetId: string;
}): Promise<firebase.storage.UploadTaskSnapshot> {
  const path = generateDealMapAssetPath({
    portalId,
    mapId,
    assetId,
    fileName: file.name,
  });
  const storageRef = firebase.storage().ref(path);
  const result = await storageRef.put(file);

  return result;
}

export async function deleteDealMapAsset({
  portalId,
  mapId,
  assetId,
  fileName,
}: {
  portalId: string;
  mapId: string;
  assetId: string;
  fileName: string;
}): Promise<void> {
  const path = generateDealMapAssetPath({
    portalId,
    mapId,
    assetId,
    fileName,
  });
  const storageRef = firebase.storage().ref(path);
  await storageRef.delete();
}
