import { CardShape, TDShape } from "@orgcharthub/tldraw-tldraw";
import findPathIntersections from "path-intersection";
import {
  canonicalIdForHGObjectRef,
  HGObjectRef,
  isCardShapeHubSpot,
  objectRefsEqual,
} from "./index";

// Taken from https://gist.github.com/mbostock/8027637, added types and updated return type
//
// TODO - we can probably optimise this for our use-case by providing a `pointAtLength` as
// we already know roughly where the intersecting point will lie
export function closestPoint(
  pathNode: SVGPathElement,
  point: [x: number, y: number],
): {
  point: [x: number, y: number];
  distance: number;
  length: number;
} {
  function distance2(p: DOMPoint): number {
    var dx = p.x - point[0],
      dy = p.y - point[1];
    return dx * dx + dy * dy;
  }

  let pathLength: number = pathNode.getTotalLength();
  let precision: number = 8;
  let best: DOMPoint | undefined = undefined;
  let bestLength: number | undefined = undefined;
  let bestDistance: number = Infinity;

  // linear scan for coarse approximation
  for (
    var scan, scanLength = 0, scanDistance;
    scanLength <= pathLength;
    scanLength += precision
  ) {
    if (
      (scanDistance = distance2(
        (scan = pathNode.getPointAtLength(scanLength)),
      )) < bestDistance
    ) {
      best = scan;
      bestLength = scanLength;
      bestDistance = scanDistance;
    }
  }

  if (!bestLength) {
    bestLength = 0;
  }
  if (!best) {
    best = new DOMPoint(0, 0);
  }

  // binary search for precise estimate
  precision /= 2;
  while (precision > 0.5) {
    let before, after, beforeLength, afterLength, beforeDistance, afterDistance;
    if (
      (beforeLength = bestLength - precision) >= 0 &&
      (beforeDistance = distance2(
        (before = pathNode.getPointAtLength(beforeLength)),
      )) < bestDistance
    ) {
      best = before;
      bestLength = beforeLength;
      bestDistance = beforeDistance;
    } else if (
      (afterLength = bestLength + precision) <= pathLength &&
      (afterDistance = distance2(
        (after = pathNode.getPointAtLength(afterLength)),
      )) < bestDistance
    ) {
      best = after;
      bestLength = afterLength;
      bestDistance = afterDistance;
    } else {
      precision /= 2;
    }
  }

  return {
    point: [best.x, best.y],
    distance: Math.sqrt(bestDistance),
    length: bestLength,
  };
}

type Rect = { x: number; y: number; width: number; height: number };
type Vec = [x: number, y: number];

function rectToPath(rect: Rect): string {
  return `M ${rect.x},${rect.y} h${rect.width} v${rect.height} h-${rect.width} z`;
}

export function labelPointsForConnectionPath(params: {
  connectionNode: SVGPathElement;
  aRect: Rect;
  bRect: Rect;
}):
  | {
      a: Vec;
      b: Vec;
      midpoint: Vec;
    }
  | undefined {
  const { connectionNode, aRect, bRect } = params;

  const SIDE_LABEL_PADDING = 100;

  const aPath = rectToPath(aRect);
  const bPath = rectToPath(bRect);
  const connectionPath = connectionNode.getAttribute("d");

  if (!connectionPath) {
    throw new Error("Connection must have path");
  }

  const aIntersections = findPathIntersections(connectionPath, aPath);
  const bIntersections = findPathIntersections(connectionPath, bPath);

  const aIntersection = aIntersections[0];
  const bIntersection = bIntersections[0];

  if (!aIntersection || !bIntersection) {
    return;
  }

  const aPoint: Vec = [aIntersection.x, aIntersection.y];
  const bPoint: Vec = [bIntersection.x, bIntersection.y];

  const closestA = closestPoint(connectionNode, aPoint);
  const closestB = closestPoint(connectionNode, bPoint);

  const visibleLineLength = Math.abs(closestA.length - closestB.length);

  const midpointLength = closestA.length + visibleLineLength / 2;
  const midpointPoint = connectionNode.getPointAtLength(midpointLength);

  const sideALength = closestA.length + SIDE_LABEL_PADDING;
  const sideAPoint = connectionNode.getPointAtLength(sideALength);

  const sideBLength = closestB.length - SIDE_LABEL_PADDING;
  const sideBPoint = connectionNode.getPointAtLength(sideBLength);

  return {
    a: [sideAPoint.x, sideAPoint.y],
    b: [sideBPoint.x, sideBPoint.y],
    midpoint: [midpointPoint.x, midpointPoint.y],
  };
}

export function shapeByObjectRef(
  shapes: TDShape[],
  objectRef: HGObjectRef,
): CardShape | undefined {
  const cardShapes = shapes.filter(isCardShapeHubSpot);

  for (const shape of cardShapes) {
    const shapeObjectRef: HGObjectRef = {
      objectType: shape.meta.objectType,
      objectId: shape.meta.objectId,
    };

    if (objectRefsEqual(objectRef, shapeObjectRef)) {
      return shape;
    }
  }
}

export function shapeByObjectCanonicalId(
  shapes: TDShape[],
  canonicalId: string,
): CardShape | undefined {
  const cardShapes = shapes.filter(isCardShapeHubSpot);

  for (const shape of cardShapes) {
    const shapeObjectRef: HGObjectRef = {
      objectType: shape.meta.objectType,
      objectId: shape.meta.objectId,
    };
    if (canonicalIdForHGObjectRef(shapeObjectRef) === canonicalId) {
      return shape;
    }
  }
}
