import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { useStylesheet } from "../hooks/useStylesheet";
import _ from "lodash";
import * as TDDraw from "@orgcharthub/tldraw-tldraw";
import * as TLDraw from "@orgcharthub/tldraw-core";
import * as HGObjectCanvasNodes from "./HGObjectCanvasNodes";
import {
  HGCanvasObjectNode,
  isCardShapeHubSpot,
  isCardShapeHubGraph,
  CardShapeHubSpot,
  HGObjectRef,
  canonicalIdForHGObjectRef,
  objectDisplayName,
} from "../domain";
import * as connection from "../domain/connection";
import useResizeObserver from "@react-hook/resize-observer";
import { observer } from "mobx-react-lite";
import { Button, CircularProgress, Menu, MenuItem } from "@mui/material";
import { HGAppContext, useStore } from "../hooks/hooks";
import { app as hgApp } from "../store/store";
import type { HGApp, HGStore } from "../store/types";
import * as actions from "../actions";
import * as UIEdges from "./ui-edges";
import { runInAction } from "mobx";
import { AddHGObjectToMapSidebar } from "./AddHGObjectToMapSidebar";
import { HGObjectSidebarImpl } from "./HGObjectSidebar";
import { HGConnectionEditor } from "./HGConnectionEditor";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import { AddAssociatedToChartModal } from "./add-associated-to-chart-modal";

// import { RoomProvider } from "../utils/liveblocks";
// import { useMultiplayerState } from "../hooks/useMultiplayerState";
import { assert } from "../utils";
import { Toolbar } from "./Toolbar";
import { AppSidebar } from "./AppSidebar";
import { ZoomToolbar } from "./ZoomToolbar";
import { RelationshipMapSwitcherToolbar } from "./RelationshipMapSwitcherToolbar";
import { useKeyboardShortcuts } from "../hooks/useKeyboardShortcuts";
import {
  SENTRY_DSN,
  IS_PROD,
  DEV_USE_V3_API_FOR_FETCH_CONNECTIONS,
  DEV_CACHE_ENABLED,
} from "../config";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { FloatingToolbar } from "./FloatingToolbar";
import { UserCursor } from "./UserCursor";
import { useTLApp } from "../hooks/hooks";
import { BuyingGroupAnalysisSidebar } from "./BuyingGroupAnalysis";
import { RightToolbar } from "./RightToolbar";
import { debouncedSyncRelationshipMapDocument } from "../api/firebase";
import * as db from "../store/db";
import { useAssetManagement } from "../hooks/useAssetManagement";

if (IS_PROD) {
  Sentry.init({
    dsn: SENTRY_DSN,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1,
  });
}

type CardWrapperProps = TLDraw.TLComponentProps<
  TDDraw.CardShape,
  HTMLDivElement,
  TDDraw.TDMeta
> & {
  onSizeChange: (size: [width: number, height: number]) => void;
};

const CardInner: React.FC<{
  shape: TDDraw.CardShape;
}> = observer((props) => {
  const { shape } = props;

  // console.log("CardInner shape", shape);

  if (
    (isCardShapeHubSpot(shape) && shape.meta.objectType === "note") ||
    isCardShapeHubGraph(shape)
  ) {
    let node: HGCanvasObjectNode;

    if (isCardShapeHubSpot(shape)) {
      node = {
        type: "objectHubSpot",
        id: shape.id,
        objectType: shape.meta.objectType,
        objectId: shape.meta.objectId,
        parentId: undefined,
      };
    } else {
      node = {
        type: "objectHubGraph",
        id: shape.id,
        objectType: shape.meta.objectType,
        parentId: undefined,
      };
    }

    return <HGObjectCanvasNodes.HGObjectNoteNode node={node} />;
  } else if (isCardShapeHubSpot(shape)) {
    const node: HGCanvasObjectNode = {
      type: "objectHubSpot",
      id: shape.id,
      objectId: shape.meta.objectId,
      objectType: shape.meta.objectType,
      parentId: undefined,
    };

    return <HGObjectCanvasNodes.HGObjectCanvasNode node={node} />;
  } else {
    return <div>unknown card shape</div>;
  }
});

const CardWrapper = React.forwardRef<HTMLDivElement, CardWrapperProps>(
  (props, ref) => {
    const { shape } = props;
    const internalSizingRef = useRef<HTMLDivElement | null>(null);

    const onSizeChange = useCallback(
      (size: [width: number, height: number]) => {
        props.onSizeChange(size);
      },
      [props.onSizeChange],
    );

    useResizeObserver(internalSizingRef, (entry) => {
      const nextSize: [w: number, h: number] = [
        entry.contentRect.width,
        entry.contentRect.height,
      ];
      onSizeChange(nextSize);
      actions.updateCardSizeCache({
        nodeId: shape.id,
        size: nextSize,
      });
    });

    useLayoutEffect(() => {
      const rect = internalSizingRef.current?.getBoundingClientRect();
      const _size: [w: number, h: number] = [
        rect?.width || 0,
        rect?.height || 0,
      ];
      onSizeChange(_size);
      actions.updateCardSizeCache({
        nodeId: shape.id,
        size: _size,
      });
    }, []);

    return (
      <div ref={internalSizingRef}>
        <CardInner shape={props.shape} />
      </div>
    );
  },
);

const Component = <T extends TLDraw.TLShape, E extends Element = any, M = any>(
  component: (
    props: TLDraw.TLComponentProps<T, E, M> & {
      onSizeChange: (size: [width: number, height: number]) => void;
    },
    ref: TLDraw.TLForwardedRef<E>,
  ) => React.ReactElement,
) => {
  return React.forwardRef(component);
};

TDDraw.Card.InjectedComponent = Component((props, ref) => {
  return <CardWrapper {...props} ref={ref} />;
});

const BottomEdgeLayer: React.FC<{}> = observer((props) => {
  return <UIEdges.CanvasConnectionsEdgeLayer />;
});

function connectableShapeIds(params: {
  tlApp: TDDraw.TldrawApp;
  store: HGStore;
  shapeAId?: string;
}): string[] {
  const { tlApp, store, shapeAId } = params;

  if (!shapeAId) {
    return [];
  }

  const onMapCardShapes = tlApp.getShapes().filter(isCardShapeHubSpot);
  const onMapObjectRefs = onMapCardShapes.map((shape) => {
    return {
      objectType: shape.meta.objectType,
      objectId: shape.meta.objectId,
    };
  });

  const shapeA: CardShapeHubSpot | undefined = shapeAId
    ? (tlApp.getShape(shapeAId) as CardShapeHubSpot | undefined)
    : undefined;

  if (!shapeA) {
    return [];
  }

  const hgObjectRefA: HGObjectRef = {
    objectType: shapeA.meta.objectType,
    objectId: shapeA.meta.objectId,
  };

  const validHGObjectCanonicalIds = connection
    .connectableHGObjectRefs({
      objectARef: hgObjectRefA,
      hgLabelPairs: Object.values(store.hgLabelPairs),
      hgObjectRefs: onMapObjectRefs,
    })
    .map(canonicalIdForHGObjectRef);

  const validShapeIds = tlApp
    .getShapes()
    .filter(isCardShapeHubSpot)
    .filter((shape) => {
      const canonicalId = canonicalIdForHGObjectRef({
        objectId: shape.meta.objectId,
        objectType: shape.meta.objectType,
      });
      return validHGObjectCanonicalIds.includes(canonicalId);
    })
    .map((shape) => shape.id);

  return validShapeIds;
}

const Document: React.FC<{}> = observer((props) => {
  const tlApp = useTLApp();
  const store = useStore();
  const portalId = store.portalId;

  const user = store.user;

  assert(user, "should not render <Document /> without user in store");

  // let error, rendererCount;
  let events: any | undefined;
  // NOTE: conditional hook okay here because we will never toggle this at runtime
  // if (store.devMultiplayerEnabled) {
  //   const {
  //     error: msError,
  //     rerenderCounter: msRerenderCounter,
  //     ...msEvents
  //   } = useMultiplayerState(tlApp, portalId, liveblocksRoomId, user);
  //   error = msError;
  //   rendererCount = msRerenderCounter;
  //   events = msEvents;
  // }

  const { ...amEvents } = useAssetManagement(
    tlApp,
    portalId,
    store.initialObject.objectId,
  );
  events = amEvents;

  // console.log(
  //   "render Document",
  //   JSON.parse(JSON.stringify(app.tlApp.document)),
  // );

  // console.log(
  //   "app documentForceUpdate with observer",
  //   store.documentForceUpdate,
  //   store.document,
  // );

  const handleChange = useCallback(
    (state: TDDraw.TldrawApp, reason?: string) => {
      // console.log("handle change", {
      //   document: _.cloneDeep(state.document),
      //   reason,
      // });
      // store.document = state.document;
      runInAction(() => {
        store.documentNotifyUpdate += 1;
      });
    },
    [],
  );

  // if (store.devMultiplayerEnabled && error) {
  //   return <div>Error: {error.message}</div>;
  // }

  // console.log("app id", _.cloneDeep(app.id));
  // console.log("store id", _.cloneDeep(app.id));
  // console.log(
  //   "render document",
  //   _.cloneDeep(store.document.pages.page_1.shapes),
  // );

  // setTimeout(() => {
  //   console.log("re-render Document", app.tlApp.document);
  // }, 1);

  // read the forceUpdate to ensure we re-render on document changes initialized outside of tldraw code
  store.documentForceUpdate;

  const handleKeyboardEvents =
    !store.draftAddingObjectToChart && !store.draftAddingAssociatedToChart;

  return (
    <div className="relative flex-auto">
      {/* <div className="absolute top-[80px] left-8 z-10">
        Force update counter: {app.store.documentForceUpdate}
      </div> */}
      <TDDraw.Tldraw
        // id="test"
        app={tlApp}
        // document={app.tlApp.document}
        components={{
          ShapeToolbar: FloatingToolbar,
          Cursor: UserCursor,
        }}
        darkMode={false}
        showUI={false}
        showMenu={false}
        showMultiplayerMenu={false}
        showPages={false}
        showSponsorLink={false}
        showHelp={false}
        onChange={handleChange}
        renderUnderneathLinks={() => {
          return <BottomEdgeLayer />;
        }}
        onPersist={(app: TDDraw.TldrawApp) => {
          console.log("onPersist");
          const shapes = app.getShapes();
          const formattedShapes = _.reduce(
            shapes,
            (acc, shape) => {
              acc[shape.id] = shape;
              return acc;
            },
            {} as Record<string, TDDraw.TDShape>,
          );
          const bindings = app.getBindings();
          const formattedBindings = _.reduce(
            bindings,
            (acc, binding) => {
              acc[binding.id] = binding;
              return acc;
            },
            {} as Record<string, TDDraw.TDBinding>,
          );
          const assets = app.assets;
          const formattedAssets = _.reduce(
            assets,
            (acc, asset) => {
              acc[asset.id] = asset;
              return acc;
            },
            {} as Record<string, TDDraw.TDAsset>,
          );

          debouncedSyncRelationshipMapDocument({
            portalId: portalId,
            mapId: store.initialObject.objectId,
            document: {
              version: 2.1,
              shapes: formattedShapes,
              bindings: formattedBindings,
              assets: formattedAssets,
            },
            metadata: {
              mapVersion: Date.now(),
            },
          });
        }}
        disableAssets={false}
        handleKeyboardEvents={handleKeyboardEvents}
        {...events}
        onSessionEnd={(
          app: TDDraw.TldrawApp,
          id: string,
          meta?: Record<any, any>,
        ) => {
          console.log("HGApp onSessionEnd", { app, id, meta });
          if (id === "connect") {
            console.log("create/update association", meta);

            if (meta && "fromShapeId" in meta && "toShapeId" in meta) {
              actions.startEditingConnection({
                shapeIdA: meta.fromShapeId,
                shapeIdB: meta.toShapeId,
              });
            }
          }
          if (events && events.onSessionEnd) {
            events.onSessionEnd();
          }
        }}
        onConnectSessionStart={(tlApp: TDDraw.TldrawApp) => {
          const onMapCardShapeIds = tlApp
            .getShapes()
            .filter(isCardShapeHubSpot)
            .map((shape) => shape.id);
          return onMapCardShapeIds;
        }}
        onConnectSessionUpdate={(
          tlApp: TDDraw.TldrawApp,
          bindings: { shapeA: string },
        ) => {
          return connectableShapeIds({
            tlApp,
            store,
            shapeAId: bindings.shapeA,
          });
        }}
      />
    </div>
  );
});

const Canvas: React.FC<{}> = observer((props) => {
  return <Document />;
});

const HGDevToolbar: React.FC<{}> = observer((props) => {
  const store = useStore();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpen = !!anchorEl;

  return (
    <div className="border-2 border-red-500 rounded-md px-2 py-2 relative bg-white">
      <Button
        variant="contained"
        color="secondary"
        style={{ textTransform: "none" }}
        onClick={(e) => {
          setAnchorEl(e.currentTarget);
        }}
      >
        dev menu
      </Button>
      <Menu
        open={menuOpen}
        anchorEl={anchorEl}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem
          onClick={async () => {
            actions.addShape();
            setAnchorEl(null);
          }}
        >
          Add shape
        </MenuItem>

        <MenuItem
          onClick={async () => {
            actions.addNote();
            setAnchorEl(null);
          }}
        >
          Add note
        </MenuItem>

        <MenuItem
          onClick={async () => {
            actions.addLocalNote();
            setAnchorEl(null);
          }}
        >
          Add local note
        </MenuItem>

        <MenuItem
          onClick={async () => {
            // actions.addShape();
            setAnchorEl(null);

            actions.checkForFetches({ force: true });
          }}
        >
          Refresh associations
        </MenuItem>

        <MenuItem
          onClick={async () => {
            setAnchorEl(null);
            actions.devToggleCache();
          }}
        >
          {DEV_CACHE_ENABLED ? "Disable cache" : "Enable cache"}
        </MenuItem>

        <MenuItem
          onClick={async () => {
            actions.devToggleV3V4FetchConnections();
          }}
        >
          {DEV_USE_V3_API_FOR_FETCH_CONNECTIONS
            ? "Use HS V4 API for fetchConnections"
            : "Use V3 API for fetchConnections"}
        </MenuItem>

        <MenuItem
          onClick={async () => {
            setAnchorEl(null);
          }}
        >
          Close relationship map
        </MenuItem>
        <MenuItem
          onClick={async () => {
            actions.togglePrivacyMode();
            setAnchorEl(null);
          }}
        >
          Toggle privacy mode
        </MenuItem>
      </Menu>
    </div>
  );
});

const AppSidebarScreenWrapper = (props: { children?: React.ReactNode }) => {
  return (
    <div className="absolute inset-0 flex flex-col flex-1 min-h-0 bg-white rounded-xl shadow-vlg overflow-hidden pointer-events-auto">
      {props.children}
    </div>
  );
};

const RelationshipMapEditor = observer(() => {
  const store = useStore();

  const [sidebarOpen, setSidebarOpen] = useState<boolean>(false);

  useEffect(() => {
    if (
      store.draftAddingObjectToChart ||
      (store.focusedObject && !sidebarOpen)
    ) {
      setSidebarOpen(true);
    }
  }, [!!store.draftAddingObjectToChart, !!store.focusedObject]);

  const handleCloseAddObjectView = useCallback(() => {
    console.log("handle close");
    if (store.focusedObject) {
      // just close the view for adding, leave the object view
      actions.cancelAddingObjectToChart();
    }
    setSidebarOpen(false);
  }, [!!store.focusedObject]);

  const handleCloseObjectView = useCallback(() => {
    setSidebarOpen(false);
  }, []);

  const handleSidebarClosed = useCallback(() => {
    actions.unfocusObject();
    actions.cancelAddingObjectToChart();
  }, []);

  const loadingMultiplayerState = store.loadingMultiplayer;

  return (
    // <RoomProvider id={roomId}>
    <>
      <div className="flex flex-col items-start w-screen h-screen overflow-hidden relative">
        <div className="flex flex-col flex-auto w-full">
          <Canvas />
        </div>

        <AppSidebar
          side="left"
          fullHeight={true}
          open={sidebarOpen}
          onPanelClosed={handleSidebarClosed}
        >
          <div className="flex-1 flex flex-row h-full relative">
            <div className="flex flex-col flex-1 min-h-0 relative">
              {!!store.focusedObject && (
                <AppSidebarScreenWrapper>
                  <HGObjectSidebarImpl onClosePanel={handleCloseObjectView} />
                </AppSidebarScreenWrapper>
              )}

              {!!store.draftAddingObjectToChart && (
                <AppSidebarScreenWrapper>
                  <AddHGObjectToMapSidebar
                    onClosePanel={handleCloseAddObjectView}
                  />
                </AppSidebarScreenWrapper>
              )}
            </div>

            <div className="relative w-[80px] z-10">
              <Toolbar />
            </div>

            <div className="absolute z-10 whitespace-nowrap left-[calc(100%-60px)]">
              <RelationshipMapSwitcherToolbar />
            </div>
          </div>
        </AppSidebar>

        <AppSidebar
          side="right"
          fullHeight={false}
          open={store.buyingGroupAnalysisVisible}
          onPanelClosed={() => {
            actions.hideBuyingGroupAnalysis();
          }}
        >
          <div className="flex flex-row max-h-[calc(100vh-6rem)]">
            <div className="relative w-[80px] z-10">
              <RightToolbar />
            </div>

            <BuyingGroupAnalysisSidebar />
          </div>
        </AppSidebar>

        <div className="absolute bottom-4 right-4 z-10 flex flex-row items-center">
          <div className="mr-4 space-x-2">
            <Button
              variant="contained"
              onClick={() =>
                actions.devResubscribeToHGObject({
                  hgObjectRef: {
                    objectType: "contact",
                    objectId: "6901",
                  },
                })
              }
            >
              devResubscribeToHGObject
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                actions.devPerformLayout();
              }}
            >
              Layout
            </Button>
          </div>
          <ZoomToolbar />
        </div>

        {store.devMenuVisible && (
          <div className="absolute top-4 right-4 z-10">
            <HGDevToolbar />
          </div>
        )}

        <HGConnectionEditor />

        <AddAssociatedToChartModal />

        {loadingMultiplayerState && (
          <div className="absolute inset-0 bg-white opacity-80 z-10 flex items-center justify-center">
            <CircularProgress color="secondary" />
          </div>
        )}
      </div>

      <AppSidebar
        side="left"
        fullHeight={true}
        open={sidebarOpen}
        onPanelClosed={handleSidebarClosed}
      >
        <div className="flex-1 flex flex-row h-full relative">
          <div className="flex flex-col flex-1 min-h-0 relative">
            {!!store.focusedObject && (
              <AppSidebarScreenWrapper>
                <HGObjectSidebarImpl onClosePanel={handleCloseObjectView} />
              </AppSidebarScreenWrapper>
            )}

            {!!store.draftAddingObjectToChart && (
              <AppSidebarScreenWrapper>
                <AddHGObjectToMapSidebar
                  onClosePanel={handleCloseAddObjectView}
                />
              </AppSidebarScreenWrapper>
            )}
          </div>

          <div className="relative w-[80px] z-10">
            <Toolbar />
          </div>

          <div className="absolute z-10 whitespace-nowrap left-[calc(100%-60px)]">
            <RelationshipMapSwitcherToolbar />
          </div>
        </div>
      </AppSidebar>

      <AppSidebar
        side="right"
        fullHeight={false}
        open={store.buyingGroupAnalysisVisible}
        onPanelClosed={() => {
          actions.hideBuyingGroupAnalysis();
        }}
      >
        <div className="flex flex-row max-h-[calc(100vh-6rem)]">
          <div className="relative w-[80px] z-10">
            <RightToolbar />
          </div>

          <BuyingGroupAnalysisSidebar />
        </div>
      </AppSidebar>

      <div className="absolute bottom-4 right-4 z-10">
        <ZoomToolbar />
      </div>

      {store.devMenuVisible && (
        <div className="absolute top-4 right-4 z-10">
          <HGDevToolbar />
        </div>
      )}

      <HGConnectionEditor />

      <AddAssociatedToChartModal />

      {hgApp.store.auth.impersonation && (
        <div className="absolute bottom-2 left-2 bg-red-500 px-4 py-2 font-medium text-red-50 z-10">
          impersonation session
        </div>
      )}

      {loadingMultiplayerState && (
        <div className="absolute inset-0 bg-white opacity-80 z-10 flex items-center justify-center">
          <CircularProgress color="secondary" />
        </div>
      )}
    </>
    // </RoomProvider>
  );
});

const HGApp: React.FC<{}> = observer(() => {
  const store = useStore();

  useKeyboardShortcuts();

  useStylesheet();

  console.log("hs.store.initialized", store.initialized);
  console.log("store.initializedEnoughForMap", store.initializedEnoughForMap);

  if (!store.initialized || !store.initializedEnoughForMap) {
    return (
      <div className="h-screen w-screen flex items-center justify-center bg-slate-100">
        <CircularProgress color="secondary" />
      </div>
    );
  }

  return <>{<RelationshipMapEditor />}</>;
});

const Head = observer(() => {
  const store = useStore();
  const dealObjectRef: HGObjectRef = store.initialObject;
  const dealObject = db.getHGObject(store, dealObjectRef);

  const dealDisplayName = dealObject ? objectDisplayName(dealObject) : null;

  useEffect(() => {
    if (dealDisplayName) {
      document.title = `${dealDisplayName} - Deal Map - OrgChartHub`;
    } else {
      document.title = "Deal Map - OrgChartHub";
    }
  }, [dealDisplayName]);

  return null;
});

const HGAppWrapper = observer(() => {
  const store = useStore();

  useEffect(() => {
    const fn = async () => {
      console.log("initializing app...");
      await actions.initialize();
    };
    if (!store.initialized) {
      fn().catch(console.error);
    }
  }, [store.initialized]);

  return (
    <>
      <Head />
      <HGApp />
    </>
  );
});

const queryClient = new QueryClient();
const AppWrapper = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <HGAppContext.Provider value={hgApp}>
        <HGAppWrapper />
      </HGAppContext.Provider>
    </QueryClientProvider>
  );
};

export default AppWrapper;
