import {
  canonicalIdForConnection,
  HGConnection,
  HGLabelPair,
} from "../domain/connection";
import { HGStore, SubscriptionDefHGObject } from "./types";
import {
  HGObjectSchema,
  HGObject,
  HGObjectRef,
  canonicalIdForHGObjectRef,
  HSOwner,
  HSPipeline,
  HGAccountDetails,
  canonicalIdForHSProperty,
  HSPipelineStage,
} from "../domain";
import { HSPropertyGroup, HSProperty } from "../domain/property";
import { ValueResolvingSubscription } from "../domain/resolved-values";
import { DateTime } from "luxon";
import { assert, assertNever } from "../utils";

function upsertMany<T extends { canonicalId: string }>(params: {
  entities: T[];
  state: { [id: string]: T };
}): void {
  const { state, entities } = params;
  for (const entity of entities) {
    const id = entity.canonicalId;
    state[id] = entity;
  }
  return;
}

export function upsertHGConnections(
  store: HGStore,
  connections: HGConnection[],
): void {
  upsertMany({ entities: connections, state: store.hgConnections });
}

export function upsertHGObjects(store: HGStore, objects: HGObject[]): void {
  upsertMany({ entities: objects, state: store.hgObjects });
}

export function upsertHGLabelPairs(
  store: HGStore,
  labelPairs: HGLabelPair[],
): void {
  upsertMany({ entities: labelPairs, state: store.hgLabelPairs });
}

export function upsertHGObjectSchemas(
  store: HGStore,
  objectSchemas: HGObjectSchema[],
): void {
  upsertMany({ entities: objectSchemas, state: store.hgObjectSchemas });
}

export function upsertHSPropertyGroups(
  store: HGStore,
  propertyGroups: HSPropertyGroup[],
): void {
  upsertMany({ entities: propertyGroups, state: store.hgPropertyGroups });
}

export function upsertHSProperties(
  store: HGStore,
  properties: HSProperty[],
): void {
  upsertMany({ entities: properties, state: store.hgProperties });
}

export function subscribeToHGObjects(
  store: HGStore,
  params: {
    hgObjectRefs: HGObjectRef[];
    force?: boolean;
  },
): void {
  const { hgObjectRefs, force } = params;

  let subDefs: SubscriptionDefHGObject[] = [];

  for (const hgObjectRef of hgObjectRefs) {
    const canonicalId = canonicalIdForHGObjectRef(hgObjectRef);
    const existingSubDef = store.hgObjectRefSubscriptions[canonicalId] as
      | SubscriptionDefHGObject
      | undefined;
    const nextSubDef: SubscriptionDefHGObject = {
      canonicalId,
      hgObjectRef: {
        objectId: hgObjectRef.objectId,
        objectType: hgObjectRef.objectType,
      },
      state: force || !existingSubDef ? "awaiting-sub" : existingSubDef.state,
      createdAt: existingSubDef?.createdAt ?? DateTime.utc().toISO(),
    };
    subDefs.push(nextSubDef);
  }

  for (const subDef of subDefs) {
    store.hgObjectRefSubscriptions[subDef.canonicalId] = subDef;
  }

  return;
}

export function subscribeToResolveValueType(
  store: HGStore,
  params: {
    valueResolvingSubscription: ValueResolvingSubscription;
    force?: boolean;
  },
): void {
  const { valueResolvingSubscription, force } = params;
  const existing =
    store.valueResolvingSubscriptions[valueResolvingSubscription.id];
  if (!existing || force) {
    store.valueResolvingSubscriptions[valueResolvingSubscription.id] =
      valueResolvingSubscription;
  }

  return;
}

export function updateOwnersValueResolvingSubscription(
  store: HGStore,
  params: {
    owners: HSOwner[];
  },
): void {
  const existing = store.valueResolvingSubscriptions.AllOwners;
  if (!existing) {
    return;
  }
  existing.owners = params.owners;
}

export function updateDealPipelinesValueResolvingSubscription(
  store: HGStore,
  params: {
    dealPipelines: HSPipeline[];
  },
): void {
  const existing = store.valueResolvingSubscriptions.AllDealPipelines;
  if (!existing) {
    return;
  }
  existing.dealPipelines = params.dealPipelines;
}

export function getAccountDetails(store: HGStore): HGAccountDetails {
  const hgAccountDetails = store.hgAccountDetails;
  assert(
    hgAccountDetails,
    "hgAccountDetails should have been set before call to `hgAccountDetailsAsserted`, can only be used after app init",
  );
  return hgAccountDetails;
}

export function knownHGConnectionForObjectRefs(params: {
  store: HGStore;
  objectRefA: HGObjectRef;
  objectRefB: HGObjectRef;
}): HGConnection {
  const { store, objectRefA, objectRefB } = params;

  const lookingForConnectionCanonicalId = canonicalIdForConnection({
    objectRefA,
    objectRefB,
  });

  const connection = store.hgConnections[lookingForConnectionCanonicalId];

  return connection;
}

export function getHSProperty(
  store: HGStore,
  params: {
    objectType: string;
    name: string;
  },
): HSProperty | undefined {
  const { objectType, name } = params;
  const canonicalId = canonicalIdForHSProperty({ objectType, name });
  const property = store.hgProperties[canonicalId];
  return property;
}

export function getHSPipeline(
  store: HGStore,
  params: {
    pipelineId: string;
  },
): HSPipeline | undefined {
  const { pipelineId } = params;
  const pipeline =
    store.valueResolvingSubscriptions.AllDealPipelines?.dealPipelines.find(
      (pipeline) => pipeline.id === pipelineId,
    );
  return pipeline;
}

export function getHSDealStage(
  store: HGStore,
  params: {
    pipelineId: string;
    dealstageId: string;
  },
): HSPipelineStage | undefined {
  const { pipelineId, dealstageId } = params;
  const pipeline = getHSPipeline(store, { pipelineId });
  if (!pipeline) {
    return undefined;
  }
  const stage = pipeline.stages.find((stage) => stage.id === dealstageId);
  return stage;
}

export function getHGObject(
  store: HGStore,
  objectRef: HGObjectRef,
): HGObject | undefined {
  const canonicalId = canonicalIdForHGObjectRef(objectRef);
  const object = store.hgObjects[canonicalId];
  return object;
}
