import {
  Close as CloseIcon,
  MoreVert as MoreVertIcon,
} from "@mui/icons-material";
import { CircularProgressProps, IconButton } from "@mui/material";
import _ from "lodash";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { canonicalObjectIdsOnMap } from "../../computeds";
import {
  canonicalIdForHSProperty,
  HGObject,
  HGObjectRef,
  objectDisplayName,
} from "../../domain";
import { useStore } from "../../hooks/hooks";
import { HSProperty } from "../../domain/property";
import { Configurer } from "./Configurer";
import {
  BuyingGroupAnalysisCondition,
  BuyingGroupAnalysisConfig,
  BuyingGroupSizeCondition,
  BuyingRolePresenceCondition,
  BUYING_GROUP_ANALYSIS_CONFIG,
  Health,
} from "./helpers";
import { HGObjectCardPreviewContact } from "../CardComponents/NonCanvas";
import * as icons from "@mui/icons-material";
import { assertNever } from "../../utils";
import { knownHGConnectionForObjectRefs } from "../../store/db";

const Header = (props: { onStartEditing: () => void }) => {
  const { onStartEditing } = props;
  return (
    <div className="flex flex-row items-center justify-between border-b border-slate-300 bg-slate-100 px-2">
      <span className="text-base font-semibold text-slate-800 py-4 pl-2 leading-none">
        Buying Group
      </span>
      <div className="text-slate-800">
        <IconButton size="small" color="inherit" onClick={onStartEditing}>
          <MoreVertIcon />
        </IconButton>
      </div>
    </div>
  );
};

const TextScoreIndicator = (props: {
  health: Health;
  identifiedCount: number;
  outOfCount: number;
}) => {
  const { health, identifiedCount, outOfCount } = props;
  let textColor: string, bgColor: string;
  if (health === "bad") {
    textColor = "text-red-800";
    bgColor = "bg-red-200";
  } else if (health === "okay") {
    textColor = "text-orange-800";
    bgColor = "bg-orange-200";
  } else if (health === "good") {
    textColor = "text-green-800";
    bgColor = "bg-green-200";
  } else {
    assertNever(health);
  }

  return (
    <div
      className={`${textColor} ${bgColor} rounded-full px-2.5 py-1 flex items-center justify-center leading-none font-semibold text-xs`}
    >
      <span className="relative">
        {identifiedCount}/{outOfCount}
      </span>
    </div>
  );
};

const BuyingGroupHGObjectCardPlaceholder = (props: { label: string }) => {
  const { label } = props;
  return (
    <div className="select-none h-[76px] w-full relative group cursor-pointer ">
      <div className="transition-all absolute inset-0 flex flex-row items-center justify-center rounded-xl border-2 border-dashed border-slate-400 bg-slate-50 text-sm font-medium text-slate-500 shadow-inner group-hover:bg-blue-50 group-active:bg-blue-100">
        <span className="flex-1 flex flex-row items-center justify-center mx-11">
          {label}
        </span>
      </div>

      <div className="absolute inset-0 flex flex-row items-center justify-end pr-3">
        <div className="transition-all opacity-0 group-hover:opacity-100">
          <IconButton
            // style={{ width: 48, height: 48 }}
            size="small"
            className="user-select-auto"
            onClick={(e) => {
              e;
            }}
          >
            <CloseIcon />
          </IconButton>
        </div>
      </div>
    </div>
  );
};

const BuyingGroupConditionBuyingRolePresence = observer(
  (props: { condition: BuyingRolePresenceCondition }) => {
    const { condition } = props;

    const store = useStore();
    const canonicalIdsOnMap = Object.keys(canonicalObjectIdsOnMap.get());
    const dealMapDealObjectRef: HGObjectRef = {
      objectId: store.initialObject.objectId,
      objectType: "deal",
    };

    const buyingRolePropertyCanonicalId = canonicalIdForHSProperty({
      objectType: "contact",
      name: "hs_buying_role",
    });
    const buyingRoleProperty = store.hgProperties[
      buyingRolePropertyCanonicalId
    ] as HSProperty | undefined;

    function buyingRoleValueToLabel(value: string): string {
      if (!buyingRoleProperty || buyingRoleProperty.type !== "enumeration") {
        return value;
      }
      const option = buyingRoleProperty.options.find((option) => {
        return option.value === value;
      });
      return option ? option.label : value;
    }

    const contactHGObjects = _.chain(canonicalIdsOnMap)
      .map(
        (canonicalId) => store.hgObjects[canonicalId] as HGObject | undefined,
      )
      .compact()
      .filter((hgObject) => hgObject.objectType === "contact")
      .filter((hgObject) => {
        return !!knownHGConnectionForObjectRefs({
          objectRefA: hgObject,
          objectRefB: dealMapDealObjectRef,
          store,
        });
      })
      .filter((hgObject) => {
        const buyingRoleValue = hgObject.properties?.["hs_buying_role"];
        const buyingRoleValues = (buyingRoleValue ?? "").split(";");
        return buyingRoleValues.includes(condition.buyingRole);
      })
      .sortBy(objectDisplayName)
      .value();

    const identifiedBuyingRoleCount = contactHGObjects.length;
    const progressValue = Math.min(
      100,
      (identifiedBuyingRoleCount / condition.count) * 100,
    );

    let state: Health;
    if (progressValue < 33) {
      state = "bad";
    } else if (progressValue < 66) {
      state = "okay";
    } else {
      state = "good";
    }
    const buyingRoleLabel = buyingRoleValueToLabel(condition.buyingRole);

    return (
      <div className="flex flex-col bg-white pb-4">
        <div className="flex flex-row px-5 py-3 items-center justify-between">
          <span className="text-sm font-medium text-slate-800 leading-none">
            {buyingRoleLabel}s
          </span>
          <div>
            <TextScoreIndicator
              health={state}
              identifiedCount={identifiedBuyingRoleCount}
              outOfCount={condition.count}
            />
          </div>
        </div>

        <div className="space-y-3 px-4">
          {_.range(0, Math.max(condition.count, contactHGObjects.length)).map(
            (i) => {
              const hgObject = contactHGObjects[i];

              if (hgObject) {
                return (
                  <HGObjectCardPreviewContact
                    key={i}
                    hgObject={hgObject}
                    onOpenInHubSpot={() => undefined}
                    onFocusOnMap={() => undefined}
                  />
                );
              } else {
                return (
                  <BuyingGroupHGObjectCardPlaceholder
                    key={i}
                    label={buyingRoleLabel}
                  />
                );
              }
            },
          )}
        </div>
      </div>
    );
  },
);

const BuyingGroupConditionBuyingGroupSize = observer(
  (props: { condition: BuyingGroupSizeCondition }) => {
    const { condition } = props;

    const store = useStore();
    const canonicalIdsOnMap = Object.keys(canonicalObjectIdsOnMap.get());
    const dealMapDealObjectRef: HGObjectRef = {
      objectId: store.initialObject.objectId,
      objectType: "deal",
    };

    // TODO: not all contacts on chart are part of the buying group
    const contactHGObjects = _.chain(canonicalIdsOnMap)
      .map(
        (canonicalId) => store.hgObjects[canonicalId] as HGObject | undefined,
      )
      .compact()
      .filter((hgObject) => hgObject.objectType === "contact")
      .filter((hgObject) => {
        return !!knownHGConnectionForObjectRefs({
          objectRefA: hgObject,
          objectRefB: dealMapDealObjectRef,
          store,
        });
      })
      .sortBy(objectDisplayName)
      .value();

    const identifiedBuyingGroupSize = contactHGObjects.length;
    const progressValue = Math.min(
      100,
      (identifiedBuyingGroupSize / condition.size) * 100,
    );

    let state: Health;
    if (progressValue > 66) {
      state = "good";
    } else if (progressValue > 33) {
      state = "okay";
    } else {
      state = "bad";
    }

    let color: CircularProgressProps["color"];
    if (state === "bad") {
      color = "error";
    } else if (state === "okay") {
      color = "warning";
    } else if (state === "good") {
      color = "success";
    }

    let iconTextColor: string, iconBgColor: string;
    if (state === "bad" && false) {
      iconTextColor = "text-red-800";
      iconBgColor = "bg-red-200";
    } else if (state === "okay" && false) {
      iconTextColor = "text-orange-800";
      iconBgColor = "bg-orange-200";
    } else if (state === "good" || true) {
      iconTextColor = "text-green-800";
      iconBgColor = "bg-green-200";
    }

    return (
      <div className="flex flex-col">
        <div className="flex flex-row px-5 py-3 items-center justify-between">
          <span className="text-sm font-medium text-slate-800 leading-none">
            Buying Group Size
          </span>
          <div>
            <TextScoreIndicator
              health={state}
              identifiedCount={identifiedBuyingGroupSize}
              outOfCount={condition.size}
            />
          </div>
          {/* <div className="flex flex-row items-center space-x-2">
            <span className="text-sm font-medium text-slate-800">
              {identifiedBuyingGroupSize}/{condition.size}
            </span>
            <ScoreIndicator health={state} />
          </div> */}
        </div>

        <div className="px-4 pt-1 pb-4">
          <div className="grid gap-2.5 auto-rows-fr grid-cols-5 overflow-hidden">
            {_.range(0, condition.size).map((i) => {
              const isIdentified = !!(i < identifiedBuyingGroupSize);
              return (
                <div
                  key={i}
                  className={`${
                    isIdentified
                      ? `${iconBgColor} ${iconTextColor} shadow`
                      : "border-dashed border-2 border-slate-300 bg-slate-50 text-slate-400 shadow-inner"
                  } h-[32px] flex items-center justify-center text-[22px] rounded-lg`}
                >
                  <icons.Person color="inherit" fontSize="inherit" />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  },
);

const BuyingGroupCondition = (props: {
  condition: BuyingGroupAnalysisCondition;
}) => {
  const { condition } = props;
  const type = condition.type;

  switch (type) {
    case "buying-group-size": {
      return <BuyingGroupConditionBuyingGroupSize condition={condition} />;
    }
    case "buying-role-presence": {
      return <BuyingGroupConditionBuyingRolePresence condition={condition} />;
    }
    default: {
      return (
        <div className="p-4">unsupported buying group condition: {type}</div>
      );
    }
  }
};

const BuyingGroupConditions = (props: {
  config: BuyingGroupAnalysisConfig;
}) => {
  const { config } = props;
  return (
    <div className="flex grow flex-col bg-white">
      <div className="grow divide-y divide-slate-300">
        {config.conditions.map((condition) => {
          return (
            <BuyingGroupCondition key={condition.id} condition={condition} />
          );
        })}
      </div>
    </div>
  );
};

const BuyingGroupAnalysisSidebarWrapper = (props: {
  children?: React.ReactNode;
}) => {
  return (
    <div className="pointer-events-auto flex min-h-0 flex-1 flex-col overflow-hidden rounded-2xl bg-white shadow-vlg border border-slate-300 select-none">
      {props.children}
    </div>
  );
};

export const BuyingGroupAnalysisSidebar = () => {
  const [step, setStep] = useState<"viewing" | "configuring">("viewing");

  const [config, setConfig] = useState<BuyingGroupAnalysisConfig>(
    BUYING_GROUP_ANALYSIS_CONFIG,
  );

  return (
    <BuyingGroupAnalysisSidebarWrapper>
      <Header
        onStartEditing={() => {
          setStep("configuring");
        }}
      />
      {step === "viewing" ? (
        <BuyingGroupConditions config={config} />
      ) : (
        <Configurer
          initialConfig={config}
          onCancel={() => {
            setStep("viewing");
          }}
          onDone={(newConfig: BuyingGroupAnalysisConfig) => {
            setConfig(newConfig);
            setStep("viewing");
          }}
        />
      )}
    </BuyingGroupAnalysisSidebarWrapper>
  );
};
