import React, { useEffect, useState } from "react";
import { motion, AnimatePresence, Variants } from "framer-motion";

const makeAnimationVariants = ({
  side,
  fullHeight,
}: {
  side: "left" | "right";
  fullHeight: boolean;
}): Variants => {
  return {
    hidden: {
      position: "absolute",
      top: "1rem",
      ...(fullHeight ? { bottom: "1rem" } : {}),
      x: side === "left" ? "-21rem" : "21rem",
      width: "26rem",
      ...(side === "left" ? { left: 0 } : { right: 0 }),
    },
    visible: {
      position: "absolute",
      top: "1rem",
      ...(fullHeight ? { bottom: "1rem" } : {}),
      x: side === "left" ? "1.5rem" : "-1rem",
      width: "26rem",
      ...(side === "left" ? { left: 0 } : { right: 0 }),
    },
  };
};

export const AppSidebar = (props: {
  children: React.ReactNode;
  open: boolean;
  side: "left" | "right";
  fullHeight: boolean;
  onPanelClosed: () => void;
}) => {
  const { open, onPanelClosed, children } = props;

  const animationVariants = React.useMemo(() => {
    return makeAnimationVariants({
      side: props.side,
      fullHeight: props.fullHeight,
    });
  }, [props.side]);

  return (
    <motion.div
      initial={false}
      onAnimationComplete={(definition) => {
        console.log("onAnimationComplete", definition);
        if (definition === "hidden") {
          onPanelClosed();
        }
      }}
      id={`open-${open}`}
      className="z-10 pointer-events-none"
      transition={{
        type: "spring",
        bounce: 0.1,
        duration: 0.4,
      }}
      variants={animationVariants}
      // animate={panelOpen ? "animate" : "initial"}
      animate={open ? "visible" : "hidden"}
    >
      {children}
    </motion.div>
  );
};
