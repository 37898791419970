import { HGObjectSchema } from ".";

export function objectTypesListsEqual(
  objectTypesListA: string[],
  objectTypesListB: string[],
): boolean {
  return (
    objectTypesListA.sort().join(":") === objectTypesListB.sort().join(":")
  );
}

export function objectTypeForFullyQualifiedObjectName(params: {
  fullyQualifiedObjectName: string;
  hgSchemas: HGObjectSchema[];
}): string | undefined {
  const { fullyQualifiedObjectName, hgSchemas } = params;
  if (fullyQualifiedObjectName === "deals") {
    return "deal";
  } else if (fullyQualifiedObjectName === "contacts") {
    return "contact";
  } else if (fullyQualifiedObjectName === "companies") {
    return "company";
  } else {
    const schema = hgSchemas.find((schema) => {
      return schema.fullyQualifiedName === fullyQualifiedObjectName;
    });
    return schema?.objectTypeId;
  }
}
