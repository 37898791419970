import { HSProperty, HSPropertyEnumeration } from "../../domain/property";
import { HGStore } from "../../store/types";
import {
  hsPropertyRequiresResolvingValues,
  hsPropertyValueResolvingType,
} from "../../domain/resolved-values";
import {
  canonicalIdForHGObjectRef,
  HGAccountDetails,
  HGObject,
  HGObjectRef,
  objectDisplayName,
} from "../../domain";
import _ from "lodash";
import { DateTime } from "luxon";
import currency from "currency.js";
import getSymbolFromCurrency from "currency-symbol-map";

export function formatValueForDisplay(params: {
  hsProperty: HSProperty;
  hgObject: HGObject;
  value: string;
  hgAccountDetails: HGAccountDetails;
}): string {
  const { hsProperty, hgObject, value, hgAccountDetails } = params;

  if (hsProperty.type === "date" || hsProperty.type === "datetime") {
    const dt = DateTime.fromISO(value);
    return dt.toLocaleString(
      hsProperty.type === "date" || hsProperty.fieldType === "date"
        ? DateTime.DATE_SHORT
        : DateTime.DATETIME_SHORT,
    );
  } else if (hsProperty.type === "number" && hsProperty.showCurrencySymbol) {
    // special case for displaying built-in HubSpot deal property in the deal's
    // specific currency
    let currencyCode = hgAccountDetails.companyCurrency;
    if (hsProperty.objectType === "deal" && hsProperty.name === "amount") {
      const dealCurrencyCode = hgObject.properties?.["deal_currency_code"];
      if (dealCurrencyCode && !_.isEmpty(dealCurrencyCode)) {
        currencyCode = dealCurrencyCode;
      }
    }
    const symbol = getSymbolFromCurrency(currencyCode);
    return currency(value).format({ separator: ",", symbol: symbol });
  }

  return value;
}

export function optionValueToLabel(params: {
  hsProperty: HSPropertyEnumeration;
  value: string;
}): string | null {
  const { hsProperty, value } = params;

  const option = _.find(hsProperty.options, (option) => {
    return option.value === value;
  });

  return option ? option.label : null;
}

export function resolvePropertyValue(props: {
  store: HGStore;
  hsProperty: HSProperty;
  hgObject: HGObject;
  value: string;
}): string {
  const { store, hsProperty, hgObject, value } = props;

  if (!hsPropertyRequiresResolvingValues(hsProperty)) {
    return value;
  }

  const resolvingType = hsPropertyValueResolvingType(hsProperty);

  if (resolvingType === "AllOwners") {
    const ownerId = value;
    const owner = store.valueResolvingSubscriptions.AllOwners?.owners.find(
      (owner) => {
        return owner.id === ownerId;
      },
    );
    if (!owner) {
      return value;
    } else {
      if (owner.firstName || owner.lastName) {
        return _.chain([owner.firstName, owner.lastName])
          .compact()
          .join(" ")
          .value();
      } else if (owner.email) {
        return owner.email;
      } else {
        return owner.id;
      }
    }
  } else if (resolvingType === "AllDealPipelines") {
    if (hsProperty.objectType === "deal" && hsProperty.name === "pipeline") {
      const pipelineId = value;
      const pipeline =
        store.valueResolvingSubscriptions.AllDealPipelines?.dealPipelines.find(
          (pipeline) => {
            return pipeline.id === pipelineId;
          },
        );
      if (!pipeline) {
        return value;
      }
      return pipeline.label;
    } else if (
      hsProperty.objectType === "deal" &&
      hsProperty.name === "dealstage"
    ) {
      // TODO: are deal stage ids always globally unique or only unique within a pipeline?
      const dealstageId = value;
      const dealstage = _.chain(
        store.valueResolvingSubscriptions.AllDealPipelines?.dealPipelines,
      )
        .flatMap((pipeline) => {
          return pipeline.stages;
        })
        .find((stage) => {
          return stage.id === dealstageId;
        })
        .value();
      if (!dealstage) {
        return value;
      }
      return dealstage.label;
    }
  } else if (resolvingType === "HGObject") {
    const referencedObjectType = hsProperty.referencedObjectType
      ? hsProperty.referencedObjectType.toLowerCase()
      : undefined;
    if (!referencedObjectType) {
      return value;
    }
    const hgObjectRef: HGObjectRef = {
      objectType: referencedObjectType,
      objectId: value,
    };
    const canonicalId = canonicalIdForHGObjectRef(hgObjectRef);
    const hgObject = store.hgObjects[canonicalId];
    if (!hgObject) {
      return value;
    }
    return objectDisplayName(hgObject);
  }

  return value;
}
