import { TLUser } from "@orgcharthub/tldraw-core";
import fontColorContrast from "font-color-contrast";
import * as React from "react";

type UserProps = Pick<
  TLUser<{ displayName?: string; email?: string }>,
  "id" | "color" | "metadata"
>;

export const UserCursor = (user: UserProps) => {
  const foregroundColor = fontColorContrast(user.color);

  return (
    <div
      data-display-name={user.metadata?.displayName}
      style={{
        // @ts-ignore
        "--hg-display-bg-color": user.color,
        "--hg-display-fg-color": foregroundColor,
      }}
    >
      <svg viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.7896 29.1698V8.74219L27.0001 23.5626H18.3678L18.1827 23.7207L12.7896 29.1698Z"
          fill="black"
          fillOpacity="0.2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 27.6897V7L27 22.0105H17.8882L17.6928 22.1707L12 27.6897Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.5791 10.3223V24.4003L17.311 20.794L17.5122 20.6191L23.8423 20.6294L13.5791 10.3223Z"
          fill={user.color}
        />
      </svg>
    </div>
  );
};
