import * as ts from "io-ts";
import { parseOrThrow } from "../utils";
import firebase from "firebase/app";
import { API_BASE_URL } from "../config";

const AUTH_API_BASE_URL = import.meta.env.PROD ? "" : "http://orgcharthub.test";

export async function login(
  authCode: string,
): Promise<{ accessToken: string; refreshToken: string }> {
  // document.location.hash = "";

  const res = await fetch(`${AUTH_API_BASE_URL}/auth/login`, {
    method: "post",
    body: JSON.stringify({
      authCode,
      mode: "iframe",
    }),
    headers: {
      accept: "application/json",
      "content-type": "application/json",
    },
  });

  const json = await res.json();

  const ExpectedBody = ts.type({
    accessToken: ts.string,
    refreshToken: ts.string,
  });

  const decoded = parseOrThrow(ExpectedBody, json);

  const { accessToken, refreshToken } = decoded;

  return {
    accessToken,
    refreshToken,
  };
}

export async function refreshSession(params: {
  portalId: string;
  refreshToken?: string;
}): Promise<{ accessToken: string; refreshToken?: string }> {
  const { portalId, refreshToken } = params;

  const res = await fetch(`${AUTH_API_BASE_URL}/auth/refresh`, {
    method: "post",
    body: JSON.stringify({
      portalId: parseInt(portalId, 10),
      ...(refreshToken ? { refreshToken } : {}),
    }),
    headers: {
      accept: "application/json",
      "content-type": "application/json",
    },
  });

  const ExpectedBody = ts.intersection([
    ts.type({
      accessToken: ts.string,
    }),
    ts.partial({
      refreshToken: ts.string,
    }),
  ]);

  const json = await res.json();

  const decoded = parseOrThrow(ExpectedBody, json);

  return decoded;
}

async function createFirebaseSession(token: string): Promise<string> {
  const res = await fetch(`${API_BASE_URL}/api/v2/firebase/token`, {
    method: "post",
    headers: {
      accept: "application/json",
      "content-type": "application/json",
      "x-och-app": "och",
      authorization: `Bearer ${token}`,
    },
  });

  const json = await res.json();

  const ExpectedBody = ts.type({
    token: ts.string,
  });

  const decoded = parseOrThrow(ExpectedBody, json);

  return decoded.token;
}

export async function firebaseSignIn(params: { token: string }): Promise<void> {
  const { token } = params;

  const firebaseToken = await createFirebaseSession(token);

  await firebase.auth().signInWithCustomToken(firebaseToken);
}

export function firebaseSignOut(): void {
  firebase.auth().setPersistence(firebase.auth.Auth.Persistence.NONE);
  return;
}

export function onAuthStateChanged(
  cb: (user: firebase.User | null) => void,
): void {
  firebase.auth().onAuthStateChanged(cb);
}
