import React, {
  DependencyList,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
  useMemo,
} from "react";
import * as domain from "../domain";
import _ from "lodash";
import * as d3 from "d3";
import { assert, assertNever, isNotUndefined } from "../utils";
import { useStore } from "../hooks/hooks";
import { observer } from "mobx-react-lite";
import * as actions from "../actions";
import { CardShape, TDDocument, TDShape } from "@orgcharthub/tldraw-tldraw";
import * as connection from "../domain/connection";
import { colors } from "../theme";
import {
  labelPointsForConnectionPath,
  shapeByObjectRef,
} from "../domain/canvas";
import { useTLApp } from "../hooks/hooks";

type Vector = [x: number, y: number];
type Size = [width: number, height: number];
type Rect = { x: number; y: number; width: number; height: number };

const useD3 = (
  renderFn: (
    selection: d3.Selection<SVGSVGElement, null, null, undefined>,
  ) => void,
  dependencies: DependencyList,
) => {
  const ref = useRef<SVGSVGElement>(null);

  useEffect(() => {
    return renderFn(d3.select(ref.current as SVGSVGElement));
  }, dependencies);

  return ref;
};

const d3CableRenderer = d3
  .line<{ x: number; y: number }>()
  .x((d) => d.x)
  .y((d) => d.y)
  .curve(d3.curveBasis);

type CableLabelDef = {
  type: "sideA" | "sideB" | "middle";
  label: string;
};

const InteractiveCable: React.FC<{
  aPoint: Vector;
  bPoint: Vector;
  aSize?: Size;
  bSize?: Size;
  labelDefs?: CableLabelDef[];
  onClick: () => void;
}> = (props) => {
  const { aPoint, bPoint, labelDefs, onClick, aSize, bSize } = props;

  const middleLabels = (labelDefs || []).filter(
    (labelDef) => labelDef.type === "middle",
  );
  const sideALabels = (labelDefs || []).filter(
    (labelDef) => labelDef.type === "sideA",
  );
  const sideBLabels = (labelDefs || []).filter(
    (labelDef) => labelDef.type === "sideB",
  );

  const numberOfCableSegments = 5;
  const stepX = (bPoint[0] - aPoint[0]) / numberOfCableSegments;
  const stepY = (bPoint[1] - aPoint[1]) / numberOfCableSegments;

  type CableNode = {
    fx?: number;
    fy?: number;
    x?: number;
    y?: number;
  };

  const nodes: CableNode[] = _.map(
    _.range(0, numberOfCableSegments + 1),
    (n) => {
      return { x: aPoint[0] + stepX * n, y: aPoint[1] + stepY * n };
    },
  );

  // force to a point 100px away from the midpoint between the start and end y position
  const forceY = _.chain([aPoint, bPoint])
    .map((point) => point[1])
    .sum()
    .divide(2)
    .add(1000)
    .value();

  const cableRef = useRef<d3.Selection<
    SVGGElement,
    null,
    null,
    undefined
  > | null>(null);

  const [cablePath, setCablePath] = useState<string | null>(null);

  const ref = useD3((selection) => {
    const cable = selection.append("g");

    const innerPath = cable
      .append("path")
      .attr("stroke-width", 5)
      .attr("stroke", colors.slate[500])
      .attr("stroke-linecap", "round")
      .attr("fill", "none")
      .attr("class", "drop-shadow-md");

    const outerPath = cable
      .append("path")
      .attr("stroke-width", 60)
      .attr("stroke", "transparent")
      .attr("fill", "none")
      .attr("class", "cursor-pointer pointer-events-auto")
      .on(
        "pointerdown",
        (e) => {
          e.stopPropagation();
          innerPath.transition().duration(100).attr("stroke-width", 16);
        },
        { capture: true },
      )
      .on("pointerup", () => {
        innerPath.transition().duration(100).attr("stroke-width", 10);
      })
      .on("click", () => {
        onClick();
      })
      .on("mouseover", () => {
        innerPath.transition().duration(100).attr("stroke-width", 10);
      })
      .on("mouseout", () => {
        innerPath.transition().duration(100).attr("stroke-width", 6);
      });

    cableRef.current = cable;

    const links = d3.pairs(nodes).map(([source, target]) => {
      return { source, target };
    });

    nodes[0].fx = aPoint[0];
    nodes[0].fy = aPoint[1];

    nodes[nodes.length - 1].fx = bPoint[0];
    nodes[nodes.length - 1].fy = bPoint[1];

    const sim = d3
      .forceSimulation(nodes)
      .force("gravity", d3.forceY(forceY).strength(0.005))
      .force("collide", d3.forceCollide(20))
      .force("links", d3.forceLink(links).strength(0.8))
      .on("tick", () => {
        innerPath.attr("d", (_d) => {
          const d = _d as unknown as {
            nodes: { x: number; y: number }[];
            sim: d3.Simulation<CableNode, undefined>;
          };
          return d3CableRenderer(d.nodes);
        });
        outerPath.attr("d", innerPath.attr("d"));

        setCablePath(innerPath.attr("d"));
      });

    innerPath.datum({ nodes, sim });

    return () => {
      cable.remove();
    };
  }, []);

  useEffect(() => {
    const cable = cableRef.current;
    if (cable) {
      const innerPath = cable.selectChild();
      const { nodes, sim } = innerPath.datum() as unknown as {
        nodes?: CableNode[];
        sim?: d3.Simulation<CableNode, undefined>;
      };

      if (nodes && nodes.length > 1 && sim) {
        const first = nodes[0];
        const last = nodes[nodes?.length - 1];

        first.fx = aPoint[0];
        first.fy = aPoint[1];

        last.fx = bPoint[0];
        last.fy = bPoint[1];

        sim.alpha(1);
        sim.restart();
      }
    }
  }, [aPoint[0], aPoint[1], bPoint[0], bPoint[1]]);

  const labelPoints2 = useMemo(() => {
    if (!cablePath || !aSize || !bSize) {
      return;
    }

    const connectionNode = document.createElementNS(
      "http://www.w3.org/2000/svg",
      "path",
    );
    connectionNode.setAttribute("d", cablePath);

    const aRect = {
      x: aPoint[0] - aSize[0] / 2,
      y: aPoint[1] - aSize[1] / 2,
      width: aSize[0],
      height: aSize[1],
    };

    const bRect = {
      x: bPoint[0] - bSize[0] / 2,
      y: bPoint[1] - bSize[1] / 2,
      width: bSize[0],
      height: bSize[1],
    };

    const points = labelPointsForConnectionPath({
      connectionNode,
      aRect,
      bRect,
    });

    return points;
  }, [cablePath, aPoint, bPoint, aSize, bSize]);

  return (
    <g>
      <svg className="overflow-visible" ref={ref} />

      {labelPoints2 && labelPoints2.a && (
        <AssociationLabelGroup labelDefs={sideALabels} point={labelPoints2.a} />
      )}
      {labelPoints2 && labelPoints2.b && (
        <AssociationLabelGroup labelDefs={sideBLabels} point={labelPoints2.b} />
      )}
      {labelPoints2 && labelPoints2.midpoint && (
        <AssociationLabelGroup
          labelDefs={middleLabels}
          point={labelPoints2.midpoint}
        />
      )}
    </g>
  );
};

const AssociationLabel = React.memo(
  (props: {
    position: Vector;
    label: string;
    fillColor: string;
    textColor: string;
  }) => {
    const { position, label, fillColor, textColor } = props;

    const refText = useRef<SVGTextElement | null>(null);
    const [textMetrics, setTextMetrics] = useState<{
      width: number;
      height: number;
    } | null>(null);

    useLayoutEffect(() => {
      const textEl = refText.current;
      if (textEl) {
        const bbox = textEl.getBBox();
        setTextMetrics({
          width: bbox.width,
          height: bbox.height,
        });
      }
    }, [label]);

    const labelPaddingX = 10;
    const labelPaddingY = 5;

    const labelRect: Rect = {
      x: position[0] - (textMetrics ? textMetrics.width / 2 : 0),
      y: position[1] - (textMetrics ? textMetrics.height / 2 : 0),
      width: textMetrics ? textMetrics.width : 0,
      height: textMetrics ? textMetrics.height : 0,
    };

    const rectRect: Rect = {
      x: labelRect.x - labelPaddingX,
      y: labelRect.y - labelPaddingY,
      width: labelRect.width + labelPaddingX * 2,
      height: labelRect.height + labelPaddingY * 2,
    };

    return (
      <g>
        <rect
          fill={fillColor}
          x={rectRect.x}
          y={rectRect.y}
          width={rectRect.width}
          height={rectRect.height}
          rx={3}
          className="drop-shadow"
        ></rect>
        <text
          ref={refText}
          x={labelRect.x}
          y={labelRect.y}
          dy={16}
          textAnchor="start"
          fill={textColor}
          fontWeight="400"
          fontSize={16}
        >
          {label}
        </text>
      </g>
    );
  },
  _.isEqual,
);

function AssociationLabelGroup(props: {
  labelDefs: CableLabelDef[];
  point: Vector;
}) {
  const { labelDefs, point } = props;

  const sortedLabelDefs = _.sortBy(labelDefs, (labelDef) => labelDef.label);

  const LABEL_HEIGHT_WITH_MARGIN = 38;
  const groupHeight = LABEL_HEIGHT_WITH_MARGIN * sortedLabelDefs.length;
  const groupOffset = groupHeight / 2 - LABEL_HEIGHT_WITH_MARGIN / 2;

  return (
    <g className="pointer-events-none select-none">
      {sortedLabelDefs.map((labelDef, i) => {
        const { label } = labelDef;

        const yOffset = LABEL_HEIGHT_WITH_MARGIN * i - groupOffset;

        const position: Vector = [point[0], point[1] + yOffset];

        return (
          <AssociationLabel
            key={i}
            position={position}
            label={label}
            fillColor={colors.slate[500]}
            textColor={colors.slate[200]}
          />
        );
      })}
    </g>
  );
}

function hgLablePairToCableLabelDefs(params: {
  appliedLabelPair: connection.HGAppliedLabelPair;
  hgLabelPair: connection.HGLabelPair;
}): CableLabelDef[] {
  const { appliedLabelPair, hgLabelPair } = params;
  assert(
    hgLabelPair.type === "primary" ||
      hgLabelPair.type === "single-label" ||
      hgLabelPair.type === "paired-label",
  );
  if (hgLabelPair.type === "single-label") {
    const singleLabel = connection.singleLabelDisplayLabel(hgLabelPair);
    return [
      {
        label: singleLabel,
        type: "middle",
      },
    ];
  } else if (hgLabelPair.type === "paired-label") {
    const sideAHGLabel =
      appliedLabelPair.objectATypeId === hgLabelPair.hgLabels.hgLabelA.typeId
        ? hgLabelPair.hgLabels.hgLabelA
        : hgLabelPair.hgLabels.hgLabelB;
    const sideBHGLabel =
      appliedLabelPair.objectATypeId === hgLabelPair.hgLabels.hgLabelA.typeId
        ? hgLabelPair.hgLabels.hgLabelB
        : hgLabelPair.hgLabels.hgLabelA;

    return [
      {
        label: sideAHGLabel.label,
        type: "sideA",
      },
      {
        label: sideBHGLabel.label,
        type: "sideB",
      },
    ];
  } else if (hgLabelPair.type === "primary") {
    return [{ type: "middle", label: "Primary Company" }];
  } else {
    assertNever(hgLabelPair);
  }
}

const CanvasConnectionEdge: React.FC<{
  hgConnection: connection.HGConnection;
  sourceShape: CardShape;
  targetShape: CardShape;
}> = observer((props) => {
  const { hgConnection, sourceShape, targetShape } = props;

  if (_.isEmpty(hgConnection.appliedLabelPairs)) {
    return null;
  }

  if (
    !domain.isCardShapeHubSpot(sourceShape) ||
    !domain.isCardShapeHubSpot(targetShape)
  ) {
    return null;
  }

  const store = useStore();

  const sourceSize = store.cardSizeCache[sourceShape.id] || [340, 410];
  const targetSize = store.cardSizeCache[targetShape.id] || [340, 410];

  const sourcePoint: Vector = useMemo(() => {
    return [
      sourceShape.point[0] + sourceSize[0] / 2,
      sourceShape.point[1] + sourceSize[1] / 2,
    ];
  }, [...sourceShape.point, ...sourceSize]);
  const targetPoint: Vector = useMemo(() => {
    return [
      targetShape.point[0] + targetSize[0] / 2,
      targetShape.point[1] + targetSize[1] / 2,
    ];
  }, [...targetShape.point, ...targetSize]);

  const labelTags = hgConnection.appliedLabelPairs
    .map((appliedLabelPair) => {
      const hgLabelPair = store.hgLabelPairs[
        appliedLabelPair.labelPairCanonicalId
      ] as connection.HGLabelPair | undefined;
      if (!hgLabelPair) {
        return undefined;
      }
      return { appliedLabelPair, hgLabelPair };
    })
    .filter(isNotUndefined)
    .filter(({ hgLabelPair }) => hgLabelPair.type !== "unlabelled")
    .map(({ appliedLabelPair, hgLabelPair }) => {
      return hgLablePairToCableLabelDefs({ appliedLabelPair, hgLabelPair });
    })
    .flatMap((cableLabelDef) => cableLabelDef);

  return (
    <InteractiveCable
      onClick={() => {
        actions.startEditingConnection({
          shapeIdA: sourceShape.id,
          shapeIdB: targetShape.id,
        });
      }}
      aPoint={sourcePoint}
      bPoint={targetPoint}
      aSize={sourceSize}
      bSize={targetSize}
      labelDefs={labelTags}
    />
  );
});

// const DebugSurface = () => {
//   const curve =
//     "M3195.43,587.7887499999999L3224.302429190749,522.8944250866721C3253.1748583814974,458.0001001733444,3310.919716762995,328.21145034668876,3321.979274039312,234.86282533067535C3333.038831315629,141.51420031466193,3297.413087486766,84.60560010929072,3242.6504280080026,37.58764341919054C3187.8877685292387,-9.430313270909636,3113.988193400574,-46.55762644573878,3036.321605337771,-80.9148641905639C2958.655017274968,-115.27210193538905,2877.2214162780256,-146.85926425021015,2780.0399798993735,-185.0271909801155C2682.8585435207224,-223.19511771002087,2569.929271760361,-267.94380885501045,2513.4646358801806,-290.31815442750525L2457,-312.6925";

//   const aPath = "M 3025.43,424.56999999999994 h340 v326.4375 h-340 z";
//   const bPath = "M 2287,-644.88 h340 v664.375 h-340 z";

//   const aIntersections = findPathIntersections(curve, aPath);
//   const bIntersections = findPathIntersections(curve, bPath);

//   console.log("aIntersections", aIntersections);
//   console.log("bIntersections", bIntersections);

//   let bez1Path: string;
//   const [x1, y1, ...rest] = aIntersections[0].bez2;
//   const lineTos = _.chunk(rest, 2).map(([x, y], i) => `L ${x} ${y}`);
//   bez1Path = `M ${x1} ${y1} ${lineTos.join(" ")}`;

//   const aIntersectPoint: [x: number, y: number] = [
//     aIntersections[0].x,
//     aIntersections[0].y,
//   ];

//   const curveSVG = document.createElementNS(
//     "http://www.w3.org/2000/svg",
//     "path",
//   );
//   curveSVG.setAttribute("d", curve);
//   const intersectPoint = closestPoint(curveSVG, aIntersectPoint);

//   console.log("intersectPoint", intersectPoint);

//   const pointAtLength = curveSVG.getPointAtLength(intersectPoint.length);

//   const labelPoints = labelPointsForConnectionPath({
//     connectionNode: curveSVG,
//     aRect: {
//       x: 3025.43,
//       y: 424.56999999999994,
//       width: 340,
//       height: 326.4375,
//     },
//     bRect: {
//       x: 2287,
//       y: -644.88,
//       width: 340,
//       height: 664.375,
//     },
//   });

//   console.log("labelPoints", labelPoints);

//   return (
//     <>
//       <path d={curve} stroke="red" strokeWidth="2" fill="none" />

//       <path d={aPath} stroke="orange" strokeWidth="7" fill="none" />
//       <path d={bPath} stroke="orange" strokeWidth="7" fill="none" />

//       {labelPoints &&
//         [labelPoints.a, labelPoints.b, labelPoints.midpoint].map(
//           (labelPoint, i) => {
//             return (
//               <circle
//                 key={i}
//                 cx={labelPoint[0]}
//                 cy={labelPoint[1]}
//                 r="5"
//                 fill={"#000"}
//               />
//             );
//           },
//         )}

//       {/* <path d={bez1Path} stroke="blue" strokeWidth="5" fill="none" />

//       <circle
//         cx={aIntersectPoint[0]}
//         cy={aIntersectPoint[1]}
//         r="20"
//         fill={"#000"}
//       />

//       <circle
//         cx={intersectPoint.point[0]}
//         cy={intersectPoint.point[1]}
//         r="10"
//         fill={"#fff"}
//       />

//       <circle
//         cx={pointAtLength.x}
//         cy={pointAtLength.y}
//         r="5"
//         fill={colors.emerald[500]}
//       /> */}
//     </>
//   );
// };

export const CanvasConnectionsEdgeLayer = observer(() => {
  const store = useStore();
  const tlApp = useTLApp();

  const hgConnections = Object.values(store.hgConnections);

  // force a read of the document notify update state so we can move links based on document updates (e.g. shape moved)
  store.documentNotifyUpdate;

  const shapes = Object.values(
    (tlApp.document as TDDocument).pages.page_1.shapes,
  );

  return (
    <svg
      data-layer={"CanvasConnectionsEdgeLayer"}
      className="pointer-events-none"
      style={{
        position: "absolute",
        transform: "translate(0, 0)",
        width: "calc(0px + (var(--tl-padding) * 2))",
        height: "calc(0px + (var(--tl-padding) * 2))",
        transformOrigin: "0 0",
        contain: "layout style size",
        overflow: "visible",
      }}
    >
      {hgConnections.map((hgConnection) => {
        const objectRefA = hgConnection.objectRefA;
        const objectRefB = hgConnection.objectRefB;

        const sourceShape = shapeByObjectRef(shapes, objectRefA);
        const targetShape = shapeByObjectRef(shapes, objectRefB);

        if (!sourceShape || !targetShape) {
          return;
        }

        return (
          <React.Fragment key={hgConnection.canonicalId}>
            <CanvasConnectionEdge
              hgConnection={hgConnection}
              sourceShape={sourceShape}
              targetShape={targetShape}
            />
            {/* <DebugSurface /> */}
          </React.Fragment>
        );
      })}
    </svg>
  );
});
