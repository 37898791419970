import React from "react";

// export function EraserIcon() {
//   return (
//     <svg
//       width="15"
//       height="15"
//       viewBox="0 0 15 15"
//       fill="none"
//       xmlns="http://www.w3.org/2000/svg"
//     >
//       <path
//         d="M1.72838 9.33987L8.84935 2.34732C9.23874 1.96494 9.86279 1.96539 10.2516 2.34831L13.5636 5.60975C13.9655 6.00555 13.9607 6.65526 13.553 7.04507L8.13212 12.2278C7.94604 12.4057 7.69851 12.505 7.44107 12.505L6.06722 12.505L3.83772 12.505C3.5673 12.505 3.30842 12.3954 3.12009 12.2014L1.7114 10.7498C1.32837 10.3551 1.33596 9.72521 1.72838 9.33987Z"
//         stroke="currentColor"
//       />
//       <line
//         x1="6.01807"
//         y1="12.5"
//         x2="10.7959"
//         y2="12.5"
//         stroke="currentColor"
//         strokeLinecap="round"
//       />
//       <line
//         x1="5.50834"
//         y1="5.74606"
//         x2="10.1984"
//         y2="10.4361"
//         stroke="currentColor"
//       />
//     </svg>
//   );
// }

export function EraserIcon() {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      clipRule="evenodd"
    >
      <path
        d="M5.662 23l-5.369-5.365c-.195-.195-.293-.45-.293-.707 0-.256.098-.512.293-.707l14.929-14.928c.195-.194.451-.293.707-.293.255 0 .512.099.707.293l7.071 7.073c.196.195.293.451.293.708 0 .256-.097.511-.293.707l-11.216 11.219h5.514v2h-12.343zm3.657-2l-5.486-5.486-1.419 1.414 4.076 4.072h2.829zm6.605-17.581l-10.677 10.68 5.658 5.659 10.676-10.682-5.657-5.657z"
        fill="currentColor"
      />
    </svg>
  );
}
