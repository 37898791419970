import {
  Circle,
  Delete,
  FormatAlignLeft,
  FormatAlignCenter,
  FormatAlignRight,
  FormatAlignJustify,
} from "@mui/icons-material";
import { FaRegObjectGroup, FaRegObjectUngroup } from "react-icons/fa";
import {
  TDSnapshot,
  TDShapeType,
  ShapeStyles,
  DashStyle,
  ColorStyle,
  SizeStyle,
  FontStyle,
  AlignStyle,
} from "@orgcharthub/tldraw-tldraw";
import _ from "lodash";
import { observer } from "mobx-react-lite";
import React from "react";
import { useTLApp, useTLAppStore } from "../hooks/hooks";
import { LineIcon } from "./primitives";
import { CursorIcon } from "./primitives/CursorIcon";
import { DashDashedIcon } from "./primitives/DashDashedIcon";
import { DashDottedIcon } from "./primitives/DashDottedIcon";
import { DashDrawIcon } from "./primitives/DashDrawIcon";
import { DashSolidIcon } from "./primitives/DashSolidIcon";
import { TextIcon } from "./primitives/TextIcon";
import { ToolbarIconButton } from "./ToolbarButton";
import * as Popover from "@radix-ui/react-popover";
import * as Switch from "@radix-ui/react-switch";

const selectedShapeIdsSelector = (s: TDSnapshot) => {
  return s.document.pageStates.page_1.selectedIds;
};

const selectedShapeTypesSelector = (s: TDSnapshot): TDShapeType[] => {
  const ids = selectedShapeIdsSelector(s);

  const shapeTypes = (ids: string[]): TDShapeType[] => {
    const types = ids.map((id) => {
      const shape = s.document.pages.page_1.shapes[id];
      if (!shape) {
        return [];
      }
      if (shape.children) {
        return shapeTypes(shape.children);
      } else {
        return [shape.type];
      }
    });

    return _.chain(types).flatten().uniq().value();
  };

  return shapeTypes(ids);
};

const selectionContainsGroupSelector = (s: TDSnapshot) => {
  const page = s.document.pages[s.appState.currentPageId];
  const selectedShapes = s.document.pageStates[
    s.appState.currentPageId
  ].selectedIds.map((id) => page.shapes[id]);

  return (
    selectedShapes.length > 0 &&
    selectedShapes.some(
      (shape) =>
        shape.children !== undefined ||
        (shape.parentId === selectedShapes[0].parentId &&
          selectedShapes[0].parentId !== s.appState.currentPageId),
    )
  );
};

const colors: Readonly<Record<ColorStyle, string>> = {
  [ColorStyle.White]: "#f0f1f3",
  [ColorStyle.LightGray]: "#c6cbd1",
  [ColorStyle.Gray]: "#788492",
  [ColorStyle.Black]: "#1d1d1d",
  [ColorStyle.Green]: "#36b24d",
  [ColorStyle.Cyan]: "#0e98ad",
  [ColorStyle.Blue]: "#1c7ed6",
  [ColorStyle.Indigo]: "#4263eb",
  [ColorStyle.Violet]: "#7746f1",
  [ColorStyle.Red]: "#ff2133",
  [ColorStyle.Orange]: "#ff9433",
  [ColorStyle.Yellow]: "#ffc936",
};

const STYLE_KEYS = [
  "color",
  "size",
  "dash",
  "font",
  "textAlign",
  "isFilled",
  "scale",
] as (keyof ShapeStyles)[];

const DASH_ICONS = {
  [DashStyle.Draw]: <DashDrawIcon />,
  [DashStyle.Solid]: <DashSolidIcon />,
  [DashStyle.Dashed]: <DashDashedIcon />,
  [DashStyle.Dotted]: <DashDottedIcon />,
} as const;

const SIZE_LETTERS = {
  [SizeStyle.Small]: "S",
  [SizeStyle.Medium]: "M",
  [SizeStyle.Large]: "L",
} as const;

const ALIGN_ICONS = {
  [AlignStyle.Start]: <FormatAlignLeft />,
  [AlignStyle.Middle]: <FormatAlignCenter />,
  [AlignStyle.End]: <FormatAlignRight />,
  [AlignStyle.Justify]: <FormatAlignJustify />,
};

const currentStyleSelector = (s: TDSnapshot) => s.appState.currentStyle;

function ToolbarSegmentDelete() {
  const tlApp = useTLApp();

  const handleOnDelete = () => {
    tlApp.delete();
  };

  return (
    <ToolbarIconButton iconSize={20} onClick={handleOnDelete}>
      <Delete />
    </ToolbarIconButton>
  );
}

function ToolbarSegmentGroupUngroup() {
  const tlApp = useTLApp();

  const selectedShapeIds = useTLAppStore(selectedShapeIdsSelector);
  const selectionContainsGroups = useTLAppStore(selectionContainsGroupSelector);

  const isMultiSelection = selectedShapeIds.length > 1;

  const handleGroupShapes = () => {
    tlApp.group();
  };

  const handleUnGroupShapes = () => {
    tlApp.ungroup();
  };

  return (
    <>
      {isMultiSelection && (
        <ToolbarIconButton iconSize={20} onClick={handleGroupShapes}>
          <FaRegObjectGroup size={20} />
        </ToolbarIconButton>
      )}
      {selectionContainsGroups && (
        <ToolbarIconButton iconSize={20} onClick={handleUnGroupShapes}>
          <FaRegObjectUngroup size={20} />
        </ToolbarIconButton>
      )}
    </>
  );
}

function FillNoFillButton({
  checked,
  onCheckedChange,
}: {
  checked: boolean;
  onCheckedChange: (checked: boolean) => void;
}) {
  return (
    <form>
      <div className="flex flex-row items-center justify-start">
        <label htmlFor="style-fill" className="text-base font-normal mr-3">
          Fill
        </label>
        <Switch.Root
          checked={checked}
          onCheckedChange={onCheckedChange}
          id="style-fill"
          className={`w-[42px] h-[25px] rounded-full relative ${
            checked ? "bg-green-600" : "bg-slate-300"
          }`}
        >
          <Switch.Thumb
            className={`block w-[21px] h-[21px] rounded-full bg-white shadow-lg will-change-transform transition-trasnform ease-in duration-100 ${
              checked ? "translate-x-[19px]" : "translate-x-[2px]"
            }`}
          />
        </Switch.Root>
      </div>
    </form>

    // <ToggleGroup.Root
    //   type="single"
    //   defaultValue="no-fill"
    //   className="w-full bg-red-500 flex flex-row"
    // >
    //   <ToggleGroup.Item
    //     value="no-fill"
    //     className="flex-grow flex items-center justify-center"
    //   >
    //     <span>No Fill</span>
    //   </ToggleGroup.Item>
    //   <ToggleGroup.Item
    //     value="no-fill"
    //     className="flex-grow flex items-center justify-center"
    //   >
    //     <span>Fill</span>
    //   </ToggleGroup.Item>
    // </ToggleGroup.Root>
  );
}

const ColorStylesPopup = () => {
  const app = useTLApp();

  const selectedIds = useTLAppStore(selectedShapeIdsSelector);
  const currentStyle = useTLAppStore(currentStyleSelector);

  const [displayedStyle, setDisplayedStyle] = React.useState(currentStyle);

  const rDisplayedStyle = React.useRef(currentStyle);
  React.useEffect(() => {
    const {
      appState: { currentStyle },
      page,
      selectedIds,
    } = app;
    let commonStyle = {} as ShapeStyles;
    if (selectedIds.length <= 0) {
      commonStyle = currentStyle;
    } else {
      const overrides = new Set<string>([]);
      app.selectedIds
        .map((id) => page.shapes[id])
        .forEach((shape) => {
          STYLE_KEYS.forEach((key) => {
            if (overrides.has(key)) return;
            if (commonStyle[key] === undefined) {
              // @ts-ignore
              commonStyle[key] = shape.style[key];
            } else {
              if (commonStyle[key] === shape.style[key]) return;
              // @ts-ignore
              commonStyle[key] = shape.style[key];
              overrides.add(key);
            }
          });
        });
    }
    // Until we can work out the correct logic for deciding whether or not to
    // update the selected style, do a string comparison. Yuck!
    if (
      JSON.stringify(commonStyle) !== JSON.stringify(rDisplayedStyle.current)
    ) {
      rDisplayedStyle.current = commonStyle;
      setDisplayedStyle(commonStyle);
    }
  }, [currentStyle, selectedIds]);

  return (
    <div className="flex flex-col">
      <div className="divide-y-2 divide-slate-200">
        <div className="flex flex-col p-2 pt-2 pb-3 space-y-3">
          <div className="grid gap-y-0.5 grid-cols-6 grid-rows-2">
            {Object.keys(colors).map((colorStyle) => {
              const _colorStyle = colorStyle as ColorStyle;
              const hex = colors[_colorStyle];
              const isActive = displayedStyle.color === _colorStyle;
              return (
                <button
                  key={_colorStyle}
                  style={{ height: 40, width: 40 }}
                  className={`flex items-center justify-center hover:bg-slate-200 active:bg-slate-300 rounded-lg transition-all ${
                    isActive ? "bg-slate-200" : ""
                  }`}
                  onClick={() => {
                    app.style({ color: _colorStyle });
                  }}
                >
                  <div
                    className="rounded-full"
                    style={{ height: 24, width: 24, backgroundColor: hex }}
                  ></div>
                </button>
              );
            })}
          </div>

          <div className="pl-2">
            <FillNoFillButton
              checked={!!displayedStyle.isFilled}
              onCheckedChange={(checked) => {
                app.style({ isFilled: checked });
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

function BorderStylesPopup() {
  const app = useTLApp();

  const selectedIds = useTLAppStore(selectedShapeIdsSelector);
  const currentStyle = useTLAppStore(currentStyleSelector);

  const [displayedStyle, setDisplayedStyle] = React.useState(currentStyle);

  const rDisplayedStyle = React.useRef(currentStyle);
  React.useEffect(() => {
    const {
      appState: { currentStyle },
      page,
      selectedIds,
    } = app;
    let commonStyle = {} as ShapeStyles;
    if (selectedIds.length <= 0) {
      commonStyle = currentStyle;
    } else {
      const overrides = new Set<string>([]);
      app.selectedIds
        .map((id) => page.shapes[id])
        .forEach((shape) => {
          STYLE_KEYS.forEach((key) => {
            if (overrides.has(key)) return;
            if (commonStyle[key] === undefined) {
              // @ts-ignore
              commonStyle[key] = shape.style[key];
            } else {
              if (commonStyle[key] === shape.style[key]) return;
              // @ts-ignore
              commonStyle[key] = shape.style[key];
              overrides.add(key);
            }
          });
        });
    }
    // Until we can work out the correct logic for deciding whether or not to
    // update the selected style, do a string comparison. Yuck!
    if (
      JSON.stringify(commonStyle) !== JSON.stringify(rDisplayedStyle.current)
    ) {
      rDisplayedStyle.current = commonStyle;
      setDisplayedStyle(commonStyle);
    }
  }, [currentStyle, selectedIds]);

  return (
    <div className="flex flex-col">
      <div className="divide-y-2 divide-slate-200">
        <div className="flex flex-row items-center justify-center space-between p-2">
          <div className="grid gap-x-0.5 grid-cols-4 grid-rows-1">
            {Object.values(DashStyle).map((style) => {
              const icon = DASH_ICONS[style];
              const isActive = style === displayedStyle.dash;
              return (
                <button
                  key={style}
                  className={`flex items-center justify-center hover:bg-slate-200 active:bg-slate-300 rounded-lg transition-all ${
                    isActive ? "bg-slate-200" : ""
                  }`}
                  style={{ height: 40, width: 40 }}
                  onClick={(e) => {
                    app.style({ dash: style });
                  }}
                >
                  {icon}
                </button>
              );
            })}
          </div>
        </div>

        <div className="flex flex-row items-center justify-end space-between p-2">
          <div className="grid gap-x-0.5 grid-cols-3 grid-rows-1">
            {Object.values(SizeStyle).map((style) => {
              const letter = SIZE_LETTERS[style];
              const isActive = style === displayedStyle.size;
              return (
                <button
                  key={style}
                  className={`flex items-center justify-center hover:bg-slate-200 active:bg-slate-300 rounded-lg transition-all text-base font-semibold ${
                    isActive ? "bg-slate-200" : ""
                  }`}
                  style={{ height: 40, width: 40 }}
                  onClick={(e) => {
                    app.style({ size: style });
                  }}
                >
                  {letter}
                </button>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

function TextStylesPopup() {
  const app = useTLApp();

  const selectedIds = useTLAppStore(selectedShapeIdsSelector);
  const currentStyle = useTLAppStore(currentStyleSelector);

  const [displayedStyle, setDisplayedStyle] = React.useState(currentStyle);

  const rDisplayedStyle = React.useRef(currentStyle);
  React.useEffect(() => {
    const {
      appState: { currentStyle },
      page,
      selectedIds,
    } = app;
    let commonStyle = {} as ShapeStyles;
    if (selectedIds.length <= 0) {
      commonStyle = currentStyle;
    } else {
      const overrides = new Set<string>([]);
      app.selectedIds
        .map((id) => page.shapes[id])
        .forEach((shape) => {
          STYLE_KEYS.forEach((key) => {
            if (overrides.has(key)) return;
            if (commonStyle[key] === undefined) {
              // @ts-ignore
              commonStyle[key] = shape.style[key];
            } else {
              if (commonStyle[key] === shape.style[key]) return;
              // @ts-ignore
              commonStyle[key] = shape.style[key];
              overrides.add(key);
            }
          });
        });
    }
    // Until we can work out the correct logic for deciding whether or not to
    // update the selected style, do a string comparison. Yuck!
    if (
      JSON.stringify(commonStyle) !== JSON.stringify(rDisplayedStyle.current)
    ) {
      rDisplayedStyle.current = commonStyle;
      setDisplayedStyle(commonStyle);
    }
  }, [currentStyle, selectedIds]);

  return (
    <div className="flex flex-col">
      <div className="divide-y-2 divide-slate-200">
        <div className="flex flex-row items-center justify-center space-between p-2">
          <div className="grid gap-x-0.5 grid-cols-4 grid-rows-1">
            {Object.values(FontStyle).map((fontStyle) => {
              let fontFamily: string;
              switch (fontStyle) {
                case FontStyle.Script:
                  fontFamily = "Caveat Brush";
                  break;
                case FontStyle.Sans:
                  fontFamily = "Recursive";
                  break;
                case FontStyle.Serif:
                  fontFamily = "Georgia";
                  break;
                case FontStyle.Mono:
                  fontFamily = "Recursive Mono";
                  break;
                default:
                  fontFamily = "Recursive";
              }

              return (
                <button
                  key={fontStyle}
                  className={`flex items-center justify-center hover:bg-slate-200 active:bg-slate-300 rounded-lg transition-all ${
                    fontStyle === displayedStyle.font ? "bg-slate-200" : ""
                  }`}
                  style={{ height: 40, width: 40 }}
                  onClick={(e) => {
                    app.style({ font: fontStyle });
                  }}
                >
                  <div style={{ fontFamily }}>Aa</div>
                </button>
              );
            })}
          </div>
        </div>

        <div className="flex flex-row items-center justify-center space-between p-2">
          <div className="grid gap-x-0.5 grid-cols-4 grid-rows-1">
            {Object.values(AlignStyle).map((style) => {
              return (
                <button
                  key={style}
                  className={`flex items-center justify-center hover:bg-slate-200 active:bg-slate-300 rounded-lg transition-all text-base font-semibold ${
                    style === displayedStyle.textAlign ? "bg-slate-200" : ""
                  }`}
                  style={{ height: 40, width: 40 }}
                  onClick={(e) => {
                    app.style({ textAlign: style });
                  }}
                >
                  {ALIGN_ICONS[style]}
                </button>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

const FloatingToolbarMixed = observer(() => {
  return (
    <div className="flex flex-row">
      <div>mixed toolbar</div>
      <ToolbarSegmentGroupUngroup />
      <ToolbarSegmentDelete />
    </div>
  );
});

const FloatingToolbarTLDrawShapes = observer(() => {
  const tlApp = useTLApp();
  const selectedShapeIds = useTLAppStore(selectedShapeIdsSelector);

  return (
    <div className="flex flex-row">
      <ToolbarIconButton iconSize={20}>
        <CursorIcon />
      </ToolbarIconButton>

      <Popover.Root>
        <Popover.Trigger asChild>
          <ToolbarIconButton iconSize={20}>
            <Circle />
          </ToolbarIconButton>
        </Popover.Trigger>
        <Popover.Portal>
          <Popover.Content sideOffset={8} className="z-[2] bg-white shadow-vlg">
            <ColorStylesPopup />
          </Popover.Content>
        </Popover.Portal>
      </Popover.Root>

      <Popover.Root>
        <Popover.Trigger asChild>
          <ToolbarIconButton iconSize={20}>
            <LineIcon />
          </ToolbarIconButton>
        </Popover.Trigger>
        <Popover.Portal>
          <Popover.Content sideOffset={8} className="z-[2] bg-white shadow-vlg">
            <BorderStylesPopup />
          </Popover.Content>
        </Popover.Portal>
      </Popover.Root>

      <Popover.Root>
        <Popover.Trigger asChild>
          <ToolbarIconButton iconSize={20}>
            <TextIcon />
          </ToolbarIconButton>
        </Popover.Trigger>
        <Popover.Portal>
          <Popover.Content sideOffset={8} className="z-[2] bg-white shadow-vlg">
            <TextStylesPopup />
          </Popover.Content>
        </Popover.Portal>
      </Popover.Root>

      <ToolbarSegmentGroupUngroup />

      <ToolbarSegmentDelete />
    </div>
  );
});

const FloatingToolbarCards = observer(() => {
  return (
    <div className="flex flex-row">
      cards toolbar
      <ToolbarSegmentGroupUngroup />
      <ToolbarSegmentDelete />
    </div>
  );
});

export const FloatingToolbar = observer(() => {
  return null;

  const tlApp = useTLApp();

  const selectedShapeTypes = tlApp.useStore(selectedShapeTypesSelector);

  const hasCardShapeSelected = _.includes(selectedShapeTypes, TDShapeType.Card);
  const onlyCardShapeSelected =
    hasCardShapeSelected && selectedShapeTypes.length === 1;

  if (selectedShapeTypes.length === 0) {
    return null;
  }

  let innerComponent;
  if (onlyCardShapeSelected) {
    innerComponent = <FloatingToolbarCards />;
  } else if (!hasCardShapeSelected) {
    innerComponent = <FloatingToolbarTLDrawShapes />;
  } else {
    innerComponent = <FloatingToolbarMixed />;
  }

  return <div className="select-none">{innerComponent}</div>;
});
