import cytoscape, { ElementDefinition } from "cytoscape";
import _ from "lodash";
import { canonicalIdForHGObjectRef, HGObjectRef } from "..";
import { HGConnection } from "../connection";

type BoundingRect = {
  x: number;
  y: number;
  width: number;
  height: number;
};

async function performCytoscapeLayout(params: {
  nodes: cytoscape.NodeDefinition[];
  edges: cytoscape.EdgeDefinition[];
}): Promise<Record<string, BoundingRect>> {
  const { nodes, edges } = params;

  const cy = cytoscape({
    headless: true,
    container: null,
    // container: document.getElementById("cytoscape")!,
    elements: { nodes, edges },
    styleEnabled: true,
    zoom: 1,
    style: [
      {
        selector: "node",
        style: {
          shape: "rectangle",
          width: (ele: unknown) => {
            const n = (ele as cytoscape.NodeSingular).data("width") as number;
            return n;
          },
          height: (ele: unknown) => {
            const n = (ele as cytoscape.NodeSingular).data("height") as number;
            return n;
          },
        },
      },
    ],
  });

  const layout = cy.layout({
    name: "concentric",
    minNodeSpacing: 300,
  });

  const promise = layout.promiseOn("layoutstop");
  layout.run();
  await promise;

  const newBoundingRects: Record<string, BoundingRect> = cy
    .nodes()
    .reduce((acc, node) => {
      const boundingRect: BoundingRect = {
        height: node.height(),
        width: node.width(),
        x: node.position().x,
        y: node.position().y,
      };
      acc[node.id()] = boundingRect;
      return acc;
    }, {} as Record<string, BoundingRect>);

  return newBoundingRects;
}

export async function layout(params: {
  hgConnections: HGConnection[];
}): Promise<Record<string, BoundingRect>> {
  const { hgConnections } = params;

  const edges: cytoscape.EdgeDefinition[] = hgConnections.map((connection) => {
    const canoncialA = canonicalIdForHGObjectRef(connection.objectRefA);
    const canoncialB = canonicalIdForHGObjectRef(connection.objectRefB);
    const edgeId = [canoncialA, canoncialB].sort().join("->");
    return {
      group: "edges",
      data: {
        id: edgeId,
        source: canoncialA,
        target: canoncialB,
      },
    };
  });

  const nodes: cytoscape.NodeDefinition[] = _.chain(hgConnections)
    .flatMap((connection) => {
      return [connection.objectRefA, connection.objectRefB];
    })
    .uniqBy((objectRef) => canonicalIdForHGObjectRef(objectRef))
    .map((objectRef) => {
      const canonicalId = canonicalIdForHGObjectRef(objectRef);
      const node: cytoscape.NodeDefinition = {
        group: "nodes",
        data: {
          id: canonicalId,
          width: 350,
          height: 560,
        },
      };
      return node;
    })
    .value();

  const boundingBoxes = await performCytoscapeLayout({ nodes, edges });

  return boundingBoxes;
}
