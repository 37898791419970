import React from "react";

export function StickyNoteIcon() {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 19V4H20V10.543C20 14.65 14 13 14 13C14 13 15.518 19 11.362 19H4ZM22 11.386V2H2V21H12.189C15.352 21 22 13.777 22 11.386Z"
        fill="currentColor"
      />
    </svg>
  );
}
