import React from "react";
import { Tooltip, TooltipProps } from "@mui/material";

export const TooltipSolid: React.FC<TooltipProps> = (props) => {
  const { children, ...otherProps } = props;
  return (
    <Tooltip
      {...otherProps}
      componentsProps={{
        tooltip: {
          style: {
            marginLeft: 20,
          },
          sx: {
            backgroundColor: "rgba(27,38,49,1)",
            fontSize: "0.875rem",
            fontWeight: "400",
            "& .MuiTooltip-arrow": {
              color: "rgba(27,38,49,1)",
            },
          },
        },
      }}
    >
      {children}
    </Tooltip>
  );
};
