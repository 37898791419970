import React from "react";
import { observer } from "mobx-react-lite";
import * as computeds from "../computeds";
import * as icons from "@mui/icons-material";

export const ObjectTypeTagWrappedIcon: React.FC<{
  objectType: string;
}> = observer((props) => {
  const { objectType } = props;

  const displayLabelSingular =
    computeds.displayLabelForObjectTypeSingular(objectType);

  let iconNode: React.ReactNode | undefined;
  switch (objectType) {
    case "contact": {
      iconNode = <icons.Person color="inherit" fontSize="small" />;
      break;
    }
    case "company": {
      iconNode = <icons.Business color="inherit" fontSize="small" />;
      break;
    }
    case "deal": {
      iconNode = <icons.Handshake color="inherit" fontSize="small" />;
      break;
    }
  }

  type ColorConfig = { text: string; background: string };
  const objectTypeColorClasses: Record<string, ColorConfig | undefined> = {
    contact: {
      background: "bg-purple-100",
      text: "text-purple-900",
    },
    company: {
      background: "bg-blue-100",
      text: "text-blue-900",
    },
    deal: {
      background: "bg-emerald-100",
      text: "text-emerald-900",
    },
  };

  const defaultColorClasses: ColorConfig = {
    text: "text-slate-900",
    background: "bg-slate-200",
  };

  const colorConfig = objectTypeColorClasses[objectType] || defaultColorClasses;

  return (
    <div
      className={`px-2 py-1.5 ${colorConfig.background} inline-flex flex-row items-center space-x-1.5 rounded-md`}
    >
      <span className={`flex items-center justify-center ${colorConfig.text}`}>
        {iconNode}
      </span>
      <div className={`${colorConfig.text} text-sm font-medium`}>
        {displayLabelSingular}
      </div>
    </div>
  );
});
