import React, { useState } from "react";
import * as actions from "../actions";
import { useDebounce } from "usehooks-ts";
import { observer } from "mobx-react-lite";
import { IconButton, MenuItem, Select, TextField } from "@mui/material";
import * as domain from "../domain";
import _ from "lodash";
import { useQuery } from "@tanstack/react-query";
import * as hubspotApi from "../api/hubspot-api";
import { AddToMapSuggestionRow } from "./AddToMapSuggestion";
import * as computeds from "../computeds";
import { useStore } from "../hooks/hooks";
import { Close as CloseIcon } from "@mui/icons-material";

const AddHGObjectToMapSidebarContent: React.FC<{
  onCancel?: () => void;
}> = observer((props) => {
  const { onCancel } = props;
  const store = useStore();

  const draftState = store.draftAddingObjectToChart || {
    objectType: "company",
  };

  const [query, setQuery] = useState<string>("");
  const actualQuery = useDebounce(query, 500);

  const accessToken = store.auth.accessToken;

  const objectTypeDisplayLabelPlural =
    computeds.displayLabelForObjectTypePlural(draftState.objectType);

  const propertiesToFetch = computeds.propertiesToFetchForObjectType(
    draftState.objectType,
  );

  const { data, isError, isLoading } = useQuery(
    ["search", actualQuery, draftState.objectType, accessToken],
    async () => {
      if (!accessToken) {
        return;
      }
      return await hubspotApi.searchObjects({
        authState: { accessToken },
        objectType: draftState.objectType,
        query: actualQuery,
        propertiesToFetch,
      });
    },
  );

  const supportedObjectTypes = computeds.supportedObjectTypes.get();
  const customObjectsSupported = store.customObjectsSupported;

  const objectTypeSelectOptions: { value: string; label: string }[] = [
    ...supportedObjectTypes.map((objectType) => {
      const displayLabel =
        computeds.displayLabelForObjectTypeSingular(objectType);
      return {
        value: objectType,
        label: displayLabel,
      };
    }),
    ...(customObjectsSupported
      ? []
      : [
          {
            value: "enable-custom-objects",
            label: "Enable custom objects...",
          },
        ]),
  ];

  return (
    <>
      <div className="flex flex-row items-center justify-between border-b-2 border-slate-500 bg-slate-300 px-4 py-2 text-slate-800">
        <span className="leading-none text-base font-semibold">Add to map</span>

        <IconButton size="small" color="inherit" onClick={onCancel}>
          <CloseIcon />
        </IconButton>
      </div>

      <div className="px-2 pt-2 pb-3 bg-slate-100">
        <div className="mb-3">
          <TextField
            autoFocus={true}
            autoComplete="off"
            value={query}
            onChange={(e) => {
              setQuery(e.target.value);
            }}
            label={`Search ${objectTypeDisplayLabelPlural}`}
            variant="filled"
            size="small"
            fullWidth={true}
          />
        </div>

        <Select
          fullWidth={true}
          label="Type"
          value={draftState.objectType}
          size="small"
          onChange={(e) => {
            const v = e.target.value;
            if (typeof v !== "string") {
              return;
            }
            if (v === "enable-custom-objects") {
              actions.enableCustomObjects();
            } else {
              actions.updateAddingObjectToChartObjectType({ objectType: v });
            }
          }}
        >
          {objectTypeSelectOptions.map((option) => {
            if (option.value === "enable-custom-objects") {
              return (
                <MenuItem
                  key="enable-custom-objects"
                  value="enable-custom-objects"
                >
                  <span className="text-base text-blue-600">
                    Enable custom objects...
                  </span>
                </MenuItem>
              );
            } else {
              return (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              );
            }
          })}
        </Select>
      </div>

      <div className="overflow-y-scroll flex flex-col">
        {isError && <div>Error!</div>}

        {isLoading && !isError && <div className="p-4">Loading...</div>}

        {!_.isEmpty(data) && !isLoading && !isError && (
          <div className="divide-y-2 border-y-2 border-slate-300">
            {_.map(data, (hgObject) => {
              return (
                <AddToMapSuggestionRow
                  key={domain.canonicalIdForHGObjectRef(hgObject)}
                  hgObject={hgObject}
                  onAddToMap={() => {
                    actions.addObjectToChart({ hgObject });
                  }}
                />
              );
            })}
          </div>
        )}

        {_.isEmpty(data) && !isLoading && !isError && (
          <div className="px-4 pb-4">
            <div className="p-4 rounded-md bg-slate-100 flex items-center justify-center text-slate-700 font-medium text-sm">
              No results found
            </div>
          </div>
        )}
      </div>
    </>
  );
});

export const AddHGObjectToMapSidebar = (props: {
  onClosePanel: () => void;
}) => {
  return <AddHGObjectToMapSidebarContent onCancel={props.onClosePanel} />;
};
