import { TooltipProps } from "@mui/material";
import React from "react";
import { TooltipSolid } from "./TooltipSolid";

interface ToolbarButtonProps {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  isActive?: boolean;
  children?: React.ReactNode;
  tooltipTitle?: string;
  tooltipPlacement?: TooltipProps["placement"];
  id?: string;
}

type ToolbarIconButtonProps = ToolbarButtonProps & { iconSize?: number };

export const ToolbarIconButton = React.forwardRef<
  HTMLButtonElement,
  ToolbarIconButtonProps
>((props, ref) => {
  const {
    isActive,
    children,
    onClick,
    tooltipTitle,
    tooltipPlacement,
    iconSize,
    id,
    disabled,
  } = props;

  let _iconSize = iconSize ? iconSize : 24;

  return (
    <TooltipSolid
      arrow
      placement={tooltipPlacement ?? "right"}
      enterDelay={300}
      title={tooltipTitle ? tooltipTitle : ""}
    >
      <div className="relative">
        <button
          ref={ref}
          disabled={disabled}
          type="button"
          onClick={onClick}
          className={`flex items-center justify-center w-[40px] h-[40px] transition-all enabled:hover:text-purple-500 enabled:active:bg-purple-100 disabled:opacity-30 ${
            isActive ? "text-purple-500" : "text-slate-900"
          } pointer-events-auto`}
          style={{
            width: 40,
            height: 40,
          }}
          id={id}
        >
          <div
            className="flex items-center justify-center"
            style={{
              width: _iconSize,
              height: _iconSize,
            }}
          >
            {children}
          </div>
        </button>
        {disabled && (
          <div className="absolute inset-0 pointer-events-auto"></div>
        )}
      </div>
    </TooltipSolid>
  );
});

type ToolbarTextButtonProps = ToolbarButtonProps & {
  label: string;
  textSizeClass?: "text-sm" | "text-base";
};

export const ToolbarTextButton = (props: ToolbarTextButtonProps) => {
  const {
    isActive,
    children,
    onClick,
    tooltipTitle,
    id,
    label,
    textSizeClass = "text-sm",
    disabled,
  } = props;

  return (
    <TooltipSolid
      arrow
      placement="right"
      title={tooltipTitle ? tooltipTitle : ""}
    >
      <button
        disabled={disabled}
        type="button"
        onClick={onClick}
        className={`user-select-none flex items-center justify-center h-[40px] transition-all hover:text-purple-500 active:bg-purple-100 ${
          isActive ? "text-purple-500" : "text-slate-900"
        }`}
        id={id}
      >
        <div className="flex items-center justify-center space-x-2 px-3">
          <span className={`${textSizeClass} font-normal`}>{label}</span>
          {children}
        </div>
      </button>
    </TooltipSolid>
  );
};
