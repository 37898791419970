import { CardShape, TDDocument } from "@orgcharthub/tldraw-tldraw";
import _ from "lodash";
import { computed, comparer } from "mobx";
import { app } from "./store/store";
import * as domain from "./domain";
import { computedFn } from "mobx-utils";

export const canonicalObjectIdsOnMap = computed(
  () => {
    // read document update counter so we always re-calculate on document change
    app.store.documentForceUpdate;
    app.store.documentNotifyUpdate;

    const cardShapes: CardShape[] = _.filter(
      (app.tlApp.document as TDDocument).pages.page_1.shapes,
      domain.isCardShape,
    );

    const canonicalIds = cardShapes
      .map((shape) => shape.meta)
      .filter(domain.isCardShapeMetaHubSpot)
      .map((shapeMeta) => {
        return domain.canonicalIdForHGObjectRef({
          objectType: shapeMeta.objectType,
          objectId: shapeMeta.objectId,
        });
      });

    return canonicalIds.reduce((acc, id) => {
      acc[id] = true;
      return acc;
    }, {} as Record<string, true>);
  },
  {
    equals: comparer.structural,
  },
);

export const hubspotProperties = computedFn(
  (objectType: string) => {
    const allProperties = Object.values(app.store.hgProperties);
    return allProperties.filter(
      (property) => property.objectType === objectType,
    );
  },
  {
    equals: comparer.structural,
  },
);

export const hubspotPropertyGroups = computedFn(
  (objectType: string) => {
    const allPropertyGroups = Object.values(app.store.hgPropertyGroups);
    return allPropertyGroups.filter(
      (propertyGroup) => propertyGroup.objectType === objectType,
    );
  },
  {
    equals: comparer.structural,
  },
);

export const portalDisplayProperties = computedFn(
  (objectType: string) => {
    return (app.store.portal?.["hg-display-properties"] || []).filter(
      (displayProperty) => displayProperty.objectType === objectType,
    );
  },
  {
    equals: comparer.structural,
  },
);

export const portalDisplayPropertiesOnCard = computedFn(
  (objectType: string) => {
    return portalDisplayProperties(objectType).filter(
      (displayProperty) => displayProperty.showOnCard === true,
    );
  },
  {
    equals: comparer.structural,
  },
);

export const labelForObjectType = computedFn<
  (objectType: string, pluralised: boolean) => string
>(
  (objectType: string, pluralised: boolean) => {
    switch (objectType) {
      case "company": {
        return pluralised ? "Companies" : "Company";
      }
      case "contact": {
        return pluralised ? "Contacts" : "Contact";
      }
      case "deal": {
        return pluralised ? "Deals" : "Deal";
      }
      case "note": {
        return pluralised ? "Notes" : "Note";
      }
    }

    const schema = app.store.hgObjectSchemas[objectType];

    if (!schema) {
      return "<unknown>";
    }

    return pluralised ? schema.labels.plural : schema.labels.singular;
  },
  {
    equals: comparer.structural,
  },
);

export const displayLabelForObjectTypePlural = (objectType: string): string => {
  return labelForObjectType(objectType, true);
};

export const displayLabelForObjectTypeSingular = (
  objectType: string,
): string => {
  return labelForObjectType(objectType, false);
};

export const supportedObjectTypes = computed<string[]>(
  () => {
    return domain.calculateSupportedObjectTypes(
      Object.values(app.store.hgObjectSchemas),
    );
  },
  {
    equals: comparer.structural,
  },
);

export const propertiesToFetchForObjectType = computedFn<
  (objectType: string) => string[]
>(
  (objectType) => {
    const hgObjectSchemas = Object.values(app.store.hgObjectSchemas);
    const displayProperties = Object.values(
      app.store.portal?.["hg-display-properties"] || [],
    );
    const propertiesToFetch = domain.calculatePropertiesToFetchForObjectType({
      hgObjectSchemas,
      displayProperties,
      objectType,
    });
    return propertiesToFetch;
  },
  { equals: comparer.structural },
);
