import React, { useCallback, useState } from "react";
import {
  Undo as UndoIcon,
  Redo as RedoIcon,
  PersonAdd as PersonAddIcon,
} from "@mui/icons-material";
import { EraserIcon, LineIcon } from "./primitives";
import { Popover } from "@mui/material";
import { useStore } from "../hooks/hooks";
import {
  ColorStyle,
  DashStyle,
  ShapeStyles,
  SizeStyle,
  TDShapeType,
  TDSnapshot,
  TDToolType,
} from "@orgcharthub/tldraw-tldraw";
import { CursorIcon } from "./primitives/CursorIcon";
import { RectangleIcon } from "./primitives/RectangeIcon";
import { PencilIcon } from "./primitives/PencilIcon";
import { ArrowIcon } from "./primitives/ArrowIcon";
import { EllipseIcon } from "./primitives/EllipseIcon";
import { TriangleIcon } from "./primitives/TriangleIcon";
import { TextIcon } from "./primitives/TextIcon";
import { StickyNoteIcon } from "./primitives/StickyNoteIcon";
import * as actions from "../actions";
import { ToolbarIconButton } from "./ToolbarButton";
import { PaintbrushIcon } from "./primitives/PaintbrushIcon";
import { DashDrawIcon } from "./primitives/DashDrawIcon";
import { DashDottedIcon } from "./primitives/DashDottedIcon";
import { DashDashedIcon } from "./primitives/DashDashedIcon";
import { DashSolidIcon } from "./primitives/DashSolidIcon";
import { UploadIcon } from "./primitives/UploadIcon";
import { observer } from "mobx-react-lite";
import { useTLApp } from "../hooks/hooks";

const colors: Readonly<Record<ColorStyle, string>> = {
  [ColorStyle.White]: "#f0f1f3",
  [ColorStyle.LightGray]: "#c6cbd1",
  [ColorStyle.Gray]: "#788492",
  [ColorStyle.Black]: "#1d1d1d",
  [ColorStyle.Green]: "#36b24d",
  [ColorStyle.Cyan]: "#0e98ad",
  [ColorStyle.Blue]: "#1c7ed6",
  [ColorStyle.Indigo]: "#4263eb",
  [ColorStyle.Violet]: "#7746f1",
  [ColorStyle.Red]: "#ff2133",
  [ColorStyle.Orange]: "#ff9433",
  [ColorStyle.Yellow]: "#ffc936",
};

const DASH_ICONS = {
  [DashStyle.Draw]: <DashDrawIcon />,
  [DashStyle.Solid]: <DashSolidIcon />,
  [DashStyle.Dashed]: <DashDashedIcon />,
  [DashStyle.Dotted]: <DashDottedIcon />,
} as const;

const SIZE_LETTERS = {
  [SizeStyle.Small]: "S",
  [SizeStyle.Medium]: "M",
  [SizeStyle.Large]: "L",
} as const;

const activeToolSelector = (s: TDSnapshot) => s.appState.activeTool;
const currentStyleSelector = (s: TDSnapshot) => s.appState.currentStyle;
const selectedIdsSelector = (s: TDSnapshot) =>
  s.document.pageStates[s.appState.currentPageId].selectedIds;

const STYLE_KEYS = [
  "color",
  "size",
  "dash",
  "font",
  "textAlign",
  "isFilled",
  "scale",
] as (keyof ShapeStyles)[];

const StylesPopup = () => {
  const tlApp = useTLApp();

  const currentStyle = tlApp.useStore(currentStyleSelector);
  const selectedIds = tlApp.useStore(selectedIdsSelector);

  const [displayedStyle, setDisplayedStyle] = React.useState(currentStyle);

  const rDisplayedStyle = React.useRef(currentStyle);
  React.useEffect(() => {
    const {
      appState: { currentStyle },
      page,
      selectedIds,
    } = tlApp;
    let commonStyle = {} as ShapeStyles;
    if (selectedIds.length <= 0) {
      commonStyle = currentStyle;
    } else {
      const overrides = new Set<string>([]);
      tlApp.selectedIds
        .map((id) => page.shapes[id])
        .forEach((shape) => {
          STYLE_KEYS.forEach((key) => {
            if (overrides.has(key)) return;
            if (commonStyle[key] === undefined) {
              // @ts-ignore
              commonStyle[key] = shape.style[key];
            } else {
              if (commonStyle[key] === shape.style[key]) return;
              // @ts-ignore
              commonStyle[key] = shape.style[key];
              overrides.add(key);
            }
          });
        });
    }
    // Until we can work out the correct logic for deciding whether or not to
    // update the selected style, do a string comparison. Yuck!
    if (
      JSON.stringify(commonStyle) !== JSON.stringify(rDisplayedStyle.current)
    ) {
      rDisplayedStyle.current = commonStyle;
      setDisplayedStyle(commonStyle);
    }
  }, [currentStyle, selectedIds]);

  return (
    <div className="flex flex-col">
      <div className="divide-y-2 divide-slate-200">
        <div className="flex flex-row p-2">
          <div className="text-sm text-slate-700 font-medium leading-none py-2 px-2 mr-4 mt-1.5">
            Color
          </div>
          <div className="grid gap-y-0.5 grid-cols-4 grid-rows-3">
            {Object.keys(colors).map((colorStyle) => {
              const _colorStyle = colorStyle as ColorStyle;
              const hex = colors[_colorStyle];
              const isActive = displayedStyle.color === _colorStyle;
              return (
                <button
                  key={_colorStyle}
                  style={{ height: 40, width: 40 }}
                  className={`flex items-center justify-center hover:bg-slate-200 active:bg-slate-300 rounded-lg transition-all ${
                    isActive ? "bg-slate-200" : ""
                  }`}
                  onClick={() => {
                    tlApp.style({ color: _colorStyle });
                  }}
                >
                  <div
                    className="rounded-full"
                    style={{ height: 24, width: 24, backgroundColor: hex }}
                  ></div>
                </button>
              );
            })}
          </div>
        </div>

        <div className="flex flex-row items-center justify-center space-between p-2">
          <div className="text-sm text-slate-700 font-medium leading-none py-2 px-2 mr-4">
            Dash
          </div>

          <div className="grid gap-x-0.5 grid-cols-4 grid-rows-1">
            {Object.values(DashStyle).map((style) => {
              const icon = DASH_ICONS[style];
              const isActive = style === displayedStyle.dash;
              return (
                <button
                  key={style}
                  className={`flex items-center justify-center hover:bg-slate-200 active:bg-slate-300 rounded-lg transition-all ${
                    isActive ? "bg-slate-200" : ""
                  }`}
                  style={{ height: 40, width: 40 }}
                  onClick={(e) => {
                    tlApp.style({ dash: style });
                  }}
                >
                  {icon}
                </button>
              );
            })}
          </div>
        </div>

        <div className="flex flex-row items-center space-between p-2">
          <div className="text-sm text-slate-700 font-medium leading-none py-2 px-2 flex-1 mr-6">
            Size
          </div>

          <div className="grid gap-x-0.5 grid-cols-3 grid-rows-1">
            {Object.values(SizeStyle).map((style) => {
              const letter = SIZE_LETTERS[style];
              const isActive = style === displayedStyle.size;
              return (
                <button
                  key={style}
                  className={`flex items-center justify-center hover:bg-slate-200 active:bg-slate-300 rounded-lg transition-all text-base font-semibold ${
                    isActive ? "bg-slate-200" : ""
                  }`}
                  style={{ height: 40, width: 40 }}
                  onClick={(e) => {
                    tlApp.style({ size: style });
                  }}
                >
                  {letter}
                </button>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export const DrawingToolbar = () => {
  const tlApp = useTLApp();

  const activeTool = tlApp.useStore(activeToolSelector);

  const [stylesMenuAnchorEl, setStylesMenuAnchorEl] =
    useState<HTMLElement | null>(null);

  const selectSelectTool = React.useCallback(() => {
    tlApp.selectTool("select");
  }, [tlApp]);

  const selectEraseTool = React.useCallback(() => {
    tlApp.selectTool("erase");
  }, [tlApp]);

  const selectDrawTool = React.useCallback(() => {
    tlApp.selectTool(TDShapeType.Draw);
  }, [tlApp]);

  const selectArrowTool = React.useCallback(() => {
    tlApp.selectTool(TDShapeType.Arrow);
  }, [tlApp]);

  const selectTextTool = React.useCallback(() => {
    tlApp.selectTool(TDShapeType.Text);
  }, [tlApp]);

  const selectStickyTool = React.useCallback(() => {
    tlApp.selectTool(TDShapeType.Sticky);
  }, [tlApp]);

  const selectShapeTool = React.useCallback((toolType: TDToolType) => {
    console.log("select toolType", toolType);
    tlApp.selectTool(toolType);
  }, []);

  const uploadAssets = React.useCallback(async () => {
    tlApp.openAsset();
  }, [tlApp])

  return (
    <div className="flex flex-col bg-white shadow-vlg rounded-md items-center">
      <ToolbarIconButton
        tooltipTitle="Select"
        onClick={selectSelectTool}
        isActive={activeTool === "select"}
        iconSize={20}
      >
        <CursorIcon />
      </ToolbarIconButton>
      <ToolbarIconButton
        tooltipTitle="Draw"
        onClick={selectDrawTool}
        isActive={activeTool === TDShapeType.Draw}
        iconSize={20}
      >
        <PencilIcon />
      </ToolbarIconButton>
      <ToolbarIconButton
        tooltipTitle="Erase"
        onClick={selectEraseTool}
        isActive={activeTool === "erase"}
      >
        <EraserIcon />
      </ToolbarIconButton>
      <ToolbarIconButton
        tooltipTitle="Arrow"
        onClick={selectArrowTool}
        isActive={activeTool === TDShapeType.Arrow}
      >
        <ArrowIcon />
      </ToolbarIconButton>
      <ToolbarIconButton
        tooltipTitle="Rectangle"
        onClick={() => {
          selectShapeTool(TDShapeType.Rectangle);
        }}
        isActive={activeTool === TDShapeType.Rectangle}
      >
        <RectangleIcon />
      </ToolbarIconButton>
      <ToolbarIconButton
        tooltipTitle="Ellipse"
        onClick={() => {
          selectShapeTool(TDShapeType.Ellipse);
        }}
        isActive={activeTool === TDShapeType.Ellipse}
      >
        <EllipseIcon />
      </ToolbarIconButton>
      <ToolbarIconButton
        tooltipTitle="Line"
        onClick={() => {
          selectShapeTool(TDShapeType.Line);
        }}
        isActive={activeTool === TDShapeType.Line}
      >
        <LineIcon />
      </ToolbarIconButton>
      <ToolbarIconButton
        tooltipTitle="Triangle"
        onClick={() => {
          selectShapeTool(TDShapeType.Triangle);
        }}
        isActive={activeTool === TDShapeType.Triangle}
      >
        <TriangleIcon />
      </ToolbarIconButton>
      <ToolbarIconButton
        tooltipTitle="Text"
        onClick={() => {
          selectShapeTool(TDShapeType.Text);
        }}
        isActive={activeTool === TDShapeType.Text}
      >
        <TextIcon />
      </ToolbarIconButton>
      <ToolbarIconButton
        tooltipTitle="Sticky"
        onClick={selectStickyTool}
        isActive={activeTool === TDShapeType.Sticky}
      >
        <StickyNoteIcon />
      </ToolbarIconButton>
      <ToolbarIconButton
        tooltipTitle="Image / Video"
        onClick={uploadAssets}
      >
        <UploadIcon />
      </ToolbarIconButton>
      <ToolbarIconButton
        tooltipTitle="Styles"
        onClick={(e) => {
          setStylesMenuAnchorEl(e.currentTarget);
        }}
        isActive={!!stylesMenuAnchorEl}
      >
        <PaintbrushIcon />
      </ToolbarIconButton>
      <Popover
        anchorEl={stylesMenuAnchorEl}
        open={!!stylesMenuAnchorEl}
        onClose={() => {
          setStylesMenuAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        elevation={4}
      >
        <StylesPopup />
      </Popover>
    </div>
  );
};

export const UndoToolbar = observer(() => {
  const store = useStore();
  const tlApp = useTLApp();

  const canUndo = !!store.currentRelationshipMap.canUndo;
  const canRedo = !!store.currentRelationshipMap.canRedo;

  return (
    <div className="flex flex-col bg-white shadow-vlg rounded-lg items-center">
      <ToolbarIconButton
        disabled={!canUndo}
        tooltipTitle="Undo"
        onClick={tlApp.undo}
      >
        <UndoIcon />
      </ToolbarIconButton>
      <ToolbarIconButton
        disabled={!canRedo}
        tooltipTitle="Redo"
        onClick={tlApp.redo}
      >
        <RedoIcon />
      </ToolbarIconButton>
    </div>
  );
});

export const HSObjectToolbar = () => {
  const tlApp = useTLApp();

  const activeTool = tlApp.useStore(activeToolSelector);

  const startAddingObjectToChart = useCallback(() => {
    actions.startAddingObjectToChart();
  }, []);

  const addNoteToChart = useCallback(() => {
    actions.addLocalNote();
  }, []);

  const selectShapeTool = React.useCallback((toolType: TDToolType) => {
    tlApp.selectTool(toolType);
    tlApp.toggleToolLock();
  }, []);

  return (
    <div className="flex flex-col bg-white shadow-vlg rounded-lg items-center">
      <ToolbarIconButton
        tooltipTitle="Add to map"
        onClick={startAddingObjectToChart}
      >
        <PersonAddIcon />
      </ToolbarIconButton>
      <ToolbarIconButton
        tooltipTitle="Add associations"
        onClick={() => {
          selectShapeTool(TDShapeType.Connect);
        }}
        isActive={activeTool === TDShapeType.Connect}
      >
        <LineIcon />
      </ToolbarIconButton>
      {/* <ToolbarIconButton tooltipTitle="Add note" onClick={addNoteToChart}>
        <StickyNoteIcon />
      </ToolbarIconButton> */}
    </div>
  );
};

export const Toolbar = () => {
  return (
    <div className="h-full flex flex-col justify-center">
      <div className="h-[55px] shrink-0"></div>
      <div className="flex-1 flex flex-col overflow-y-scroll pointer-events-auto overflow-x-visible pointer-events-none">
        <div className="space-y-4 m-auto">
          <HSObjectToolbar />
          <DrawingToolbar />
          <UndoToolbar />
          <div className="h-[55px] shrink-0"></div>
        </div>
      </div>
    </div>
  );
};
