import React from "react";
import { Menu as MenuIcon } from "@mui/icons-material";
import { observer } from "mobx-react-lite";
import { useStore } from "../hooks/hooks";
import {
  canonicalIdForHGObjectRef,
  HSPipeline,
  HSPipelineStage,
  isClosedLostDealStage,
} from "../domain";
import {
  formatValueForDisplay,
  resolvePropertyValue,
} from "./PropertyComponents/helpers";
import * as db from "../store/db";
import _ from "lodash";
import chroma from "chroma-js";
import { assert } from "../utils";

const PipelineStageProgressIndicator = (props: {
  hsPipeline: HSPipeline;
  stageId: string;
}) => {
  const { hsPipeline, stageId } = props;

  const notClosedLostStages = hsPipeline.stages.filter(
    (stage) => !isClosedLostDealStage(stage),
  ).length;

  const closedLostColor = "#516F90";
  const scale = chroma
    .scale(["#FB9187", "#B475B4"])
    .mode("lch")
    .colors(notClosedLostStages);

  const sortedStages = _.sortBy(
    hsPipeline.stages,
    (stage) => stage.displayOrder,
  );

  const stageIndex = sortedStages.findIndex((stage) => stage.id === stageId);

  const currentStage = hsPipeline.stages.find((stage) => stage.id === stageId);
  assert(currentStage, "stageId must be in given pipeline");
  const isClosedLost = isClosedLostDealStage(currentStage);

  return (
    <div className="flex flex-row space-x-1">
      {sortedStages.map((stage, i) => {
        const isFilled = isClosedLost
          ? stage === currentStage
          : i <= stageIndex;
        const filledColor = isFilled
          ? isClosedLost
            ? closedLostColor
            : scale[i]
          : "#EAF0F5";
        return (
          <div
            style={{
              backgroundColor: filledColor,
            }}
            className={`rounded-full flex-1 h-[5px]`}
          ></div>
        );
      })}
    </div>
  );
};

const DealMapPipelineSection = observer(() => {
  const store = useStore();

  const dealObjectRef = store.initialObject;

  const canonicalId = canonicalIdForHGObjectRef(dealObjectRef);

  const dealHGObject = store.hgObjects[canonicalId];

  const pipeline = dealHGObject?.properties?.pipeline;
  const dealstage = dealHGObject?.properties?.dealstage;
  // const dealstage = "1658913";
  // const dealstage = "1658915";
  // const dealstage = "1658916";

  const pipelineProperty = db.getHSProperty(store, {
    objectType: "deal",
    name: "pipeline",
  });
  const dealstageProperty = db.getHSProperty(store, {
    objectType: "deal",
    name: "dealstage",
  });

  let resolvedPipeline: string | undefined;
  let resolvedDealStage: string | undefined;
  if (
    typeof pipeline === "string" &&
    typeof dealstage === "string" &&
    pipelineProperty &&
    dealstageProperty
  ) {
    resolvedPipeline = resolvePropertyValue({
      hgObject: dealHGObject,
      hsProperty: pipelineProperty,
      store,
      value: pipeline,
    });
    resolvedDealStage = resolvePropertyValue({
      hgObject: dealHGObject,
      hsProperty: dealstageProperty,
      store,
      value: dealstage,
    });
  }

  let hsPipeline: HSPipeline | undefined;
  if (pipeline) {
    hsPipeline = db.getHSPipeline(store, {
      pipelineId: pipeline,
    });
  }

  let hsDealStage: HSPipelineStage | undefined;
  if (pipeline && dealstage) {
    hsDealStage = db.getHSDealStage(store, {
      pipelineId: pipeline,
      dealstageId: dealstage,
    });
  }

  const canRenderPipelineInformation = resolvedPipeline && resolvedDealStage;

  if (!canRenderPipelineInformation) {
    return null;
  }

  return (
    <div className="flex-1 grid grid-rows-2 gap-1 p-3">
      <div className="flex-1 flex flex-col justify-center">
        <span className="text-sm text-slate-900">
          {resolvedPipeline} / {resolvedDealStage}
        </span>
      </div>

      <div className="flex-1 flex flex-col justify-center">
        {hsPipeline && dealstage && (
          <PipelineStageProgressIndicator
            hsPipeline={hsPipeline}
            stageId={dealstage}
          />
        )}
      </div>
    </div>
  );
});

const DealMapTitleSection = observer(() => {
  const store = useStore();

  const dealObjectRef = store.initialObject;

  const canonicalId = canonicalIdForHGObjectRef(dealObjectRef);

  const dealHGObject = store.hgObjects[canonicalId];

  if (!dealHGObject) {
    return null;
  }

  const dealname = dealHGObject.properties?.dealname;
  const amount = dealHGObject.properties?.amount;

  const amountProperty = db.getHSProperty(store, {
    objectType: "deal",
    name: "amount",
  });

  const canRenderDealName =
    typeof dealname === "string" && !_.isEmpty(dealname);
  const canRenderAmount =
    typeof amount === "string" && !_.isEmpty(amount) && amountProperty;

  if (!canRenderDealName && !canRenderAmount) {
    return null;
  }

  let formattedAmount: string | undefined;
  if (canRenderAmount) {
    formattedAmount = formatValueForDisplay({
      hgAccountDetails: db.getAccountDetails(store),
      hgObject: dealHGObject,
      hsProperty: amountProperty,
      value: amount,
    });
  }

  return (
    <div className="flex-1 grid grid-rows-2 gap-1 p-3">
      <div className="flex-1 flex flex-col justify-center">
        <h1 className="text-sm text-slate-900">
          {dealname || `Deal Record ID: ${dealObjectRef.objectId}`}
        </h1>
      </div>
      <div className="flex-1 flex flex-col justify-center">
        <span className="text-sm text-slate-900">{formattedAmount}</span>
      </div>
    </div>
  );
});

export const RelationshipMapSwitcherToolbar = observer(() => {
  return (
    <div className="bg-white shadow-vlg rounded-md flex flex-row pointer-events-auto divide-x divide-slate-200">
      <div className="flex flex-row divide-x-2">
        <div className="flex flex-col items-center justify-center">
          <button
            onClick={() => undefined}
            className="flex flex-col items-center justify-center hover:text-purple-500 transition-all active:bg-purple-100 h-[40px] w-[40px] "
          >
            <MenuIcon></MenuIcon>
          </button>
        </div>
      </div>

      <DealMapTitleSection />
      <DealMapPipelineSection />
    </div>
  );
});
