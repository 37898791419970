import type { HGObjectRef } from "../domain";
import * as hubspot from "../api/hubspot-api";
import * as firebaseApi from "../api/firebase";
import * as domain from "../domain";
import { HSPropertyGroup, HSProperty } from "../domain/property";
import _ from "lodash";
import { withLocalDevCache } from "../utils";
import * as connection from "../domain/connection";
import {
  makeNewDealMapDocument,
  makeShapeForHSObject,
} from "../domain/document";
import * as concentric from "../domain/layout/concentric";
import { shapeByObjectCanonicalId } from "../domain/canvas";
import { TDDocument } from "@orgcharthub/tldraw-tldraw";
import { DEV_CACHE_ENABLED } from "../config";

export async function createInitialDealMapWithConnectedContacts(params: {
  authState: domain.AuthState;
  dealObjectRef: HGObjectRef;
  unlabelledDealContactLabelPair: connection.HGLabelPair;
}): Promise<{
  doc: TDDocument;
  hgObjects: domain.HGObject[];
  hgConnections: connection.HGConnection[];
}> {
  const { dealObjectRef, authState, unlabelledDealContactLabelPair } = params;

  const doc = makeNewDealMapDocument({
    id: dealObjectRef.objectId,
    dealObjectRef,
  });

  console.log("base doc", doc);

  const allAssociatedContactIds =
    await hubspot.fetchContactIdsAssociatedWithDeal({
      authState,
      dealId: dealObjectRef.objectId,
    });

  // only consider up to associated contacts for now
  const associatedContactIds = allAssociatedContactIds.slice(0, 10);

  const dealTypeId =
    unlabelledDealContactLabelPair.hgLabels.hgLabelA.objectType === "deal"
      ? unlabelledDealContactLabelPair.hgLabels.hgLabelA.typeId
      : unlabelledDealContactLabelPair.hgLabels.hgLabelB.typeId;
  const contactTypeId =
    unlabelledDealContactLabelPair.hgLabels.hgLabelA.objectType === "contact"
      ? unlabelledDealContactLabelPair.hgLabels.hgLabelA.typeId
      : unlabelledDealContactLabelPair.hgLabels.hgLabelB.typeId;

  const dealToContactHGConnections: connection.HGConnection[] =
    associatedContactIds.map((id) => {
      const objectRefB: HGObjectRef = {
        objectType: "contact",
        objectId: id,
      };
      const canonicalId = connection.canonicalIdForConnection({
        objectRefA: dealObjectRef,
        objectRefB,
      });
      const hgConnection: connection.HGConnection = {
        appliedLabelPairs: [
          {
            labelPairCanonicalId: unlabelledDealContactLabelPair.canonicalId,
            objectATypeId: dealTypeId,
            objectBTypeId: contactTypeId,
          },
        ],
        canonicalId: canonicalId,
        objectRefA: dealObjectRef,
        objectRefB: objectRefB,
      };
      return hgConnection;
    });

  const contactHGObjects = associatedContactIds.map((id) => {
    const hgObject: domain.HGObject = {
      canonicalId: domain.canonicalIdForHGObjectRef({
        objectId: id,
        objectType: "contact",
      }),
      objectId: id,
      objectType: "contact",
      isFetched: false,
    };
    return hgObject;
  });

  console.log("deal to contact connections", dealToContactHGConnections);

  const layoutResult = await concentric.layout({
    hgConnections: dealToContactHGConnections,
  });

  console.log("layout result", layoutResult);

  // add in the shapes for the contact objects
  doc.pages.page_1.shapes = {
    ...doc.pages.page_1.shapes,
    ...Object.fromEntries(
      associatedContactIds.map((id) => {
        const shape = makeShapeForHSObject({
          childIndex: 0,
          hgObjectRef: {
            objectType: "contact",
            objectId: id,
          },
          point: [0, 0],
        });
        return [shape.id, shape];
      }),
    ),
  };

  for (const [canonicalId, boundingRect] of Object.entries(layoutResult)) {
    const shape = shapeByObjectCanonicalId(
      Object.values(doc.pages.page_1.shapes),
      canonicalId,
    );
    if (!shape) {
      continue;
    }
    doc.pages.page_1.shapes[shape.id].point = [boundingRect.x, boundingRect.y];
  }

  console.log("patched doc", doc);

  return {
    doc,
    hgConnections: dealToContactHGConnections,
    hgObjects: contactHGObjects,
  };
}

export async function fetchObjectsConnections(params: {
  authState: domain.AuthState;
  objectRefs: HGObjectRef[];
  hgSchemas: domain.HGObjectSchema[];
  existingHGLabelPairs: connection.HGLabelPair[];
  propertiesToFetchForObjectTypes: Record<string, string[]>;
}): Promise<{
  connections: connection.HGConnection[];
  objects: domain.HGObject[];
}> {
  console.log("use-cases/fetchObjectsConnections", params);

  const {
    authState,
    objectRefs,
    existingHGLabelPairs,
    hgSchemas,
    propertiesToFetchForObjectTypes,
  } = params;

  console.log("existingHGLabelPairs", existingHGLabelPairs);

  let level0FetchedHGObjects: domain.HGObject[] = [];
  let objectRefsToFetch: HGObjectRef[] = [];
  let allConnections: connection.HGConnection[] = [];

  // TODO: these requests can be made in parallel?
  for (const objectRef of objectRefs) {
    const { connections, level1ObjectRefs, level0Object } =
      await hubspot.fetchConnectionsForLevel0ObjectRef({
        authState,
        level0ObjectRef: objectRef,
        hgSchemas,
        hgLabelPairs: existingHGLabelPairs,
        propertiesToFetchForObjectTypes,
      });

    allConnections = [...allConnections, ...connections];
    objectRefsToFetch = [...objectRefsToFetch, objectRef, ...level1ObjectRefs];
    if (level0Object) {
      level0FetchedHGObjects.push(level0Object);
    }
  }

  const fetchedCanonicalIds = level0FetchedHGObjects.map(
    (hgObject) => hgObject.canonicalId,
  );
  const uniqueObjectRefsToFetch = _.chain(objectRefsToFetch)
    .uniqBy(domain.canonicalIdForHGObjectRef)
    .filter((objectRef) => {
      const id = domain.canonicalIdForHGObjectRef(objectRef);
      return !fetchedCanonicalIds.includes(id);
    })
    .value();

  const hgObjects = await hubspot.fetchObjects({
    authState,
    objectRefs: uniqueObjectRefsToFetch,
    propertiesToFetchForObjectTypes,
  });

  const result = {
    objects: [...level0FetchedHGObjects, ...hgObjects],
    connections: allConnections,
  };

  console.log("use-cases/fetchObjectsConnections", { params, result });

  return result;
}

interface DependencyEventAccountDetailsFetched {
  type: "DependencyEventAccountDetailsFetched";
  accountDetails: domain.HGAccountDetails;
}

interface DependencyEventSchemasDiscovered {
  type: "DependencyEventSchemasDiscovered";
  customObjectsSupported: boolean;
  schemas: domain.HGObjectSchema[];
}

interface DependencyEventHGLabelPairsFetched {
  type: "DependencyEventHGLabelPairsFetched";
  hgLabelPairs: connection.HGLabelPair[];
  fromRemote: true;
}

interface DependencyEventPropertyGroupsFetched {
  type: "DependencyEventPropertyGroupsFetched";
  propertyGroups: HSPropertyGroup[];
  fromRemote: boolean;
}

interface DependencyEventPropertiesFetched {
  type: "DependencyEventPropertiesFetched";
  properties: HSProperty[];
  fromRemote: boolean;
}

type DependencyEvent =
  | DependencyEventAccountDetailsFetched
  | DependencyEventSchemasDiscovered
  | DependencyEventHGLabelPairsFetched
  | DependencyEventPropertyGroupsFetched
  | DependencyEventPropertiesFetched;

export async function ensureHubSpotDependencies(params: {
  portalId: string;
  accessToken: string;
  onDependency: (event: DependencyEvent) => void;
}) {
  const { portalId, accessToken, onDependency } = params;

  // fetch account details so we can determine uiDomain, time zone
  // settings and currency settings
  const { accountDetails } = await withLocalDevCache(
    "accountDetails",
    async () => {
      const accountDetails = await hubspot.fetchAccountDetails({
        authState: { accessToken },
      });
      return { accountDetails };
    },
  );

  onDependency({
    type: "DependencyEventAccountDetailsFetched",
    accountDetails,
  });

  // figure out which schemas we can support
  console.log("discovering supported object schemas...");
  const { schemas, customObjectsSupported } = await withLocalDevCache(
    "supportedSchemas",
    async () => {
      return await hubspot.discoverSupportedSchemas({
        authState: { accessToken },
      });
    },
  );

  onDependency({
    type: "DependencyEventSchemasDiscovered",
    customObjectsSupported: customObjectsSupported,
    schemas,
  });

  const supportedObjectTypes = domain.calculateSupportedObjectTypes(schemas);

  console.log("supportedObjectTypes", supportedObjectTypes);

  console.log("fetching label pairs...");
  const hgLabelPairs = await withLocalDevCache("hgLabelPairs", async () => {
    const hsAssociationLabels = await hubspot.fetchHubSpotAssociationLabels({
      objectTypes: supportedObjectTypes,
      authState: { accessToken },
    });
    const hgLabels =
      connection.makeHGLabelsFromHSAssociationLabels(hsAssociationLabels);
    const labelPairs = connection.makeHGLabelPairsFromHSAssociationLabels({
      hgLabels,
    });
    return labelPairs;
  });

  onDependency({
    type: "DependencyEventHGLabelPairsFetched",
    hgLabelPairs,
    fromRemote: true,
  });

  console.log("fetching/loading hubspot properties and property groups...");

  const localStorageKey = `${portalId}-initialHubSpotDependencies`;
  const fromLocalStorage = localStorage.getItem(localStorageKey);

  interface StorableHubSpotDependencies {
    properties: HSProperty[];
    propertyGroups: HSPropertyGroup[];
  }

  function storeHubSpotInitialDependencies(
    dependencies: StorableHubSpotDependencies,
  ) {
    localStorage.setItem(
      localStorageKey,
      JSON.stringify(dependencies, null, 2),
    );
  }

  async function refreshHubSpotInitialDependencies(): Promise<StorableHubSpotDependencies> {
    const propertyGroups = await hubspot.fetchAllPropertyGroupDefinitions({
      objectTypes: supportedObjectTypes,
      authState: { accessToken },
    });
    const properties = await hubspot.fetchAllPropertyDefinitions({
      objectTypes: supportedObjectTypes,
      authState: { accessToken },
    });

    const result = { propertyGroups, properties };

    return result;
  }

  function fireDependencyEvents(
    dependencies: StorableHubSpotDependencies,
    fromRemote: boolean,
  ) {
    onDependency({
      type: "DependencyEventPropertiesFetched",
      properties: dependencies.properties,
      fromRemote: fromRemote,
    });

    onDependency({
      type: "DependencyEventPropertyGroupsFetched",
      propertyGroups: dependencies.propertyGroups,
      fromRemote: fromRemote,
    });
  }

  if (fromLocalStorage) {
    console.log(
      "loading hubspot dependencies from storage and refreshing in the background...",
    );
    const localDependencies = JSON.parse(
      fromLocalStorage,
    ) as Awaited<StorableHubSpotDependencies>;
    fireDependencyEvents(localDependencies, false);

    try {
      const remoteDependencies = await refreshHubSpotInitialDependencies();
      fireDependencyEvents(remoteDependencies, true);
      storeHubSpotInitialDependencies(remoteDependencies);
    } catch (e) {
      console.error(e);
    }
  } else {
    console.log(
      "no hubspot dependencies in local storage, waiting for them...",
    );
    const remoteDependencies = await refreshHubSpotInitialDependencies();
    fireDependencyEvents(remoteDependencies, true);
    storeHubSpotInitialDependencies(remoteDependencies);
  }
}

export async function maybeAddDefaultDisplayProperties(params: {
  portalId: string;
}): Promise<void> {
  return await firebaseApi.addDefaultDisplayPropertiesIfRequired(params);
}
