import {
  CardShape,
  CardShapeMetaHubSpot,
  CardShapeMetaHubGraph,
  TDShape,
  TDShapeType,
} from "@orgcharthub/tldraw-tldraw";
import * as ts from "io-ts";
import _ from "lodash";
import firebase from "firebase/app";
import { DateTime } from "luxon";
import shortid from "shortid";
import * as TDDraw from "@orgcharthub/tldraw-tldraw";
import { HGDisplayProperty, HSProperty } from "./property";

export interface AuthState {
  accessToken: string;
}

export const GRID_SIZE = 20;

const SCOPES_REQUIRED_FOR_CUSTOM_OBJECT_SUPPORT = [
  "crm.objects.custom.read",
  "crm.objects.custom.write",
  "crm.schemas.custom.read",
] as const;

export const DEFAULT_DISPLAY_PROPERTIES: Readonly<HGDisplayProperty[]> = [
  // contacts
  {
    name: "firstname",
    objectType: "contact",
    showOnCard: true,
  },
  {
    name: "lastname",
    objectType: "contact",
    showOnCard: true,
  },
  {
    name: "jobtitle",
    objectType: "contact",
    showOnCard: true,
  },
  {
    name: "notes_last_updated",
    objectType: "contact",
    showOnCard: true,
  },
  {
    name: "company",
    objectType: "contact",
    showOnCard: false,
  },
  {
    name: "email",
    objectType: "contact",
    showOnCard: false,
  },

  // companies
  {
    name: "name",
    objectType: "company",
    showOnCard: true,
  },
  {
    name: "hubspot_owner_id",
    objectType: "company",
    showOnCard: true,
  },
  {
    name: "hs_ideal_customer_profile",
    objectType: "company",
    showOnCard: true,
  },
  {
    name: "num_associated_deals",
    objectType: "company",
    showOnCard: true,
  },
  {
    name: "hs_is_target_account",
    objectType: "company",
    showOnCard: false,
  },
  {
    name: "annualrevenue",
    objectType: "company",
    showOnCard: false,
  },
  {
    name: "description",
    objectType: "company",
    showOnCard: false,
  },

  // deals
  {
    name: "dealname",
    objectType: "deal",
    showOnCard: true,
  },
  {
    name: "amount",
    objectType: "deal",
    showOnCard: true,
  },
  {
    name: "closedate",
    objectType: "deal",
    showOnCard: true,
  },
  {
    name: "hubspot_owner_id",
    objectType: "deal",
    showOnCard: true,
  },
  {
    name: "description",
    objectType: "deal",
    showOnCard: false,
  },
  {
    name: "dealstage",
    objectType: "deal",
    showOnCard: false,
  },
] as const;

const HGCanvasNodeDebugInfo = ts.partial({
  debug: ts.type({
    color: ts.string,
    label: ts.string,
  }),
});

export const HGCanvasObjectNodeHubSpot = ts.intersection([
  ts.type({
    type: ts.literal("objectHubSpot"),
    objectId: ts.string,
    objectType: ts.string,
    id: ts.string,
    parentId: ts.union([ts.string, ts.undefined]),
  }),
  HGCanvasNodeDebugInfo,
]);
export type HGCanvasObjectNodeHubSpot = ts.TypeOf<
  typeof HGCanvasObjectNodeHubSpot
>;

export const HGCanvasObjectNodeHubGraph = ts.intersection([
  ts.type({
    type: ts.literal("objectHubGraph"),
    objectType: ts.string,
    id: ts.string,
    parentId: ts.union([ts.string, ts.undefined]),
  }),
  HGCanvasNodeDebugInfo,
]);
export type HGCanvasObjectNodeHubGraph = ts.TypeOf<
  typeof HGCanvasObjectNodeHubGraph
>;

export const HGCanvasObjectNode = ts.union([
  HGCanvasObjectNodeHubSpot,
  HGCanvasObjectNodeHubGraph,
]);
export type HGCanvasObjectNode = ts.TypeOf<typeof HGCanvasObjectNode>;

export const HGObjectRef = ts.type({
  objectType: ts.string,
  objectId: ts.string,
});
export type HGObjectRef = ts.TypeOf<typeof HGObjectRef>;

const HGObjectTypePair = ts.tuple([ts.string, ts.string]);
export type HGObjectTypePair = ts.TypeOf<typeof HGObjectTypePair>;

export function canonicalIdForHGObjectRef(objectRef: HGObjectRef): string {
  return `${objectRef.objectType}:${objectRef.objectId}`;
}

export function canonicalIdForHSPropertyGroup(params: {
  objectType: string;
  name: string;
}): string {
  const { name, objectType } = params;
  return `PropertyGroup:${objectType}:${name}`;
}

export function canonicalIdForHSProperty(params: {
  objectType: string;
  name: string;
}): string {
  const { name, objectType } = params;
  return `Property:${objectType}:${name}`;
}

export function canonicalIdForPipeline(params: {
  objectType: string;
  id: string;
}): string {
  return `Pipeline:${params.objectType}:${params.id}`;
}

export function canonicalIdForPipelineStage(params: {
  objectType: string;
  pipelineId: string;
  id: string;
}): string {
  return `PipelineStage:${params.objectType}:${params.pipelineId}:${params.id}`;
}

export const HGObject = ts.intersection([
  ts.type({
    objectType: ts.string,
    objectId: ts.string,
    canonicalId: ts.string,

    /** local custom properties that we'll keep track of **/
    isFetched: ts.boolean,
  }),
  ts.partial({
    properties: ts.record(ts.string, ts.union([ts.string, ts.null])),
    associations: ts.record(
      ts.string,
      ts.type({
        results: ts.array(
          ts.type({
            id: ts.string,
            type: ts.string,
          }),
        ),
      }),
    ),
  }),
]);
export type HGObject = ts.TypeOf<typeof HGObject>;

export const HSUser = ts.intersection([
  ts.type({
    id: ts.string,
  }),
  ts.partial({
    firstName: ts.string,
    lastName: ts.string,
    email: ts.string,
  }),
]);
export type HSUser = ts.TypeOf<typeof HSUser>;

export const HSOwner = ts.intersection([
  ts.type({
    id: ts.string,
    teams: ts.array(
      ts.type({
        id: ts.string,
        name: ts.string,
        primary: ts.boolean,
      }),
    ),
  }),
  ts.partial({
    email: ts.string,
    firstName: ts.string,
    lastName: ts.string,
    userId: ts.number,
  }),
]);
export type HSOwner = ts.TypeOf<typeof HSOwner>;

export const HSPipelineStage = ts.type({
  canonicalId: ts.string,
  objectType: ts.string,
  pipelineId: ts.string,

  id: ts.string,
  label: ts.string,
  displayOrder: ts.number,
  metadata: ts.type({
    probability: ts.string,
  }),
});
export type HSPipelineStage = ts.TypeOf<typeof HSPipelineStage>;
export const HSPipeline = ts.type({
  canonicalId: ts.string,
  objectType: ts.string,

  id: ts.string,
  label: ts.string,
  displayOrder: ts.number,
  stages: ts.array(HSPipelineStage),
});
export type HSPipeline = ts.TypeOf<typeof HSPipeline>;

export type HGObjectRefPair = [
  objectRefA: HGObjectRef,
  objectRefB: HGObjectRef,
];

export function objectRefPairId(objectRefPair: HGObjectRefPair): string {
  const [refA, refB] = objectRefPair;
  return [canonicalIdForHGObjectRef(refA), canonicalIdForHGObjectRef(refB)]
    .sort()
    .join("/");
}

export function objectRefsEqual(a: HGObjectRef, b: HGObjectRef): boolean {
  return a.objectId === b.objectId && a.objectType === b.objectType;
}

// TODO: this needs to work for custom objects as well
export function objectDisplayName(hgObject: HGObject): string {
  switch (hgObject.objectType) {
    case "company": {
      const name = hgObject.properties?.name;
      const domain = hgObject.properties?.domain;
      return name || domain || hgObject.objectId;
    }

    case "contact": {
      const firstName = hgObject.properties?.first_name;
      const lastName = hgObject.properties?.last_name;
      const email = hgObject.properties?.email;

      if (firstName || lastName) {
        return _.filter([firstName, lastName], _.identity).join(" ");
      } else if (email) {
        return email;
      } else {
        return hgObject.objectId;
      }
    }

    case "note": {
      const name = "note";
      return name;
    }

    case "deal": {
      const name = hgObject.properties?.dealname;
      return name || hgObject.objectId;
    }

    case "2-4732493": {
      const name = hgObject.properties?.development_name;
      return name || hgObject.objectId;
    }

    case "2-7769356": {
      const name = hgObject.properties?.name;
      return name || hgObject.objectId;
    }

    default: {
      return hgObject.objectId;
    }
  }
}

export function makeUnsavedNoteShape(params: {
  childIndex: number;
  point: number[];
}): CardShape {
  const { childIndex, point } = params;

  const id = `unsaved_${shortid()}`;

  const noteShape: CardShape = {
    type: TDShapeType.Card,
    id,
    parentId: "page_1",
    name: "Card",
    childIndex,
    point,
    style: {
      dash: TDDraw.DashStyle.Draw,
      size: TDDraw.SizeStyle.Large,
      color: TDDraw.ColorStyle.Blue,
    },
    meta: {
      metaType: "hgObject",
      objectType: "note",
    },
  };

  return noteShape;
}

// export function isUnsavedNoteShape();

export function hubspotURLForObjectRef(params: {
  portalId: string;
  portalHSDomain: string;
  objectRef: HGObjectRef;
}): string {
  const { portalId, portalHSDomain, objectRef } = params;
  return `https://${portalHSDomain}/contacts/${portalId}/${objectRef.objectType}/${objectRef.objectId}`;
}

export function outsideHubSpotURL(currentURL: string): string {
  const url = new URL(currentURL);
  url.searchParams.set("outside-hubspot", "true");
  return url.toString();
}

export function isOutsideHubSpotURL(currentURL: string): boolean {
  const url = new URL(currentURL);
  return url.searchParams.get("outside-hubspot") === "true";
}

export function isCardShape(shape: TDShape): shape is CardShape {
  return shape.type === "card";
}

export const DateTimeFromFirestoreTimestamp = new ts.Type<
  string,
  firebase.firestore.Timestamp,
  unknown
>(
  "DateTimeFromFirestoreTimestamp",
  // todo format validation?
  (input: unknown): input is string => typeof input === "string",
  (input, context) => {
    // can't `instanceof` against fb.firestore.Timestamp because it will be
    // considered false between different sources of data: `firebase-admin` vs
    // `firestore` modules
    const toDateFn = _.get(input, "toDate") as unknown;
    if (typeof toDateFn === "function") {
      const jsDate = toDateFn.call(input);
      return ts.success(DateTime.fromJSDate(jsDate).toISO());
    } else {
      return ts.failure(input, context);
    }
  },
  (isoDateTime: string): firebase.firestore.Timestamp => {
    const jsDate = DateTime.fromISO(isoDateTime).toJSDate();
    return firebase.firestore.Timestamp.fromDate(jsDate);
  },
);

export const RelationshipMapHSObjectIndex = ts.record(
  ts.string,
  ts.literal(true),
);
export type RelationshipMapHSObjectIndex = ts.TypeOf<
  typeof RelationshipMapHSObjectIndex
>;

export const RelationshipMap = ts.intersection([
  ts.type({
    id: ts.string,
    portalId: ts.string,
    name: ts.string,
    archived: ts.union([ts.boolean, ts.undefined]),
    description: ts.union([ts.string, ts.undefined]),
    isTemplate: ts.union([ts.boolean, ts.undefined]),
    createdAt: DateTimeFromFirestoreTimestamp,
    updatedAt: DateTimeFromFirestoreTimestamp,
  }),
  ts.partial({
    hsObjectIndex: RelationshipMapHSObjectIndex,
  }),
]);
export type RelationshipMap = ts.TypeOf<typeof RelationshipMap>;

export const RelationshipMapRecord = ts.type({
  id: ts.string,
  portalId: ts.string,
  document: ts.unknown,
  metadata: ts.type({
    mapVersion: ts.number,
  }),
});
export type RelationshipMapRecord = ts.TypeOf<typeof RelationshipMapRecord>;

export function userDisplayName(user: HSUser): string | undefined {
  if (user.firstName || user.lastName) {
    const parts = [user.firstName, user.lastName]
      .filter((part) => !_.isEmpty(part))
      .join(" ");
    return parts;
  } else {
    return undefined;
  }
}

export const HGPortal = ts.intersection([
  ts.type({
    "hub-domain": ts.string,
  }),
  ts.partial({
    "hg-display-properties": ts.array(HGDisplayProperty),
  }),
]);
export type HGPortal = ts.TypeOf<typeof HGPortal>;

export const HGAccountDetails = ts.type({
  portalId: ts.string,
  timeZone: ts.string,
  companyCurrency: ts.string,
  utcOffsetMilliseconds: ts.number,
  utcOffset: ts.string,
  uiDomain: ts.string,
});
export type HGAccountDetails = ts.TypeOf<typeof HGAccountDetails>;

export const HGObjectSchema = ts.type({
  canonicalId: ts.string,
  id: ts.string,
  objectTypeId: ts.string,
  properties: ts.array(HSProperty),
  fullyQualifiedName: ts.string,
  associations: ts.array(
    ts.type({
      id: ts.string,
      fromObjectTypeId: ts.string,
      toObjectTypeId: ts.string,
      name: ts.string,
    }),
  ),
  labels: ts.type({
    singular: ts.string,
    plural: ts.string,
  }),
  primaryDisplayProperty: ts.string,
  secondaryDisplayProperties: ts.array(ts.string),
  searchableProperties: ts.array(ts.string),
  requiredProperties: ts.array(ts.string),
  name: ts.string,
});
export type HGObjectSchema = ts.TypeOf<typeof HGObjectSchema>;

// taken from the default values returned for HubSpot’s object search
// API and then extended for things we know we we’ll need
const ALWAYS_FETCH_PROPERTIES = {
  contact: [
    "firstname",
    "lastname",
    "email",
    "lastmodifieddate",
    "hs_object_id",
    "createdate",
    // extensions
    "hs_buying_role", // always want to render buying roles
  ],
  company: [
    "name",
    "domain",
    "createdate",
    "hs_lastmodifieddate",
    "hs_object_id",
  ],
  deal: [
    "dealname",
    "amount",
    "closedate",
    "pipeline",
    "dealstage",
    "createdate",
    "hs_lastmodifieddate",
    "hs_object_id",
    // extensions
    "deal_currency_code", // needed to determine which amount & currency symbol to use
  ],
  // "note": ["hs_note_body", "hubspot_owner_id"],
} as const;

type ALWAYS_SUPPORTED_OBJECT_TYPE = keyof typeof ALWAYS_FETCH_PROPERTIES;
const ALWAYS_SUPPORTED_OBJECT_TYPES: readonly ALWAYS_SUPPORTED_OBJECT_TYPE[] = [
  "company",
  "contact",
  "deal",
] as const;

function isAlwaysSupportedObjectType(
  objectType: string,
): objectType is ALWAYS_SUPPORTED_OBJECT_TYPE {
  return ALWAYS_SUPPORTED_OBJECT_TYPES.includes(
    objectType as ALWAYS_SUPPORTED_OBJECT_TYPE,
  );
}

export function calculateSupportedObjectTypes(
  hgObjectSchemas: HGObjectSchema[],
): string[] {
  return [
    ...ALWAYS_SUPPORTED_OBJECT_TYPES,
    ...hgObjectSchemas.map((hgObjectSchema) => hgObjectSchema.objectTypeId),
  ];
}

export function calculatePropertiesToFetchForObjectType(params: {
  objectType: string;
  displayProperties: HGDisplayProperty[];
  hgObjectSchemas: HGObjectSchema[];
}): string[] {
  const { objectType, displayProperties, hgObjectSchemas } = params;

  const displayPropertyNamesToFetch = displayProperties
    .filter((displayProperty) => {
      return displayProperty.objectType === objectType;
    })
    .map((displayProperty) => {
      return displayProperty.name;
    });

  // also gather up anything we expect we need for custom objects
  const customObjectPropertyNamesToFetch = hgObjectSchemas
    .filter((hgObjectSchema) => {
      return hgObjectSchema.objectTypeId === objectType;
    })
    .flatMap((hgObjectSchema) => {
      return [
        hgObjectSchema.primaryDisplayProperty,
        ...hgObjectSchema.secondaryDisplayProperties,
        ...hgObjectSchema.requiredProperties,
        ...hgObjectSchema.searchableProperties,
      ];
    });

  // include the default-returned properties for default HubSpot objects
  const hubspotDefaultProperties = isAlwaysSupportedObjectType(objectType)
    ? ALWAYS_FETCH_PROPERTIES[objectType]
    : [];

  return _.uniq([
    ...displayPropertyNamesToFetch,
    ...customObjectPropertyNamesToFetch,
    ...hubspotDefaultProperties,
  ]);
}

function calculatePropertiesToFetch(params: {
  displayProperties: HGDisplayProperty[];
  hgObjectSchemas: HGObjectSchema[];
  objectTypes: string[];
}): Record<string, string[]> {
  const { displayProperties, hgObjectSchemas, objectTypes } = params;

  const propertiesByObjectType: Record<string, string[]> = {};

  for (const objectType of objectTypes) {
    propertiesByObjectType[objectType] =
      calculatePropertiesToFetchForObjectType({
        hgObjectSchemas,
        displayProperties,
        objectType,
      });
  }

  return propertiesByObjectType;
}

export function calculatePropertiesToFetchForAllObjectTypes(params: {
  displayProperties: HGDisplayProperty[];
  hgObjectSchemas: HGObjectSchema[];
}): Record<string, string[]> {
  const { displayProperties, hgObjectSchemas } = params;

  const customObjectTypes = hgObjectSchemas.map(
    (hgObjectSchema) => hgObjectSchema.objectTypeId,
  );

  return calculatePropertiesToFetch({
    displayProperties,
    hgObjectSchemas,
    objectTypes: [...ALWAYS_SUPPORTED_OBJECT_TYPES, ...customObjectTypes],
  });
}

export function customObjectsSupported(currentScopes: string[]): boolean {
  return SCOPES_REQUIRED_FOR_CUSTOM_OBJECT_SUPPORT.every((requiredScope) => {
    return currentScopes.includes(requiredScope);
  });
}

export function toShortDate(isoDateTime: string): string {
  const dt = DateTime.fromISO(isoDateTime);
  const formattedValue = dt.toLocaleString(DateTime.DATE_SHORT);
  return formattedValue;
}

export function toShortDateTime(isoDateTime: string): string {
  const dt = DateTime.fromISO(isoDateTime);
  const formattedValue = dt.toLocaleString(DateTime.DATETIME_SHORT);
  return formattedValue;
}

// type SettableURLParams = "mapId";
// export function updateURLParam(k: SettableURLParams, v: string | null): void {
//   const url = new URL(window.location.href);
//   if (v === null) {
//     url.searchParams.delete(k);
//   } else {
//     url.searchParams.set(k, v);
//   }
//   window.history.replaceState(null, "", url);
// }

export function isCardShapeMetaHubSpot(
  shapeMeta: CardShape["meta"],
): shapeMeta is CardShapeMetaHubSpot {
  return shapeMeta.metaType === "hsObject";
}

export function isCardShapeMetaHubGraph(
  shapeMeta: CardShape["meta"],
): shapeMeta is CardShapeMetaHubGraph {
  return shapeMeta.metaType === "hgObject";
}

export type CardShapeHubSpot = Omit<CardShape, "meta"> & {
  meta: CardShapeMetaHubSpot;
};

export type CardShapeHubGraph = Omit<CardShape, "meta"> & {
  meta: CardShapeMetaHubGraph;
};

export function isCardShapeHubSpot(shape: TDShape): shape is CardShapeHubSpot {
  return isCardShape(shape) && isCardShapeMetaHubSpot(shape.meta);
}

export function isCardShapeHubGraph(
  shape: TDShape,
): shape is CardShapeHubGraph {
  return isCardShape(shape) && isCardShapeMetaHubGraph(shape.meta);
}

export function isClosedLostDealStage(stage: HSPipelineStage): boolean {
  // TODO: parse string? could be "0"?
  return stage.metadata.probability === "0.0";
}

export function isClosedWonDealStage(stage: HSPipelineStage): boolean {
  // TODO: parse string? could be "1"?
  return stage.metadata.probability === "1.0";
}
