import * as TDDraw from "@orgcharthub/tldraw-tldraw";
import {
  TDDocument,
  CardShape,
  TDShapeType,
  ColorStyle,
  SizeStyle,
  DashStyle,
} from "@orgcharthub/tldraw-tldraw";
import _ from "lodash";
import shortid from "shortid";
import { CardShapeHubSpot, HGObjectRef } from ".";

const INITIAL_DOCUMENT: TDDraw.TDDocument = {
  id: "document_1",
  version: 15.4,
  pageStates: {
    page_1: {
      id: "page_1",
      selectedIds: [],
      camera: {
        point: [400, 200],
        zoom: 0.6,
      },
    },
  },
  assets: {},
  name: "something",
  pages: {
    page_1: {
      id: "page_1",
      name: "Some PAge",
      shapes: {
        "7oIyUqwUT": {
          id: "7oIyUqwUT",
          type: "card",
          name: "Card",
          parentId: "page_1",
          childIndex: 3,
          point: [-81.34, 167.67],
          rotation: 0,
          style: {
            color: "blue",
            size: "large",
            isFilled: false,
            dash: "draw",
            scale: 1,
          },
          meta: {
            metaType: "hsObject",
            objectType: "contact",
            objectId: "801",
          },
          isGhost: false,
        },
        kv4ZAys8Q: {
          id: "kv4ZAys8Q",
          type: "card",
          name: "Card",
          parentId: "page_1",
          childIndex: 2,
          point: [1791.6, -479.42],
          rotation: 0,
          style: {
            color: "blue",
            size: "large",
            isFilled: false,
            dash: "draw",
            scale: 1,
          },
          meta: {
            metaType: "hsObject",
            objectType: "contact",
            objectId: "851",
          },
          isGhost: false,
        },
        N9YPcxdoc: {
          id: "N9YPcxdoc",
          type: "card",
          name: "Card",
          parentId: "page_1",
          childIndex: 5,
          point: [2593.85, -1883.51],
          rotation: 0,
          style: {
            color: "blue",
            size: "large",
            isFilled: false,
            dash: "draw",
            scale: 1,
          },
          meta: {
            metaType: "hsObject",
            objectType: "company",
            objectId: "3045280402",
          },
          isGhost: false,
        },
        QKW22MHdG: {
          id: "QKW22MHdG",
          type: "card",
          name: "Card",
          parentId: "page_1",
          childIndex: 6,
          point: [1219.95, -1637.5],
          rotation: 0,
          style: {
            color: "blue",
            size: "large",
            isFilled: false,
            dash: "draw",
            scale: 1,
          },
          meta: {
            metaType: "hsObject",
            objectType: "contact",
            objectId: "1051",
          },
          isGhost: false,
        },
        Md4RoFzhk: {
          id: "Md4RoFzhk",
          type: "card",
          name: "Card",
          parentId: "page_1",
          childIndex: 7,
          point: [1274.95, -1055.68],
          rotation: 0,
          style: {
            color: "blue",
            size: "large",
            isFilled: false,
            dash: "draw",
            scale: 1,
          },
          meta: {
            metaType: "hsObject",
            objectType: "deal",
            objectId: "1605405664",
          },
          isGhost: false,
        },
        R_A72vfqZam: {
          id: "R_A72vfqZam",
          type: "card",
          name: "Card",
          parentId: "page_1",
          childIndex: 8,
          point: [2740.25, -640.45],
          rotation: 0,
          style: {
            color: "blue",
            size: "large",
            isFilled: false,
            dash: "draw",
            scale: 1,
          },
          meta: {
            metaType: "hsObject",
            objectType: "company",
            objectId: "5916939827",
          },
          isGhost: false,
        },
        uSyNS0rN8: {
          id: "uSyNS0rN8",
          type: "card",
          name: "Card",
          parentId: "page_1",
          childIndex: 9,
          point: [577.58, -1359.13],
          rotation: 0,
          style: {
            color: "blue",
            size: "large",
            isFilled: false,
            dash: "draw",
            scale: 1,
          },
          meta: {
            metaType: "hsObject",
            objectType: "contact",
            objectId: "3501",
          },
          isGhost: false,
        },
        "aec814f2-396c-4a2e-376b-d746d9c3933e": {
          id: "aec814f2-396c-4a2e-376b-d746d9c3933e",
          type: "ellipse",
          name: "Ellipse",
          parentId: "page_1",
          childIndex: 1,
          point: [276.33, -1953.11],
          radius: [1144.2800935316386, 1144.2800935316386],
          rotation: 0,
          style: {
            color: "green",
            size: "large",
            isFilled: false,
            dash: "dashed",
            scale: 1,
          },
          label: "",
          labelPoint: [0.5, 0.5],
          isGhost: false,
        },
        "e2c32bb0-e628-42fc-32e8-ac6b62998bd4": {
          id: "e2c32bb0-e628-42fc-32e8-ac6b62998bd4",
          type: "text",
          name: "Text",
          parentId: "page_1",
          childIndex: 10,
          point: [486.61, -616.1],
          rotation: 0,
          text: "higher influence",
          style: {
            color: "green",
            size: "medium",
            isFilled: false,
            dash: "dotted",
            scale: 1,
            font: "script",
            textAlign: "middle",
          },
          isGhost: false,
        },
        "61429837-7683-40a7-28eb-421ac392fffc": {
          id: "61429837-7683-40a7-28eb-421ac392fffc",
          type: "text",
          name: "Text",
          parentId: "page_1",
          childIndex: 11,
          point: [-304.58, -98.83],
          rotation: 0,
          text: "lower influence",
          style: {
            color: "yellow",
            size: "medium",
            isFilled: false,
            dash: "dotted",
            scale: 1,
            font: "script",
            textAlign: "middle",
          },
          isGhost: false,
        },
        "5WA6Jc42j": {
          id: "5WA6Jc42j",
          type: "card",
          name: "Card",
          parentId: "page_1",
          childIndex: 11,
          point: [3485.24, -1133.65],
          rotation: 0,
          style: {
            color: "blue",
            size: "large",
            isFilled: false,
            dash: "draw",
            scale: 1,
          },
          meta: {
            metaType: "hsObject",
            objectType: "contact",
            objectId: "1251",
          },
          isGhost: false,
        },
        "ed1dc78b-f350-4755-3718-bb06fb510ef0": {
          id: "ed1dc78b-f350-4755-3718-bb06fb510ef0",
          type: "arrow",
          name: "Arrow",
          parentId: "page_1",
          childIndex: 12,
          point: [3322.27, -2044.25],
          rotation: 0,
          bend: 0,
          handles: {
            start: {
              id: "start",
              index: 0,
              point: [0, 0],
              canBind: true,
            },
            end: {
              id: "end",
              index: 1,
              point: [0, 2693.08],
              canBind: true,
            },
            bend: {
              id: "bend",
              index: 2,
              point: [0, 1346.54],
            },
          },
          decorations: {
            end: "arrow",
          },
          style: {
            color: "violet",
            size: "large",
            isFilled: false,
            dash: "dotted",
            scale: 1,
          },
          label: "",
          labelPoint: [0.5, 0.5],
        },
        "7b4de0bb-d2c4-420d-09d6-59e31b437d92": {
          id: "7b4de0bb-d2c4-420d-09d6-59e31b437d92",
          type: "text",
          name: "Text",
          parentId: "page_1",
          childIndex: 13,
          point: [3480.77, -1557.58],
          rotation: 0,
          text: "partners",
          style: {
            color: "violet",
            size: "large",
            isFilled: false,
            dash: "dotted",
            scale: 1,
            font: "script",
            textAlign: "middle",
          },
        },
        "f49e92d2-4db2-42c6-2f7b-9af23e5038e0": {
          id: "f49e92d2-4db2-42c6-2f7b-9af23e5038e0",
          type: "sticky",
          name: "Sticky",
          parentId: "page_1",
          childIndex: 14,
          point: [1568.07, -1731.61],
          size: [200, 200],
          text: "sticky notes about the thing as well",
          rotation: 0,
          style: {
            color: "yellow",
            size: "small",
            isFilled: false,
            dash: "dotted",
            scale: 1,
            font: "script",
            textAlign: "middle",
          },
        },
      },
      bindings: {},
    },
  },
} as TDDocument;

export function makeNewInitialDocument(id: string): TDDraw.TDDocument {
  const newDoc = _.cloneDeep(INITIAL_DOCUMENT);
  return {
    ...newDoc,
    id,
  };
}

export function makeNewDealMapDocument(params: {
  id: string;
  dealObjectRef: HGObjectRef;
}): TDDraw.TDDocument {
  const { id, dealObjectRef } = params;

  const dealShapeId = shortid();
  const dealShape: CardShape = {
    id: dealShapeId,
    childIndex: 0,
    meta: {
      metaType: "hsObject",
      objectId: dealObjectRef.objectId,
      objectType: dealObjectRef.objectType,
    },
    type: TDShapeType.Card,
    name: "Card",
    parentId: "page_1",
    point: [0, 0],
    rotation: 0,
    style: {
      color: ColorStyle.Blue,
      size: SizeStyle.Large,
      isFilled: false,
      dash: DashStyle.Draw,
      scale: 1,
    },
    isGhost: false,
  };

  const doc: TDDocument = {
    assets: {},
    version: 15.4,
    pageStates: {
      page_1: {
        id: "page_1",
        selectedIds: [],
        camera: {
          point: [0, 0],
          zoom: 0.6,
        },
      },
    },
    id,
    name: `Deal Map: ${id}`,
    pages: {
      page_1: {
        bindings: {},
        id: "page_1",
        name: "Page 1",
        shapes: {
          [dealShapeId]: dealShape,
        },
      },
    },
  };

  return doc;
}

export function makeShapeForHSObject(params: {
  hgObjectRef: HGObjectRef;
  shapeId?: string;
  childIndex: number;
  point: [number, number];
}): CardShapeHubSpot {
  const { shapeId, childIndex, point, hgObjectRef } = params;
  const id = shapeId || shortid();
  const shape: CardShapeHubSpot = {
    id,
    type: TDDraw.TDShapeType.Card,
    parentId: "page_1",
    name: "Card",
    childIndex,
    point: [point[0], point[1]],
    style: {
      dash: TDDraw.DashStyle.Draw,
      size: TDDraw.SizeStyle.Large,
      color: TDDraw.ColorStyle.Blue,
    },
    meta: {
      metaType: "hsObject",
      objectType: hgObjectRef.objectType,
      objectId: hgObjectRef.objectId,
    },
  };
  return shape;
}
