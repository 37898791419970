import React from "react";
import * as ReactDOM from "react-dom/client";
import App from "./components/HGApp";
import "./App.css";
import { APP_VERSION } from "./config";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import * as mobx from "mobx";
// @ts-ignore
import mobxFormatters from "mobx-formatters";

mobxFormatters(mobx);

if (
  !new (class {
    x: any;
  })().hasOwnProperty("x")
)
  throw new Error("Transpiler is not configured correctly");

console.log(APP_VERSION);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLDivElement,
);

const renderApp = () => {
  root.render(
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>,
  );
};

const theme = createTheme({
  typography: {
    fontFamily: [
      `"Lexend Deca"`,
      "Inter",
      "Roboto",
      "Helvetica",
      "Arial",
      "sans-serif",
    ].join(","),
  },
});

const renderAppStrictMode = () => {
  root.render(
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </React.StrictMode>,
  );
};

if (import.meta.hot) {
  // import.meta.hot.on("vite:beforeUpdate", (e) =>
  //   console.log("before update index.tsx", e),
  // );

  import.meta.hot.accept("./components/HGApp", () => {
    console.log("call renderApp from hot");
    renderApp();
  });
}

const STRICT_MODE = false;

if (STRICT_MODE) {
  renderAppStrictMode();
} else {
  renderApp();
}
