import _ from "lodash";
import * as ts from "io-ts";
import reporters from "io-ts-reporters";
import { isLeft } from "fp-ts/Either";
import {
  DEV_CACHE_ENABLED,
  IS_PROD,
  LOCALSTORAGE_APP_CACHE_VERSION,
} from "../config";

export function assertNever(value: never): never {
  throw Error(`Unexpected value '${value}'`);
}

export function assert(condition: any, msg?: string): asserts condition {
  if (!condition) {
    throw new Error(msg);
  }
}

export function delay(ms: number): Promise<void> {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

export function indexBy<T>(
  coll: T[],
  keyFn: (item: T) => string,
): Record<string, T> {
  return _.reduce(
    coll,
    (acc, item) => {
      const id = keyFn(item);
      acc[id] = item;
      return acc;
    },
    {} as Record<string, T>,
  );
}

export function makeParseErrorGeneric<T>(result: ts.Validation<T>) {
  const messages = reporters.report(result).join("\n");
  console.error(result);
  throw new Error(`Could not parse: ${messages}`);
}

export function parseOrThrow<A>(
  from: ts.Decoder<unknown, A>,
  data: unknown,
): A {
  const decoded = from.decode(data);
  if (isLeft(decoded)) {
    throw makeParseErrorGeneric(decoded);
  }
  return decoded.right;
}

export async function withLocalDevCache<T>(
  cacheKey: string,
  fn: () => Promise<T>,
): Promise<T> {
  if (IS_PROD || !DEV_CACHE_ENABLED) {
    return await fn();
  }

  let versionedCacheKey = `${LOCALSTORAGE_APP_CACHE_VERSION}:${cacheKey}`;

  const cachedValue = localStorage.getItem(versionedCacheKey);
  if (cachedValue) {
    return JSON.parse(cachedValue) as T;
  } else {
    const v = await fn();
    localStorage.setItem(versionedCacheKey, JSON.stringify(v));
    return v;
  }
}

export function isNotUndefined<T>(i: T | undefined): i is T {
  return i !== undefined;
}

export function isNotNull<T>(i: T | null): i is T {
  return i !== null;
}
