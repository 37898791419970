import {
  useRef,
  useEffect,
  useState,
  useLayoutEffect,
  DependencyList,
  useCallback,
} from "react";
import firebase from "firebase/app";
import shortid from "shortid";
import * as ts from "io-ts";
import { parseOrThrow } from "../utils";
import { TDSnapshot } from "@orgcharthub/tldraw-tldraw";
import React from "react";
import { HGApp, HGStore } from "../store/types";

export function useInterval(callback: () => void, delay: number | null) {
  const savedCallback = useRef(callback);

  // Remember the latest callback if it changes.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    // Don't schedule if no delay is specified.
    if (delay === null) {
      return;
    }

    const id = setInterval(() => savedCallback.current(), delay);

    return () => clearInterval(id);
  }, [delay]);
}

export function useEffectOnce(
  effect: () => void | (() => void),
  dependencies?: any[],
) {
  const hasRun = useRef<boolean>(false);

  useEffect(() => {
    let effectReturns: void | (() => void) = () => {};

    if (!hasRun.current) {
      hasRun.current = true;
      effectReturns = effect();
    }

    return effectReturns;
  }, dependencies);
}

export function usePrevious<T>(value: T): T | undefined {
  const ref = useRef<T | undefined>(undefined);

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

function makeId(prefix?: string): string {
  return prefix ? `${prefix}:${shortid()}` : shortid();
}

/**
 * An id that we can use for debugging. Probably want to pass a fixed, non-empty, dependency array to ensure that we always create a new ID during "mount"/"unmount" through fast refresh.
 */
export function useId(prefix: string, deps: DependencyList): string {
  // const id = useRef<string>();
  // if (id.current === undefined) {
  //   console.log("generate id...");
  //   id.current = prefix ? `${prefix}:${shortid()}` : shortid();
  // }
  // return id.current;

  const [id, setId] = useState<string>(() => makeId(prefix));

  useLayoutEffect(() => {
    setId(() => makeId(prefix));
  }, deps);

  return id;
}

export function useIdFastRefresh(prefix: string) {
  return useId(prefix, ["fast-refresh"]);
}

export function useTransformer<A>(
  decoder: ts.Decoder<unknown, A>,
): (data: unknown) => A {
  const f = useCallback(
    (data: unknown) => {
      return parseOrThrow(decoder, data);
    },
    [decoder],
  );
  return f;
}

export function useTLApp() {
  const app = useHGApp();
  return app.tlApp;
}

export function useTLAppStore<U>(selector: (state: TDSnapshot) => U) {
  const app = useHGApp();
  const tlApp = app.tlApp;

  return tlApp.useStore(selector);
}

export const HGAppContext = React.createContext<HGApp>({} as HGApp);

export function useHGApp() {
  const context = React.useContext(HGAppContext);
  return context;
}

export function useStore(): HGStore {
  const app = useHGApp();
  return app.store;
}
