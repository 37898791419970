import React from "react";
import { HGObject } from "../domain";
import * as domain from "../domain";
import * as icons from "@mui/icons-material";
import { Button } from "@mui/material";
import * as computeds from "../computeds";
import { observer } from "mobx-react-lite";
import { useStore } from "../hooks/hooks";
import _ from "lodash";
import * as connection from "../domain/connection";

const bgColorClassesForObjectType: {
  [objectType: string]:
    | {
        dark: string;
        darker: string;
        darkest: string;
        light: string;
        darkBorder: string;
      }
    | undefined;
} = {
  contact: {
    darkBorder: "border-purple-400",
    darker: "bg-purple-300",
    darkest: "bg-purple-500",
    dark: "bg-purple-200",
    light: "bg-purple-100",
  },
  company: {
    darkBorder: "border-blue-400",
    darker: "bg-blue-300",
    darkest: "bg-blue-500",
    dark: "bg-blue-200",
    light: "bg-blue-100",
  },
  deal: {
    darkBorder: "border-emerald-400",
    darker: "bg-emerald-300",
    darkest: "bg-emerald-500",
    dark: "bg-emerald-200",
    light: "bg-emerald-100",
  },
};

const fgColorClassForObjectType: {
  [objectType: string]: string | undefined;
} = {
  contact: "text-purple-900",
  company: "text-blue-900",
  deal: "text-emerald-900",
};

function fgColorClass(objectType: string): string {
  return fgColorClassForObjectType[objectType] || "text-slate-900";
}

function bgColorClasses(objectType: string): {
  darkBorder: string;
  darker: string;
  darkest: string;
  dark: string;
  light: string;
} {
  return (
    bgColorClassesForObjectType[objectType] || {
      darkBorder: "border-slate-400",
      dark: "bg-slate-200",
      darker: "bg-slate-300",
      darkest: "bg-slate-400",
      light: "bg-slate-50",
    }
  );
}

export const AddToMapSuggestionRow: React.FC<{
  hgObject: HGObject;
  hgConnection?: connection.HGConnection;
  onAddToMap: (hgObject: HGObject) => void;
}> = observer((props) => {
  const { hgObject, hgConnection, onAddToMap } = props;

  const store = useStore();

  let iconNode: React.ReactNode | undefined;
  switch (hgObject.objectType) {
    case "contact": {
      iconNode = (
        <icons.Person
          color="inherit"
          className="relative"
          style={{ fontSize: 16, top: -1 }}
        />
      );
      break;
    }
    case "company": {
      iconNode = <icons.Business color="inherit" style={{ fontSize: 16 }} />;
      break;
    }
    case "deal": {
      iconNode = <icons.Handshake color="inherit" style={{ fontSize: 16 }} />;
      break;
    }
  }

  const Tag = (props: { label: string }) => {
    return (
      <div
        className={`shrink-0 pl-1 pr-3 py-1 rounded-md flex items-center justify-center ${
          bgColorClasses(hgObject.objectType).dark
        } ${fgColorClass(hgObject.objectType)} text-sm font-normal`}
      >
        <div className="pl-1 mr-1">{iconNode}</div>
        <div className="relative">{props.label}</div>
      </div>
    );
  };

  const displayLabels =
    hgConnection &&
    _.chain(hgConnection.appliedLabelPairs)
      .map((appliedLabelPair) => {
        return connection.displayLabelForObjectRef({
          appliedLabelPair,
          hgConnection,
          hgLabelPairs: [
            store.hgLabelPairs[appliedLabelPair.labelPairCanonicalId],
          ],
          hgObjectRef: hgObject,
        });
      })
      .compact()
      .sort()
      .join(", ")
      .value();

  return (
    <div className="px-4 py-2.5 flex flex-row items-center">
      <div className="pl-1 space-y-1 mr-2 min-w-0 grow">
        <h4 className="text-base font-normal text-slate-900 hg-privacy blur">
          {domain.objectDisplayName(hgObject)}
        </h4>

        <div className="flex flex-row space-x-2 items-center whitespace-nowrap">
          <Tag
            label={computeds.displayLabelForObjectTypeSingular(
              hgObject.objectType,
            )}
          />

          {!_.isEmpty(displayLabels) && (
            <div className="text-sm font-normal text-slate-900 text-ellipsis overflow-hidden">
              {displayLabels}
            </div>
          )}
        </div>
      </div>

      <div className="shrink-0">
        <Button
          variant="contained"
          style={{ textTransform: "none" }}
          onClick={(e) => {
            onAddToMap(hgObject);
          }}
        >
          Add to map
        </Button>
      </div>
    </div>
  );
});
