import { Button, Dialog, IconButton } from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { observer } from "mobx-react-lite";
import React from "react";
import { useStore } from "../hooks/hooks";
import { HGConnectionEditingSession, HGStore } from "../store/types";
import * as actions from "../actions";
import * as domain from "../domain";
import _ from "lodash";
import Select, {
  ActionMeta,
  OnChangeValue,
  components as SelectComponents,
  MultiValueRemoveProps,
  GroupBase,
} from "react-select";
import * as connection from "../domain/connection";
import * as objectTypesDomain from "../domain/object-types";
import { assert, isNotUndefined } from "../utils";
import { ObjectTypeTagWrappedIcon } from "./ObjectTypeTag";

const allowableHGLabelPairs = (
  store: HGStore,
  objectTypeA: string,
  objectTypeB: string,
): connection.HGLabelPair[] => {
  const hgLabelPairs = Object.values(store.hgLabelPairs);

  const allowableHGLabelPairs = hgLabelPairs.filter((hgLabelPair) => {
    const mentionedObjectTypes =
      connection.labelPairMentionedObjectTypes(hgLabelPair);
    return objectTypesDomain.objectTypesListsEqual(
      [objectTypeA, objectTypeB],
      mentionedObjectTypes,
    );
  });

  return allowableHGLabelPairs;
};

const AssociationEditorObjectPreview = (params: {
  hgObject: domain.HGObject;
  children?: React.ReactElement;
}) => {
  const { hgObject, children } = params;

  type ColorConfig = { border: string };
  const objectTypeColorConfig: Record<string, ColorConfig | undefined> = {
    company: {
      border: "border-blue-400 border",
    },
    contact: {
      border: "border-purple-400 border",
    },
    deal: {
      border: "border-emerald-400 border",
    },
  };
  const defaultColorConfig: ColorConfig = {
    border: "border-slate-300 border",
  };
  const colorConfig =
    objectTypeColorConfig[hgObject.objectType] || defaultColorConfig;

  return (
    <div className={`${colorConfig.border} rounded`}>
      <div className="flex flex-col p-2 space-y-2">
        <span className="text-base font-normal text-slate-800">
          {domain.objectDisplayName(hgObject)}
        </span>
        <div>
          <ObjectTypeTagWrappedIcon objectType={hgObject.objectType} />
        </div>
      </div>

      {children}
    </div>
  );
};

const DottedConnectorLine = ({
  height,
}: {
  height: "short" | "long" | "full";
}) => {
  let heightClass = "";
  if (height === "short") {
    heightClass = "h-2";
  } else if (height === "long") {
    heightClass = "h-10";
  } else if (height === "full") {
    heightClass = "min-h-[38px] grow";
  }
  return (
    <div
      className={`${heightClass} w-1/2 border-r-[3px] border-dashed border-slate-400`}
    ></div>
  );
};

const PrimaryCompanySelector = observer(
  ({
    companyObjectRefs,
    connectionEditingSession,
  }: {
    companyObjectRefs: domain.HGObjectRef[];
    connectionEditingSession: HGConnectionEditingSession;
  }) => {
    const store = useStore();

    function companyNameFromObjectId(objectId: string): string {
      const companyObject = store.hgObjects[
        domain.canonicalIdForHGObjectRef({
          objectType: "company",
          objectId,
        })
      ] as domain.HGObject | undefined;
      if (!companyObject) {
        return objectId;
      }
      return domain.objectDisplayName(companyObject);
    }

    type PrimaryCompanySelectOption = {
      value: string;
      label: string;
    };

    const selectOptions: PrimaryCompanySelectOption[] = companyObjectRefs.map(
      (companyObjectRef) => {
        return {
          label: companyNameFromObjectId(companyObjectRef.objectId),
          value: companyObjectRef.objectId,
        };
      },
    );

    let value: PrimaryCompanySelectOption | undefined;
    if (connectionEditingSession.newPrimaryCompanySelection) {
      value = {
        label: companyNameFromObjectId(
          connectionEditingSession.newPrimaryCompanySelection.objectId,
        ),
        value: connectionEditingSession.newPrimaryCompanySelection.objectId,
      };
    }

    return (
      <Select
        options={selectOptions}
        value={value}
        isClearable={true}
        onChange={(value) => {
          if (value) {
            actions.connectionEditingSessionSetNewPrimaryCompany({
              newCompanyObjectRef: {
                objectId: value.value,
                objectType: "company",
              },
            });
          }
        }}
      />
    );
  },
);

type SelectOption = {
  value: number;
  label: string;
  isFixed: boolean;
};

const MultiValueRemove = (
  props: MultiValueRemoveProps<SelectOption, true, GroupBase<SelectOption>>,
) => {
  if (props.data.isFixed) {
    return null;
  }
  return <SelectComponents.MultiValueRemove {...props} />;
};

const RelationshipEditorInner = observer(
  (params: {
    portalId: string;
    connectionEditingSession: HGConnectionEditingSession;
    onClose: () => void;
  }) => {
    const { portalId, connectionEditingSession, onClose } = params;

    const draftConnection = connectionEditingSession.draft;

    const editMode: "edit" | "create" = connectionEditingSession.existing
      ? "edit"
      : "create";

    const store = useStore();

    const hgObjectA =
      store.hgObjects[
        domain.canonicalIdForHGObjectRef(draftConnection.objectRefA)
      ];
    const hgObjectB =
      store.hgObjects[
        domain.canonicalIdForHGObjectRef(draftConnection.objectRefB)
      ];

    if (!hgObjectA || !hgObjectB) {
      throw Error("Cannot find objects for association editor");
    }

    const appliedLabelPairByTypeId = draftConnection.appliedLabelPairs.reduce(
      (acc, appliedLabelPair) => {
        acc[`${appliedLabelPair.objectATypeId}`] = appliedLabelPair;
        acc[`${appliedLabelPair.objectBTypeId}`] = appliedLabelPair;
        return acc;
      },
      {} as Record<string, connection.HGAppliedLabelPair>,
    );

    const selectableHGLabelPairs = allowableHGLabelPairs(
      store,
      draftConnection.objectRefA.objectType,
      draftConnection.objectRefB.objectType,
    ).filter((hgLabelPair) => {
      return hgLabelPair.type !== "unlabelled";
    });

    const selectableHGLabelPairsByTypeId = selectableHGLabelPairs.reduce(
      (acc, hgLabelPair) => {
        const labelATypeId = hgLabelPair.hgLabels.hgLabelA.typeId;
        const labelBTypeId = hgLabelPair.hgLabels.hgLabelB.typeId;
        acc[`${labelATypeId}`] = hgLabelPair;
        acc[`${labelBTypeId}`] = hgLabelPair;
        return acc;
      },
      {} as Record<string, connection.HGLabelPair>,
    );

    const pairedHGLabels = selectableHGLabelPairs.filter(
      (hgLabelPair) => hgLabelPair.type === "paired-label",
    );

    const singularHGLabels = selectableHGLabelPairs.filter(
      connection.isSingleLabelOrPrimaryLabelPair,
    );

    const pairedSelectOptions: SelectOption[] = pairedHGLabels.flatMap(
      (hgLabelPair) => {
        if (hgLabelPair.type === "unlabelled") {
          return [];
        }

        return [
          {
            value: hgLabelPair.hgLabels.hgLabelA.typeId,
            label: hgLabelPair.hgLabels.hgLabelA.label,
            isFixed: false,
          },
          {
            value: hgLabelPair.hgLabels.hgLabelB.typeId,
            label: hgLabelPair.hgLabels.hgLabelB.label,
            isFixed: false,
          },
        ];
      },
    );

    const singularSelectOptions: SelectOption[] = singularHGLabels.map(
      (hgLabelPair) => {
        const hgLabelForObjectAType =
          hgObjectA.objectType === hgLabelPair.hgLabels.hgLabelA.objectType
            ? hgLabelPair.hgLabels.hgLabelA
            : hgLabelPair.hgLabels.hgLabelB;
        return {
          value: hgLabelForObjectAType.typeId,
          label: connection.isPrimaryLabelPair(hgLabelPair)
            ? "Primary Company"
            : hgLabelForObjectAType.label,
          isFixed: false,
        };
      },
    );

    const hasPairedLabelOptions = pairedSelectOptions.length > 0;
    const hasSingleLabelOptions = singularSelectOptions.length > 0;

    const supportsPrimaryCompanyAssociation =
      connection.objectTypePairSupportsPrimaryCompanyAssociation([
        hgObjectA.objectType,
        hgObjectB.objectType,
      ]);

    const primaryCompanyAssociationCandidates =
      connection.primaryCompanyAssociationCandidates({
        hgConnection: draftConnection,
        otherHGConnections: Object.values(store.hgConnections),
        hgLabelPairs: Object.values(store.hgLabelPairs),
      });

    console.log(
      "primaryCompanyAssociationCandidates",
      primaryCompanyAssociationCandidates,
    );

    const singluarValue: SelectOption[] = draftConnection.appliedLabelPairs
      .map((appliedLabelPair) => {
        const hgLabelPair = store.hgLabelPairs[
          appliedLabelPair.labelPairCanonicalId
        ] as connection.HGLabelPair | undefined;

        if (!hgLabelPair) {
          return undefined;
        }

        return {
          appliedLabelPair,
          hgLabelPair,
        };
      })
      .filter(isNotUndefined)
      .filter(
        ({ hgLabelPair }) =>
          connection.isSingleLabelLabelPair(hgLabelPair) ||
          (supportsPrimaryCompanyAssociation &&
            connection.isPrimaryLabelPair(hgLabelPair)),
      )
      .map(({ appliedLabelPair, hgLabelPair }) => {
        assert(
          hgLabelPair.type === "single-label" || hgLabelPair.type === "primary",
        );
        const objectAHGLabel =
          appliedLabelPair.objectATypeId ===
          hgLabelPair.hgLabels.hgLabelA.typeId
            ? hgLabelPair.hgLabels.hgLabelA
            : hgLabelPair.hgLabels.hgLabelB;
        return {
          value: objectAHGLabel.typeId,
          label: connection.isPrimaryLabelPair(hgLabelPair)
            ? "Primary Company"
            : objectAHGLabel.label,
          isFixed:
            connection.isPrimaryLabelPair(hgLabelPair) &&
            primaryCompanyAssociationCandidates.length === 0,
        };
      });

    console.log("singleSelecValue", singluarValue);
    console.log("singleSelectOptions", singularSelectOptions);

    const objectAPairedValue: SelectOption[] = draftConnection.appliedLabelPairs
      .map((appliedLabelPair) => {
        const hgLabelPair = store.hgLabelPairs[
          appliedLabelPair.labelPairCanonicalId
        ] as connection.HGLabelPair | undefined;

        if (!hgLabelPair) {
          return undefined;
        }

        return {
          appliedLabelPair,
          hgLabelPair,
        };
      })
      .filter(isNotUndefined)
      .filter(({ hgLabelPair }) => hgLabelPair.type === "paired-label")
      .map(({ appliedLabelPair, hgLabelPair }) => {
        assert(hgLabelPair.type === "paired-label");
        const objectAHGLabel =
          appliedLabelPair.objectATypeId ===
          hgLabelPair.hgLabels.hgLabelA.typeId
            ? hgLabelPair.hgLabels.hgLabelA
            : hgLabelPair.hgLabels.hgLabelB;
        return {
          value: objectAHGLabel.typeId,
          label: objectAHGLabel.label,
          isFixed: false,
        };
      });

    const objectBPairedValue: SelectOption[] = draftConnection.appliedLabelPairs
      .map((appliedLabelPair) => {
        const hgLabelPair = store.hgLabelPairs[
          appliedLabelPair.labelPairCanonicalId
        ] as connection.HGLabelPair | undefined;

        if (!hgLabelPair) {
          return undefined;
        }

        return {
          appliedLabelPair,
          hgLabelPair,
        };
      })
      .filter(isNotUndefined)
      .filter(({ hgLabelPair }) => hgLabelPair.type === "paired-label")
      .map(({ appliedLabelPair, hgLabelPair }) => {
        assert(hgLabelPair.type === "paired-label");
        const objectBHGLabel =
          appliedLabelPair.objectBTypeId ===
          hgLabelPair.hgLabels.hgLabelA.typeId
            ? hgLabelPair.hgLabels.hgLabelA
            : hgLabelPair.hgLabels.hgLabelB;
        return {
          value: objectBHGLabel.typeId,
          label: objectBHGLabel.label,
          isFixed: false,
        };
      });

    const makeChangeHandler = (params: {
      objectSide: "A" | "B";
    }): ((
      value: OnChangeValue<SelectOption, true>,
      actionMeta: ActionMeta<SelectOption>,
    ) => void) => {
      const { objectSide } = params;
      return (value, meta) => {
        console.log("meta", meta);
        if (meta.action === "remove-value" || meta.action === "pop-value") {
          if (meta.removedValue.isFixed) {
            return;
          }
          const typeId = meta.removedValue.value;
          const appliedLabelPair = appliedLabelPairByTypeId[`${typeId}`];
          actions.editingSessionRemoveAssociationLabelPair({
            labelPairCanonicalId: appliedLabelPair.labelPairCanonicalId,
          });
        } else if (meta.action === "select-option") {
          const typeId = meta.option?.value;
          if (typeof typeId === "undefined") {
            return;
          }
          const hgLabelPair = selectableHGLabelPairsByTypeId[`${typeId}`];
          actions.editingSessionAddAssociationLabelPair({
            labelPairCanonicalId: hgLabelPair.canonicalId,
            objectSide,
            typeId,
          });
        }
      };
    };

    const objectASelectChangeHandler = makeChangeHandler({ objectSide: "A" });
    const objectBSelectChangeHandler = makeChangeHandler({ objectSide: "B" });

    const isReplacingPrimaryCompany =
      connectionEditingSession.existing &&
      connection.connectionReplacingExistingPrimaryCompany({
        nonCompanyObjectConnections: Object.values(store.hgConnections),
        draftConnection: draftConnection,
        hgLabelPairs: Object.values(store.hgLabelPairs),
      });

    let oldCompanyHGObject: domain.HGObject | undefined;
    if (isReplacingPrimaryCompany) {
      const nonCompanyObjectRef =
        draftConnection.objectRefA.objectType === "company"
          ? draftConnection.objectRefB
          : draftConnection.objectRefA;
      const ref = connection.primaryCompanyObjectRefForObjectRef({
        nonCompanyObjectRef,
        connections: Object.values(store.hgConnections),
        hgLabelPairs: Object.values(store.hgLabelPairs),
      });
      oldCompanyHGObject =
        ref && store.hgObjects[domain.canonicalIdForHGObjectRef(ref)];
    }

    console.log("isReplacingPrimaryCompany", isReplacingPrimaryCompany);

    const ReplacingPrimaryCompanyNote = () => {
      return (
        <div className="px-2 pt-2 pb-2 bg-purple-100 space-y-2 mt-1 text-sm text-purple-800">
          {oldCompanyHGObject ? (
            <span className="leading-normal">
              Updating this association will replace{" "}
              <span className="font-semibold">
                {domain.objectDisplayName(oldCompanyHGObject)}
              </span>{" "}
              as the primary company.
            </span>
          ) : (
            <div>
              Updating this association will replace the existing primary
              company
            </div>
          )}
        </div>
      );
    };

    const needsToSelectNewPrimaryCompany =
      connectionEditingSession.existing &&
      primaryCompanyAssociationCandidates.length > 0 &&
      connection.connectionHasPrimaryCompany({
        connection: connectionEditingSession.existing,
        hgLabelPairs: Object.values(store.hgLabelPairs),
      }) &&
      !connection.connectionHasPrimaryCompany({
        connection: draftConnection,
        hgLabelPairs: Object.values(store.hgLabelPairs),
      });

    const hasSelectedNewPrimaryCompany =
      !!connectionEditingSession.newPrimaryCompanySelection;

    // TODO: only allow creation of company->company with at least one applied label (no unlabelled company->company associations are valid. It might be that no unlabelled same object associations are valid?)
    const createUpdateDisabled =
      needsToSelectNewPrimaryCompany && !hasSelectedNewPrimaryCompany;
    const removeDisabled =
      needsToSelectNewPrimaryCompany && !hasSelectedNewPrimaryCompany;

    const NewCompanySelector = () => {
      return (
        <div className={`px-2 pt-1 pb-2 bg-purple-100 space-y-1`}>
          <span className="ml-1 text-xs text-purple-800 font-semibold leading-none">
            Select a new primary company
          </span>
          <PrimaryCompanySelector
            companyObjectRefs={primaryCompanyAssociationCandidates}
            connectionEditingSession={connectionEditingSession}
          />
        </div>
      );
    };

    return (
      <div className="h-full flex flex-col">
        <div className="bg-slate-300 p-6 flex flex-row justify-between items-center">
          <span className="text-slate-800 font-semibold text-lg leading-none">
            {editMode === "edit" ? "Edit" : "Create"} association
          </span>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>

        <div className="flex-1 flex flex-col p-6 space-y-8 overflow-y-scroll">
          <div className="grow flex flex-col">
            {/* top object ref, and maybe paired label selector */}
            <div className="">
              <AssociationEditorObjectPreview hgObject={hgObjectA}>
                <>
                  {needsToSelectNewPrimaryCompany &&
                    hgObjectB.objectType === "company" && (
                      <NewCompanySelector />
                    )}
                  {isReplacingPrimaryCompany &&
                    hgObjectB.objectType === "company" && (
                      <ReplacingPrimaryCompanyNote />
                    )}
                </>
              </AssociationEditorObjectPreview>
              {hasPairedLabelOptions && (
                <>
                  <DottedConnectorLine height="short" />
                  <Select
                    isMulti={true}
                    isClearable={false}
                    value={objectAPairedValue}
                    options={pairedSelectOptions}
                    onChange={objectASelectChangeHandler}
                  />
                </>
              )}
            </div>

            <div
              className={`grow flex flex-col ${
                hasSingleLabelOptions ? "min-h-[140px]" : "min-h-[80px]"
              } `}
            >
              {/* label select for single-label associations */}
              {hasSingleLabelOptions && (
                <>
                  <DottedConnectorLine height="full" />
                  <Select
                    isMulti={true}
                    isClearable={false}
                    value={singluarValue}
                    options={singularSelectOptions}
                    components={{ MultiValueRemove }}
                    onChange={objectASelectChangeHandler}
                  />
                  <DottedConnectorLine height="full" />
                </>
              )}
              {!hasSingleLabelOptions && <DottedConnectorLine height="full" />}
            </div>

            {/* bottom object ref, and maybe paired label selector */}
            <div>
              {hasPairedLabelOptions && (
                <>
                  <Select
                    isMulti={true}
                    isClearable={false}
                    value={objectBPairedValue}
                    options={pairedSelectOptions}
                    onChange={objectBSelectChangeHandler}
                  />
                  <DottedConnectorLine height="short" />
                </>
              )}
              <AssociationEditorObjectPreview hgObject={hgObjectB}>
                <>
                  {needsToSelectNewPrimaryCompany &&
                    hgObjectA.objectType === "company" && (
                      <NewCompanySelector />
                    )}

                  {isReplacingPrimaryCompany &&
                    hgObjectA.objectType === "company" && (
                      <ReplacingPrimaryCompanyNote />
                    )}
                </>
              </AssociationEditorObjectPreview>
            </div>
          </div>

          {/* create/remove buttons */}
          <div
            className={`flex flex-row ${
              editMode === "edit" ? "justify-between" : "justify-end"
            }`}
          >
            {editMode === "edit" && (
              <Button
                style={{
                  textTransform: "none",
                  fontWeight: 400,
                }}
                color="error"
                disabled={removeDisabled}
                onClick={() => {
                  actions.connectionEditingSessionRemoveConnectionOnHubSpot();
                }}
              >
                Remove association
              </Button>
            )}
            <Button
              variant="contained"
              color="primary"
              style={{
                textTransform: "none",
              }}
              disabled={createUpdateDisabled}
              onClick={() => {
                actions.connectionEditingSessionUpdateConnectionOnHubSpot();
              }}
            >
              {editMode === "edit" ? "Update" : "Create"} association
            </Button>
          </div>
        </div>
      </div>
    );
  },
);

export const HGConnectionEditor: React.FC<{}> = observer(() => {
  const store = useStore();
  const portalId = store.portalId;

  const connectionEditingSession = store.connectionEditingSession;

  const isVisible = !!connectionEditingSession;

  return (
    <Dialog
      fullWidth={true}
      maxWidth={"xs"}
      open={isVisible}
      onClose={() => {
        actions.cancelEditingAssociation();
      }}
    >
      <div className="flex flex-col overflow-hidden">
        {isVisible && (
          <RelationshipEditorInner
            portalId={portalId}
            connectionEditingSession={connectionEditingSession}
            onClose={() => {
              actions.cancelEditingAssociation();
            }}
          />
        )}
      </div>
    </Dialog>
  );
});
